import React from "react";
import { useHistory } from "react-router-dom";
import { useGoogleLogout } from "react-google-login";

import * as session from "~/src/system/session";
import ROUTES from "~/src/constants/routes";

const clientId = process.env.GOOGLE_CLIENT_ID || "";

interface GoogleLogoutButtonProps {
  className?: string;
  disabled?: boolean;
}

export const GoogleLogoutButton: React.FunctionComponent<
  GoogleLogoutButtonProps
> = ({ children, className, disabled }) => {
  const history = useHistory();

  const logout = () => {
    session.remove();
    // TODO: remove user data
    history.push(`${ROUTES.login.path}/fromLogout`);
  };

  const { signOut, loaded } = useGoogleLogout({
    clientId,
    cookiePolicy: "single_host_origin",
    onLogoutSuccess: logout,
  });

  return (
    <button
      onClick={signOut}
      disabled={disabled || !loaded}
      className={className || ""}
    >
      {children}
    </button>
  );
};
