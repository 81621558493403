import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { IGoogleUser } from "~/src/types/user";
import { IResponse } from "~/src/system/resource";

export interface IVerifyGoogleUserResponse {
  data?: {
    verifyGoogleUser?: {
      token?: string;
    };
  };
}

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.API_URL,
    prepareHeaders(headers) {
      headers.set("x-api-key", process.env.API_KEY || "");
      headers.set("content-type", "application/json");

      return headers;
    },
  }),
  tagTypes: ["Post"],
  endpoints: (builder) => {
    return {
      verifyGoogleUser: builder.query<IResponse, IGoogleUser | null>({
        query: (profile: IGoogleUser) => {
          const query = `mutation verifyGoogleUser($input: GoogleUser) {
            verifyGoogleUser(input: $input) {
              __typename
              ... on FlowFiError {
                id
                type
                message
              }
              ... on Session {
                token
              }
            }
          }`;
          const variables = {
            input: profile,
          };
          return {
            url: `users/`,
            method: "POST",
            body: { query, variables },
          };
        },
        // Clean up the response object.
        transformResponse: (response: IVerifyGoogleUserResponse) => {
          return response.data?.verifyGoogleUser || { token: "" };
        },
      }),
    };
  },
});

export const { useVerifyGoogleUserQuery } = userApi;
