import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { transformAppSyncApiResponse as transformResponse } from "~/src/system/resource";
import {
  ICategorizationWorkflowInput,
  ICategorizationWorkflowOutput,
  ICategorizeTransactionLineItemInput,
  ICategorizeTransactionOutput,
  ICreateMerchantInput,
  ICreateMerchantOutput,
  IMerchantsOutput,
  ITransactionLineItemsInput,
  ITransactionLineItemsOutput,
  ITransactionOutput,
} from "./transactions-api-types";

import { TransactionQueryVariables as TTransactionQueryVariables } from "~/src/graphql/jonathan/api";
// import { transaction as transactionQuery } from "~/src/graphql/jonathan/queries";

import {
  categorizationWorkflowSchema,
  categorizedLineItemSchema,
  categorizeWorkflowExcludeSuggestionsSchema,
  merchantSchema,
  transactionLineItemsSchema,
} from "./transactions-api-schema";

export const transactionsApi = createApi({
  reducerPath: "transactionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.JONATHAN_API,
    prepareHeaders(headers) {
      headers.set("content-type", "application/json");
      headers.set(
        "authorization",
        `Bearer ${process.env.JONATHAN_API_TOKEN || ""}`
      );
      return headers;
    },
  }),
  tagTypes: [
    "CategorizationWorkflow",
    "merchants",
    "transactionLineItems",
    "transactions",
  ],
  endpoints: (build) => {
    return {
      categorizationWorkflow: build.mutation<
        ICategorizationWorkflowOutput,
        ICategorizationWorkflowInput
      >({
        query: (categorizationWorkflowInput: ICategorizationWorkflowInput) => {
          const query = `
            mutation CategorizationWorkflow($input: CategorizationWorkflowInput!) {
              categorizationWorkflow(input: $input) { ${
                categorizationWorkflowInput.excludeSuggestions
                  ? categorizeWorkflowExcludeSuggestionsSchema
                  : categorizationWorkflowSchema
              } }
            }
          `;
          const input = { ...categorizationWorkflowInput };
          delete input.excludeSuggestions;
          const variables = { input };
          return {
            url: "/",
            method: "POST",
            body: { query, variables },
          };
        },
        transformResponse,
      }),
      categorizeTransaction: build.mutation<
        ICategorizeTransactionOutput,
        ICategorizeTransactionLineItemInput
      >({
        query: (
          categorizeTransactionLineItemInput: ICategorizeTransactionLineItemInput
        ) => {
          const query = `
            mutation CategorizeTransaction($input: CategorizeTransactionLineItemInput!) {
              categorizeTransactionLineItem(input: $input) { ${categorizedLineItemSchema} }
            }
          `;
          const variables = { input: categorizeTransactionLineItemInput };
          return {
            url: "/",
            method: "POST",
            body: { query, variables },
          };
        },
        invalidatesTags: ["transactionLineItems"],
        transformResponse,
      }),
      merchants: build.query<IMerchantsOutput, string>({
        query: (companyId: string) => {
          const query = `query merchants($companyId: ID!) {
            merchants(companyId: $companyId) { ${merchantSchema} }
          }`;
          const variables = { companyId };
          return {
            url: "/",
            method: "POST",
            body: { query, variables },
          };
        },
        providesTags: ["merchants"],
        transformResponse,
      }),
      transactions: build.query<ITransactionOutput, TTransactionQueryVariables>(
        {
          query: (variables: TTransactionQueryVariables) => {
            const query = `
            query Transaction($companyId: ID!, $externalTransactionId: ID!) {
              transaction(
                companyId: $companyId
                externalTransactionId: $externalTransactionId
              ) {
                externalId
                type
                syncToken
                date
                lineItems {
                  ${categorizedLineItemSchema}
                }
              }
            }
          `;
            return {
              url: "/",
              method: "POST",
              body: { query, variables },
            };
          },
          providesTags: ["transactions"],
          transformResponse,
        }
      ),
      transactionLineItems: build.query<
        ITransactionLineItemsOutput,
        ITransactionLineItemsInput
      >({
        query: (input: ITransactionLineItemsInput) => {
          const query = `query transactionLineItems($input: TransactionLineItemsInput!) {
            transactionLineItems(input: $input) { ${transactionLineItemsSchema} }
          }`;
          const variables = { input };
          return {
            url: "/",
            method: "POST",
            body: { query, variables },
          };
        },
        providesTags: ["transactionLineItems"],
        transformResponse,
      }),
      createMerchant: build.mutation<
        ICreateMerchantOutput,
        ICreateMerchantInput
      >({
        query: (createMerchantInput: ICreateMerchantInput) => {
          // EXPL: The BE expects at least one of `merchantType` AND/OR `postingType` (and prefers `merchantType` if both are provided).
          const queryArguments = `$companyId: ID!, $name: String!${
            createMerchantInput.merchantType
              ? ", $merchantType: MerchantType!"
              : ""
          }${
            createMerchantInput.postingType
              ? ", $postingType: PostingType!"
              : ""
          }`;
          const queryInput = `{ companyId: $companyId, name: $name${
            createMerchantInput.merchantType
              ? ", merchantType: $merchantType"
              : ""
          }${
            createMerchantInput.postingType ? ", postingType: $postingType" : ""
          }}`;
          const query = `mutation createMerchant(${queryArguments}) {
            createMerchant(input: ${queryInput}) { ${merchantSchema} }
          }`;
          const variables = createMerchantInput;
          return {
            url: "/",
            method: "POST",
            body: { query, variables },
          };
        },
        invalidatesTags: ["merchants"],
        transformResponse,
      }),
    };
  },
});

export const {
  useCategorizationWorkflowMutation,
  useCategorizeTransactionMutation,
  useMerchantsQuery,
  useCreateMerchantMutation,
  useLazyTransactionLineItemsQuery,
  useLazyTransactionsQuery,
} = transactionsApi;
