import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { Icons } from "~/src/assets/icons";
import { Button } from "~/src/components/button";
import { BackButton } from "~/src/components/back-button";
import { useAppSelector } from "~/src/system/store/hooks";
import { TablePaginator } from "~/src/components/table-paginator";
import { TableLimit, TTableLimitValue } from "~/src/components/table-limit";
import { setUncatTagIdInEdit } from "~/src/state/accounts/accounts-data-slice";
import { ITag } from "~/src/types/accounts";
import { sortTags } from "~/src/pages/account-tags-block/utilities";
import ROUTES from "~/src/constants/routes";

import { AccountForm } from "./account-form";
import "./uncategorized-tags.css";

// interface IUncategorizedTagsProps {}

/* 
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
*/

export const UncategorizedTags: React.FunctionComponent<IUncategorizedTagsProps> =
  ({}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    // initialize state/refs
    const [filteredTags, setFilteredTags] = useState<ITag[]>([]);
    const [pageTags, setPageTags] = useState<ITag[]>([]);
    const [tableSort, setTableSort] = useState<boolean>();
    const [tableLimit, setTableLimit] = useState<TTableLimitValue>(50);
    const [tableOffset, setTableOffset] = useState<number>(0);
    const [tableSearch, setTableSearch] = useState<string>("");

    // get accounts data from url param `category`
    const allAccounts = useAppSelector(
      (state) => state.accounts.accountTagMapping.accounts
    );
    const { unmappedTags } = useAppSelector(
      (state) => state.accounts.accountTagMapping
    );
    const dataLoaded = !!allAccounts.length;

    // filter tags when store data or filter values update
    useEffect(() => {
      dispatch(setUncatTagIdInEdit(""));
      if (tableSearch) setTableOffset(0);
      const tagsFiltered = unmappedTags.filter((tag) =>
        tag.name.toLowerCase().includes(tableSearch.toLowerCase())
      );
      setFilteredTags(tagsFiltered);
    }, [allAccounts, tableSearch]);

    // sort and slice filtered tags data when filtered data or table controls update
    useEffect(() => {
      dispatch(setUncatTagIdInEdit(""));
      const tagsSlice =
        tableSort !== undefined
          ? sortTags([...filteredTags], "name", tableSort ? "asc" : "dec")
          : [...filteredTags];
      setPageTags(tagsSlice.slice(tableOffset, tableOffset + tableLimit));
    }, [filteredTags, tableSort, tableLimit, tableOffset]);

    return (
      <>
        <div className="account-tags-block__header">
          <div>
            <BackButton onClick={() => history.push(ROUTES.accountTags.path)}>
              <span>
                Uncategorized Mappings
                {dataLoaded && (
                  <span className="account-tags-block__header-count">{` (${unmappedTags.length})`}</span>
                )}
              </span>
            </BackButton>
          </div>
          {dataLoaded && (
            <div className="account-tags-block__header-controls">
              <form>
                <input
                  type="text"
                  value={tableSearch}
                  onChange={(e) => setTableSearch(e.target.value)}
                  className="search-input account-tags-block__search"
                  placeholder="Search..."
                />
              </form>
              <Button
                theme="secondary"
                size="large"
                icon={Icons.default.SortAz}
                onClick={() => setTableSort(!tableSort)}
              />
            </div>
          )}
        </div>
        <div>
          <table className="table">
            <tbody>
              {pageTags.map((tag) => (
                <tr key={tag.id}>
                  <td>
                    <span>{tag.name}</span>
                  </td>
                  <td>
                    <AccountForm tag={tag} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {dataLoaded && !!filteredTags.length && (
          <div className="account-tags-block__footer">
            <TableLimit value={tableLimit} setValue={setTableLimit} />
            <TablePaginator
              offset={tableOffset}
              setOffset={setTableOffset}
              limit={tableLimit}
              length={filteredTags.length}
            />
          </div>
        )}
      </>
    );
  };
