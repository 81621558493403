import React, { useEffect, useState } from "react";
import moment from "moment";

import { Icons } from "~/src/assets/icons";
import { Button } from "~/src/components/button";
import { useAppSelector } from "~/src/system/store/hooks";
import { TablePaginator } from "~/src/components/table-paginator";
import { TableLimit, TTableLimitValue } from "~/src/components/table-limit";
import { IUncategorizedLineItem } from "~/src/types/transactions";
import { formatDisplayDate } from "~/src/utilities/dates";
import { getAccountName } from "~/src/utilities/accounts";
import { Redirect } from "react-router";
import ROUTES from "~/src/constants/routes";
import { formatCurrency } from "~/src/utilities/numbers";
import { TRANSACTION_TYPE_DISPLAY_MAP } from "~/src/constants/transactions";

import { UncategorizedLineItemRow } from "./components/uncategorized-line-item-row";
import { PageHeader } from "~/src/components/page-header";
import "./uncategorized-transactions.css";
import { ScrollContainer } from "~/src/components/scroll-container";
import { useApplyRules } from "~/src/pages/categorization/hooks/use-apply-rules";

export const UncategorizedTransactions: React.FunctionComponent = () => {
  const applyRules = useApplyRules();

  // state
  const uncategorizedTransactions = useAppSelector(
    (state) => state.transactions.categorizationWorkflow.uncategorizedLineItems
  );
  const categorizationWorkflowDateRange = useAppSelector(
    (state) => state.transactions.categorizationWorkflowDateRange
  );
  const [filteredTransactions, setFilteredTransactions] = useState<
    IUncategorizedLineItem[]
  >([]);
  const [pageTransactions, setPageTransactions] = useState<
    IUncategorizedLineItem[]
  >([]);
  const [tableSort, setTableSort] = useState<boolean>();
  const [tableLimit, setTableLimit] = useState<TTableLimitValue>(50);
  const [tableOffset, setTableOffset] = useState<number>(0);
  const [tableSearch, setTableSearch] = useState<string>("");
  const [openDrawer, setOpenDrawer] = useState<{ id: string }>({ id: "" });

  // filter transactions data when data or filter values update
  useEffect(() => {
    if (tableSearch) setTableOffset(0);
    const transactionsSlice = uncategorizedTransactions.filter((transaction) =>
      [
        transaction.memo ?? "",
        getAccountName(transaction.sourceAccountInfo),
        TRANSACTION_TYPE_DISPLAY_MAP[transaction.type],
        transaction.amount,
        formatCurrency(transaction.amount),
      ]
        .join("")
        .toLowerCase()
        .includes(tableSearch.toLowerCase())
    );
    setFilteredTransactions(transactionsSlice);
  }, [uncategorizedTransactions, tableSearch]);

  // sort and slice filtered transactions data when filtered data or table controls update
  useEffect(() => {
    const transactionsSlice = [...filteredTransactions];
    if (tableSort !== undefined) {
      transactionsSlice.sort((a, b) => {
        if (a.date > b.date) return tableSort ? 1 : -1;
        else if (a.date < b.date) return tableSort ? -1 : 1;
        else return 0;
      });
    }
    setPageTransactions(
      transactionsSlice.slice(tableOffset, tableOffset + tableLimit)
    );
  }, [filteredTransactions, tableSort, tableLimit, tableOffset]);

  const getDateRangeString = () => {
    let start = "";
    let end = "";
    const catStart = formatDisplayDate(
      moment.utc(categorizationWorkflowDateRange.start)
    );
    const catEnd = formatDisplayDate(
      moment.utc(categorizationWorkflowDateRange.end)
    );
    const today = formatDisplayDate(moment());
    const startDefault = "Start of Time";
    const endDefault = "Today";

    if (categorizationWorkflowDateRange.start !== 0) start = catStart;
    else start = startDefault;
    if (catEnd !== today) end = catEnd;
    else end = endDefault;
    if (start === startDefault && end === endDefault) return "(All Dates)";

    return `(${start} - ${end})`;
  };

  return uncategorizedTransactions.length ? (
    <div className="uncategorized-transactions">
      <ScrollContainer viewContainer fullHeight>
        <PageHeader
          icon={Icons.default.Book}
          label="Transactions"
          className="categorization__header"
        />
        <div className="uncategorized-transactions__header">
          <div>
            <strong className="uncategorized-transactions__summary">
              Transactions{" "}
              <span className="text-orange">
                ({uncategorizedTransactions.length})
              </span>
            </strong>
            <p className="uncategorized-transactions__date-range">
              {getDateRangeString()}
            </p>
          </div>
          <div className="account-tags-block__header-controls">
            <input
              type="text"
              value={tableSearch}
              onChange={(e) => setTableSearch(e.target.value)}
              className="search-input uncategorized-transactions__search"
              placeholder="Search..."
            />
            <Button
              icon={Icons.default.SortAz}
              theme="secondary"
              size="large"
              onClick={() => setTableSort(!tableSort)}
            />
          </div>
        </div>

        <div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Transaction Type</th>
                <th scope="col" className="uncategorized-line-item-row__memo">
                  Memo Line
                </th>
                <th scope="col">Source Account</th>
                <th scope="col" className="text-right">
                  Amount
                </th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {pageTransactions.map((lineItem) => (
                <UncategorizedLineItemRow
                  key={`${lineItem.externalTransactionId}-${lineItem.lineItemId}`}
                  lineItem={lineItem}
                  openDrawer={openDrawer}
                  setOpenDrawer={setOpenDrawer}
                />
              ))}
            </tbody>
          </table>
        </div>

        {!!filteredTransactions.length && (
          <div className="uncategorized-transactions__footer-controls">
            <TableLimit value={tableLimit} setValue={setTableLimit} />
            <TablePaginator
              offset={tableOffset}
              setOffset={setTableOffset}
              limit={tableLimit}
              length={filteredTransactions.length}
            />
          </div>
        )}
      </ScrollContainer>

      <div className="uncategorized-transactions__footer">
        <Button
          label="Apply Rules"
          theme="primary"
          size="large"
          onClick={applyRules}
          className="ml-auto"
        />
      </div>
    </div>
  ) : (
    <Redirect to={ROUTES.categorization.path} />
  );
};
