// helper functions for action items
import { TActionItemCategory } from "~/src/types/action-items";
import { IActionItem } from "~/src/types/action-items";

const FORMATTED_CATEGORIES = {
  miscellaneous: "Misc",
  amortization: "Service Period",
  categorization: "Categorization",
};

export const formatActionItemCategory = (category: TActionItemCategory) => {
  return FORMATTED_CATEGORIES[category];
};

export const getWeekFromToday = () => {
  const date = new Date();
  date.setDate(date.getDate() + 7);
  return date.getTime().toString();
};

export const getActionItemByTransactionId = (
  actionItems: IActionItem[],
  transactionId: string,
  lineItemId: string
) => {
  return actionItems.filter(
    (item) =>
      item.transaction_line_item_id === lineItemId &&
      item.transaction_id === transactionId
  );
};
