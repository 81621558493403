import React from "react";
import { useHistory } from "react-router-dom";
import Classnames from "classnames";

import { Pane } from "~/src/components/pane";
import ROUTES from "~/src/constants/routes";
import { Badge } from "~/src/components/badge";
import { SVGHandler } from "~/src/components/svg-handler";

import { IAccountBlockDisplayProps } from "~/src/constants/accounts";
import "./account-block-card.css";

interface IAccountBlockCardProps {
  displayProps: IAccountBlockDisplayProps;
  numAccounts: number;
  numTags: number;
}

const Insight = ({ label, count }: { label: string; count: number }) => {
  return (
    <div className="account-block-card-insight">
      <p className="account-block-card-insight__label">{label}</p>
      <Badge count={count} />
    </div>
  );
};

export const AccountBlockCard: React.FunctionComponent<
  IAccountBlockCardProps
> = ({ displayProps, numAccounts, numTags }) => {
  const history = useHistory();
  const { slug, displayName, icon, iconAlt, iconBackgroundColor } =
    displayProps;
  const goToAccountType = () => {
    history.push(`${ROUTES.accountTags.path}/${slug}`);
  };

  return (
    <Pane className="account-block-card" onClick={goToAccountType}>
      <div className="account-block-card__inner">
        <div className="account-block-card__heading">
          <div
            className={Classnames(
              "account-block-card__icon",
              iconBackgroundColor
            )}
          >
            <SVGHandler image={icon} width={32} height={32} altText={iconAlt} />
          </div>
          <h3 className="account-block-card__name">{displayName}</h3>
        </div>
        <div className="account-block-card__divider" />
        <div className="account-block-card__insights">
          {!!numAccounts && (
            <Insight label="Number of accounts: " count={numAccounts} />
          )}
          <Insight label="Total number of mappings: " count={numTags} />
        </div>
      </div>
    </Pane>
  );
};
