import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import ClassNames from "classnames";
import { toast } from "react-toastify";

import { useDispatch } from "react-redux";
import { Icons } from "~/src/assets/icons";
import ROUTES from "~/src/constants/routes";
import { Pane } from "~/src/components/pane";
import { Button } from "~/src/components/button";
import { BackButton } from "~/src/components/back-button";
import { useAppSelector } from "~/src/system/store/hooks";
import { TablePaginator } from "~/src/components/table-paginator";
import { TableLimit, TTableLimitValue } from "~/src/components/table-limit";
import {
  clearAccountTagsEdits,
  deleteAccountTagsEdit,
  updateAccountTagsEdits,
} from "~/src/state/accounts/accounts-data-slice";
import { IAccountsByBlock, IAccountWithTags, ITag } from "~/src/types/accounts";
import {
  useDeleteTagMappingMutation,
  useUpdateTagMappingMutation,
} from "~/src/state/accounts/accounts-api-slice";
import {
  ACCOUNT_BLOCK_DISPLAY_MAP,
  SLUG_TO_BLOCK_MAP,
  ACCOUNT_TYPE_DISPLAY_MAP,
} from "~/src/constants/accounts";
import { Toast } from "~/src/components/toast";
import { getAccountName } from "~/src/utilities/accounts";

import { buildTagMappingInputs, sortTags } from "./utilities";
import { Tags } from "./tags";
import "./account-tags-block.css";

/*
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable.
*/

export const AccountTagsBlock: React.FunctionComponent = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  // initialize state/refs
  const [filteredAccounts, setFilteredAccounts] = useState<IAccountWithTags[]>(
    []
  );
  const [pageAccounts, setPageAccounts] = useState<IAccountWithTags[]>([]);
  const [tableSort, setTableSort] = useState<boolean>();
  const [tableLimit, setTableLimit] = useState<TTableLimitValue>(50);
  const [tableOffset, setTableOffset] = useState<number>(0);
  const [tableSearch, setTableSearch] = useState<string>("");
  // state combined with ref so resize event listener accesses correct current val
  const [tagsColumnWidth, _setTagsColumnWidth] = useState<number>(0);
  const tagsColumnWidthRef = useRef<number>(tagsColumnWidth);
  const setTagsColumnWidth = (width: number) => {
    tagsColumnWidthRef.current = width;
    _setTagsColumnWidth(width);
  };
  const tagsColumn = useRef<HTMLSpanElement>(null);
  const companyId = useAppSelector(
    (state) => state.companiesData.customerCompanies.active?.id || ""
  );

  // get accounts of the block in the url param "accountBlock"
  const accountsByBlock = useAppSelector<IAccountsByBlock>(
    (state) => state.accounts.accountsByBlock
  );
  const accountTagsEdits = useAppSelector(
    (state) => state.accounts.accountTagsEdits
  );
  const params = useParams<{ accountBlock?: string }>();
  const accountBlock = SLUG_TO_BLOCK_MAP[params.accountBlock ?? ""];
  const accountsOfBlock = accountsByBlock[accountBlock]?.accounts ?? [];
  const dataLoaded = !!accountsByBlock[accountBlock];

  // tag mapping mutations
  const [updateTagMapping, updateTagMappingMutation] =
    useUpdateTagMappingMutation();
  const [deleteTagMapping, deleteTagMappingMutation] =
    useDeleteTagMappingMutation();
  const commitAccountTagsEdits = async (
    newTags: ITag[],
    existingTags: ITag[],
    externalAccountId: string
  ) => {
    if (
      updateTagMappingMutation.isLoading ||
      deleteTagMappingMutation.isLoading
    ) {
      return;
    }
    dispatch(deleteAccountTagsEdit(externalAccountId));

    const tagMappingInputs = buildTagMappingInputs(
      newTags,
      existingTags,
      companyId,
      externalAccountId
    );
    const updateTags = async () => {
      if (tagMappingInputs.update) {
        await updateTagMapping(tagMappingInputs.update);
      }
    };
    const deleteTags = async () => {
      if (tagMappingInputs.delete) {
        await deleteTagMapping(tagMappingInputs.delete);
      }
    };
    await Promise.all([updateTags(), deleteTags()]);
  };

  // manage tag mutation states
  useEffect(() => {
    const mutationsLoading =
      updateTagMappingMutation.isLoading || deleteTagMappingMutation.isLoading;
    const mutationsSuccess =
      (updateTagMappingMutation.isSuccess &&
        updateTagMappingMutation.data.data) ||
      (deleteTagMappingMutation.isSuccess &&
        deleteTagMappingMutation.data.data);
    const mutationsError =
      updateTagMappingMutation.isError ||
      !!updateTagMappingMutation.data?.errors ||
      deleteTagMappingMutation.isError ||
      !!deleteTagMappingMutation.data?.errors;
    if (mutationsLoading) {
      toast(
        <Toast
          message="Processing tag updates..."
          icon={Icons.green.LoadingAnimated}
        />,
        { toastId: "tagMutationLoading" }
      );
    } else {
      toast.dismiss("tagMutationLoading");
    }

    if (mutationsSuccess && !mutationsLoading) {
      toast(
        <Toast
          message="Tags were successfully updated."
          icon={Icons.green.Info}
        />,
        { toastId: "tagMutationSuccess" }
      );
    } else if (mutationsError && !mutationsLoading) {
      toast(
        <Toast
          message="There was a problem updating tags. Please try again."
          icon={Icons.green.Info}
        />,
        { toastId: "tagMutationError" }
      );
    }
  }, [updateTagMappingMutation, deleteTagMappingMutation]);

  // filter accounts data when data or filter values update
  useEffect(() => {
    dispatch(clearAccountTagsEdits());
    if (tableSearch) setTableOffset(0);
    const accountsSlice = accountsOfBlock.filter((account) =>
      [
        getAccountName(account.account),
        account.tags.reduce((accum, tag) => {
          return accum + tag.name;
        }, ""),
        ACCOUNT_TYPE_DISPLAY_MAP[account.account.accountType],
      ]
        .join("")
        .toLowerCase()
        .includes(tableSearch.toLowerCase())
    );
    setFilteredAccounts(accountsSlice);
  }, [accountsByBlock, tableSearch]);

  // sort and slice filtered accounts data when filtered data or table controls update
  useEffect(() => {
    dispatch(clearAccountTagsEdits());
    const accountsSlice = [...filteredAccounts];
    if (tableSort !== undefined) {
      accountsSlice.sort((a, b) => {
        const aName = getAccountName(a.account).toLowerCase();
        const bName = getAccountName(b.account).toLowerCase();
        if (aName > bName) return tableSort ? 1 : -1;
        else if (aName < bName) return tableSort ? -1 : 1;
        else return 0;
      });
    }
    setPageAccounts(accountsSlice.slice(tableOffset, tableOffset + tableLimit));
  }, [filteredAccounts, tableSort, tableLimit, tableOffset]);

  // track tags column width so tags component can use it to calculate how many tags to display
  const updateTagColumnWidth = () => {
    // minimize resize listener performance impact by only updating state when difference > 5px
    if (tagsColumn.current) {
      if (
        Math.abs(tagsColumn.current.offsetWidth - tagsColumnWidthRef.current) >
        10
      ) {
        setTagsColumnWidth(tagsColumn.current.offsetWidth);
      }
    }
  };
  useLayoutEffect(() => {
    if (tagsColumn.current) {
      updateTagColumnWidth();
      window.addEventListener("resize", updateTagColumnWidth);
    }
    return () => window.removeEventListener("resize", updateTagColumnWidth);
  }, [pageAccounts]);

  // form actions
  const inEditMode = (account: IAccountWithTags) => {
    return !!accountTagsEdits[account.account.externalId];
  };
  const handleCancel = (account: IAccountWithTags) => {
    dispatch(deleteAccountTagsEdit(account.account.externalId));
  };
  const handleSave = (account: IAccountWithTags) => {
    commitAccountTagsEdits(
      accountTagsEdits[account.account.externalId].tags,
      account.tags,
      account.account.externalId
    );
  };
  const handleEdit = (account: IAccountWithTags) => {
    const sortedTags = sortTags([...account.tags], "name");
    dispatch(
      updateAccountTagsEdits({
        externalAccountId: account.account.externalId,
        tags: sortedTags,
      })
    );
  };

  const getPaneBackground = (account: IAccountWithTags) => {
    return inEditMode(account) ? "bg-gray-hover" : "";
  };

  return (
    <>
      <div className="account-tags-block__header">
        <div>
          <BackButton onClick={() => history.push(ROUTES.accountTags.path)}>
            <span>
              {`${ACCOUNT_BLOCK_DISPLAY_MAP[accountBlock].displayName} QuickBooks Accounts`}
              {dataLoaded && (
                <span className="account-tags-block__header-count">{` (${accountsOfBlock.length})`}</span>
              )}
            </span>
          </BackButton>
        </div>
        {dataLoaded && (
          <div className="account-tags-block__header-controls">
            <form>
              <input
                type="text"
                value={tableSearch}
                onChange={(e) => setTableSearch(e.target.value)}
                className="search-input account-tags-block__search"
                placeholder="Search..."
              />
            </form>
            <Button
              theme="secondary"
              size="large"
              icon={Icons.default.SortAz}
              onClick={() => setTableSort(!tableSort)}
            />
          </div>
        )}
      </div>

      <div className="account-tags-block__list">
        {dataLoaded && (
          <Pane className="account-tags-block__row account-tags-block__heading">
            <div className="account-tags-block__heading-col-account-name account-tags-block__col-account-name">
              <span>QuickBooks Account</span>
              <img className="h-5" src={Icons.default.Info} />
            </div>
            <span className="account-tags-block__col-account-type">
              Account Type
            </span>
            <span ref={tagsColumn}>Tags</span>
            {/* empty div needed to keep header column 2 same width as row column 2 */}
            <div className="account-tags-block__col-buttons" />
          </Pane>
        )}
        {pageAccounts.map((account) => (
          <Pane
            key={account.account.externalId}
            className={ClassNames(
              "account-tags-block__row",
              getPaneBackground(account)
            )}
          >
            {inEditMode(account) ? (
              <div className="account-tags-block__edit-row">
                <div className="account-tags-block__row px-0">
                  <span className="account-tags-block__col-account-name">
                    {getAccountName(account.account)}
                  </span>
                  <span className="account-tags-block__col-account-type">
                    {ACCOUNT_TYPE_DISPLAY_MAP[account.account.accountType]}
                  </span>
                  <div />
                  <div className="account-tags-block__save-cancel">
                    <Button
                      theme="secondary"
                      size="medium"
                      label="Cancel"
                      onClick={() => handleCancel(account)}
                    />
                    <Button
                      theme="primary"
                      size="medium"
                      label="Save"
                      onClick={() => handleSave(account)}
                    />
                  </div>
                </div>
                <Pane>
                  <Tags
                    account={account}
                    commitAccountTagsEdits={commitAccountTagsEdits}
                    maxColWidth={tagsColumnWidth}
                  />
                </Pane>
              </div>
            ) : (
              <>
                <span className="account-tags-block__col-account-name">
                  {getAccountName(account.account)}
                </span>
                <span className="account-tags-block__col-account-type">
                  {ACCOUNT_TYPE_DISPLAY_MAP[account.account.accountType]}
                </span>
                <Tags
                  account={account}
                  commitAccountTagsEdits={commitAccountTagsEdits}
                  maxColWidth={tagsColumnWidth}
                />
                <div className="account-tags-block__col-buttons">
                  <Button
                    theme="secondary"
                    size="medium"
                    label="Edit Tags"
                    onClick={() => handleEdit(account)}
                  />
                </div>
              </>
            )}
          </Pane>
        ))}
      </div>

      {dataLoaded && !!filteredAccounts.length && (
        <div className="account-tags-block__footer">
          <TableLimit value={tableLimit} setValue={setTableLimit} />
          <TablePaginator
            offset={tableOffset}
            setOffset={setTableOffset}
            limit={tableLimit}
            length={filteredAccounts.length}
          />
        </div>
      )}
    </>
  );
};
