import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IJiraEpic } from "../../types/jira-epic";

interface IJiraEpicsDataState {
  jiraEpics: IJiraEpic[];
}

const initialState: IJiraEpicsDataState = {
  jiraEpics: [],
};

const jiraEpicsDataSlice = createSlice({
  name: "jiraEpics",
  initialState,
  reducers: {
    setJiraEpics(state, action: PayloadAction<IJiraEpic[]>) {
      state.jiraEpics = action.payload;
    },
  },
});

export const { setJiraEpics } = jiraEpicsDataSlice.actions;
export default jiraEpicsDataSlice.reducer;
