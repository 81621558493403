import { IAccount } from "../types/accounts";
import { ISplitAccountInfo, TSourceAccountInfo } from "../types/transactions";
import { IAccountTagMapping } from "../types/accounts";

export const getAccountName = (sourceAccountInfo: TSourceAccountInfo) => {
  if ((sourceAccountInfo as ISplitAccountInfo).status) {
    return (sourceAccountInfo as ISplitAccountInfo).status ?? "";
  } else {
    const account = sourceAccountInfo as IAccount;
    let result = "";
    if (account.accountNumber) {
      result = account.accountNumber + " ";
    }
    return result + account.displayName ?? "";
  }
};

export const findAccountByTagId = (
  tagId: string,
  accountTagMapping: IAccountTagMapping
) => {
  const acct = accountTagMapping.accounts.filter(
    (account) =>
      account.tags.length && account.tags.find((tag) => tag.id === tagId)
  );
  return acct.length ? acct[0]?.account?.externalId : "";
};
