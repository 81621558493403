import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

import { useAppSelector } from "~/src/system/store/hooks";
import { Icons } from "~/src/assets/icons";
import { Button } from "~/src/components/button";
import { Toast } from "~/src/components/toast";

import { MerchantAutocomplete } from "./merchant-autocomplete";
import { useCreateMerchantMutation } from "~/src/state/transactions/transactions-api-slice";
import { TPostingType } from "~/src/types/rules";
import { ICreateMerchantInput } from "~/src/state/transactions/transactions-api-types";
import { IMerchant } from "~/src/types/transactions";
import "./merchant-selector.css";

export type TMerchantMutationStatus =
  | "UNINITIALIZED"
  | "LOADING"
  | "SUCCESS"
  | "ERROR";

interface IMerchantSelectorProps {
  merchant?: IMerchant;
  setMerchant: React.Dispatch<React.SetStateAction<IMerchant | undefined>>;
  postingType: TPostingType;
  required?: boolean;
}

export const MerchantSelector: React.FunctionComponent<IMerchantSelectorProps> =
  ({ merchant, setMerchant, postingType, required }) => {
    const companyId = useAppSelector(
      (state) => state.companiesData.customerCompanies.active?.id || ""
    );
    const merchants = useAppSelector((state) => state.transactions.merchants);
    const [inputValue, setInputValue] = useState<string>("");
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputKeyEvent, setInputKeyEvent] =
      useState<React.KeyboardEvent<HTMLInputElement>>();
    const handleInputKeyEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (["Tab", "ArrowDown", "ArrowUp", "Escape"].includes(e.code)) {
        e.preventDefault();
      }
      if (dropdownOpen && e.code === "Enter") e.preventDefault();
      setInputKeyEvent(e);
    };
    const [mutationStatus, setMutationStatus] =
      useState<TMerchantMutationStatus>("UNINITIALIZED");
    const [inputFocused, setInputFocused] = useState<boolean>(
      inputRef.current === document.activeElement
    );
    const [dropdownOpen, setDropdownOpen] = useState(false);

    // manage state of merchant selector
    const onSelectExistingMerchant = (merchant: IMerchant) => {
      setInputValue(merchant.name);
      setMerchant(merchant);
      inputRef.current?.blur();
      setDropdownOpen(false);
    };

    // handle add new merchant
    const [createMerchant, createMerchantMutation] =
      useCreateMerchantMutation();
    const onAddNewMerchant = () => {
      const createMerchantInput: ICreateMerchantInput = {
        companyId,
        name: inputValue,
        postingType,
      };
      createMerchant(createMerchantInput);
      inputRef.current?.blur();
    };

    /**
     * Set merchant mutation status for use by the autocomplete component.
     * Close the dropdown on success or error.
     */
    useEffect(() => {
      if (createMerchantMutation.isLoading) setMutationStatus("LOADING");
      else if (
        createMerchantMutation.isSuccess &&
        !!createMerchantMutation.data.data
      ) {
        setDropdownOpen(false);
        setMerchant(createMerchantMutation.data.data);
        setMutationStatus("SUCCESS");
      } else if (
        createMerchantMutation.isError ||
        !!createMerchantMutation.data?.errors
      ) {
        setDropdownOpen(false);
        setMutationStatus("ERROR");
        toast(
          <Toast
            message="There was a problem adding the new merchant. Please try again."
            icon={Icons.green.Info}
          />
        );
      } else setMutationStatus("UNINITIALIZED");
    }, [createMerchantMutation]);

    const resetMerchantSelector = () => {
      setInputValue("");
      clearSelectedMerchant();
      inputRef.current?.focus();
    };
    const clearSelectedMerchant = () => {
      setMerchant(undefined);
    };

    // on input change, if value doesn't match the chosen merchant name, clear the merchant
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
      if (e.target.value !== merchant?.name) clearSelectedMerchant();
    };

    // If a default selected merchant is passed in, set the input to the merchant name.
    useEffect(() => {
      if (merchant) setInputValue(merchant.name);
    }, [merchant]);

    // close autocomplete and clear input (if there's no merchant) on off click
    useEffect(() => {
      const handleClick = (e: MouseEvent) => {
        if (
          (e.target as HTMLElement).closest(
            ".merchant-selector__input, .autocomplete"
          )
        ) {
          return;
        }
        setDropdownOpen(false);
        if (!merchant) setInputValue("");
      };
      if (dropdownOpen) {
        document.addEventListener("click", handleClick);
      }
      return () => document.removeEventListener("click", handleClick);
    }, [dropdownOpen, merchant]);

    // dropdown opened when input changes and input is focused and has a value
    useEffect(() => {
      if (inputFocused && !!inputValue) setDropdownOpen(true);
      if (!inputValue) setDropdownOpen(false);
    }, [inputValue]);

    return (
      <>
        <label>Merchant {required && "*"}</label>
        <div className="merchant-selector">
          <div className="merchant-selector__input-wrapper">
            <input
              type="text"
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              ref={inputRef}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyEvent}
              placeholder="Start Typing"
              className="merchant-selector__input"
            />
            <MerchantAutocomplete
              open={dropdownOpen}
              inputValue={inputValue}
              setInputValue={setInputValue}
              inputKeyEvent={inputKeyEvent}
              merchants={merchants}
              onAddNewMerchant={onAddNewMerchant}
              onSelectExistingMerchant={onSelectExistingMerchant}
              mutationStatus={mutationStatus}
              inputRef={inputRef}
              merchantIsSelected={!!merchant}
            />
          </div>
          {!!merchant && (
            <Button
              icon={Icons.default.Remove}
              theme="secondary"
              size="large"
              type="button"
              onClick={resetMerchantSelector}
            />
          )}
        </div>
      </>
    );
  };
