import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { ICustomerCompanies } from "./companies-data-slice";
import { ICompany, ICompanyProfileUpdateInput } from "~/src/types/company";

interface IGetCustomerCompaniesResponse {
  data?: {
    getWorkbenchCompanies: ICustomerCompanies;
  };
}

const companyModel = `
id
state
name
domain
created
updated
stage
avatar
employees
location_city
location_state
location_zip
industry
type
primary_poc
primary_business_activity
revenue_model
fiscal_start
books_start
`;

const companyUpdateProfileModel = `
id
name
type
primary_poc
primary_business_activity
revenue_model
fiscal_start
books_start
`;

export const companiesApi = createApi({
  reducerPath: "companiesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.STATE_API,
    prepareHeaders(headers) {
      headers.set("content-type", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Companies"],
  endpoints: (build) => {
    return {
      getCustomerCompanies: build.query<ICustomerCompanies, string | null>({
        query: (user_id: string) => {
          const query = `
            query getWorkbenchCompanies($user_id: ID!, $company_id: ID!, $config: QueryConfig) {
              getWorkbenchCompanies(user_id: $user_id, company_id: $company_id, config: $config) {
                assigned {${companyModel}}
                all {${companyModel}}
              }
            }
          `;
          const variables = {
            user_id,
            company_id: "",
            config: {
              // temporary hard limit to enable viewing of all companies
              limit: 500,
            },
          };
          return {
            url: `companies/`,
            method: "POST",
            body: { query, variables },
          };
        },
        transformResponse: (response: IGetCustomerCompaniesResponse) => {
          return (
            response.data?.getWorkbenchCompanies || {
              all: [],
              assigned: [],
              active: {
                id: "",
                name: "",
                domain: "",
                state: "",
              },
            }
          );
        },
        providesTags: ["Companies"],
      }),
      updateCompanyProfile: build.mutation<
        ICompany,
        ICompanyProfileUpdateInput
      >({
        query: (companyInput) => {
          // input may not need the !
          const query = `mutation updateCompanyProfile($companyInput: UpdateCompanyProfile!) {
            updateCompanyProfile(company: $companyInput) {
              ${companyUpdateProfileModel}
            }
          }`;
          const variables = {
            companyInput,
          };
          return {
            url: `companies/`,
            method: "POST",
            body: { query, variables },
          };
        },
        invalidatesTags: ["Companies"],
      }),
    };
  },
});

export const { useGetCustomerCompaniesQuery, useUpdateCompanyProfileMutation } =
  companiesApi;
