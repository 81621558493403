import React, { useEffect, useState } from "react";
import { Icons } from "~/src/assets/icons";
import { SVGHandler } from "../svg-handler";
import "./loading.css";

interface ILoadingProps {
  delay?: number;
}

export const Loading: React.FunctionComponent<ILoadingProps> = ({ delay }) => {
  const [render, setRender] = useState<boolean>(false);
  useEffect(() => {
    delayRender();
  }, []);

  const delayRender = async () => {
    await new Promise((resolve) => setTimeout(resolve, delay || 0));
    setRender(true);
  };

  return (
    <>
      {render && (
        <div className="loading-background">
          <SVGHandler
            image={Icons.green.LoadingAnimated}
            width={64}
            height={64}
          />
        </div>
      )}
    </>
  );
};
