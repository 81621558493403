import React from "react";
import { SVGHandler } from "../svg-handler";
import Classnames from "classnames";
import "./page-header.css";

interface IPageHeader {
  label: string | (() => string);
  icon: string;
  className?: string;
  children?: React.ReactNode;
}

export const PageHeader = ({
  label,
  icon,
  className,
  children,
}: IPageHeader): JSX.Element => {
  const headerClasses = Classnames("page-header", {
    ...(className ? { [className]: className } : {}),
  });

  return (
    <h2 className={headerClasses}>
      <SVGHandler
        image={icon}
        width={36}
        height={36}
        altText={`${label} icon`}
      />
      {label}
      {children}
    </h2>
  );
};
