import { Icons } from "~/src/assets/icons";
import { TAccountBlock } from "~/src/types/accounts";
import { TAccountType } from "~/src/types/accounts";

/* 
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
*/

export interface IAccountBlockDisplayProps {
  sort: number;
  slug: string;
  displayName: string;
  icon: string;
  iconAlt: string;
  iconBackgroundColor: string;
}
type TAccountBlockDisplayMap = {
  [key in TAccountBlock]: IAccountBlockDisplayProps;
};
export const ACCOUNT_BLOCK_DISPLAY_MAP: TAccountBlockDisplayMap = {
  ASSETS: {
    sort: 1,
    slug: "assets",
    displayName: "Assets",
    icon: Icons.green.Book,
    iconAlt: "Book icon",
    iconBackgroundColor: "bg-green-lighter",
  },
  LIABILITIES: {
    sort: 2,
    slug: "liabilities",
    displayName: "Liabilities",
    icon: Icons.green.Notes,
    iconAlt: "Notes icon",
    iconBackgroundColor: "bg-green-lighter",
  },
  EQUITY: {
    sort: 3,
    slug: "equity",
    displayName: "Equity",
    icon: Icons.green.Percent,
    iconAlt: "Percent icon",
    iconBackgroundColor: "bg-green-lighter",
  },
  INCOME: {
    sort: 4,
    slug: "income",
    displayName: "Income",
    icon: Icons.green.Wallet,
    iconAlt: "Open wallet icon",
    iconBackgroundColor: "bg-green-lighter",
  },
  COGS: {
    sort: 5,
    slug: "cogs",
    displayName: "COGS",
    icon: Icons.green.Shop,
    iconAlt: "Shopping bag icon",
    iconBackgroundColor: "bg-green-lighter",
  },
  EXPENSES: {
    sort: 6,
    slug: "expenses",
    displayName: "Expenses",
    icon: Icons.green.Garage,
    iconAlt: "Garage with car icon",
    iconBackgroundColor: "bg-green-lighter",
  },
};

export const UNCATEGORIZED_TAGS_DISPLAY_PROPS = {
  sort: 0,
  slug: "uncategorized-tags",
  displayName: "Uncategorized Mappings",
  icon: Icons.red.NoLabel,
  iconAlt: "Crossed-out label icon",
  iconBackgroundColor: "bg-red-lighter",
};

type TSlugToBlockMap = { [key: string]: TAccountBlock };
export const SLUG_TO_BLOCK_MAP: TSlugToBlockMap = {
  assets: "ASSETS",
  liabilities: "LIABILITIES",
  equity: "EQUITY",
  income: "INCOME",
  cogs: "COGS",
  expenses: "EXPENSES",
};

type TAccountTypeDisplayMap = { [key in TAccountType]: string };
export const ACCOUNT_TYPE_DISPLAY_MAP: TAccountTypeDisplayMap = {
  ACCOUNTS_PAYABLE: "Accounts Payable",
  ACCOUNTS_RECEIVABLE: "Accounts Receivable",
  BANK: "Bank",
  COGS: "COGS",
  CREDIT_CARDS: "Credit Cards",
  EQUITY: "Equity",
  EXPENSES: "Expenses",
  FIXED_ASSETS: "Fixed Assets",
  INCOME: "Income",
  LONG_TERM_LIABILITIES: "Long Term Liabilities",
  OTHER_ASSETS: "Other Assets",
  OTHER_CURRENT_ASSETS: "Other Current Assets",
  OTHER_CURRENT_LIABILITIES: "Other Current Liabilities",
  OTHER_EXPENSES: "Other Expenses",
  OTHER_INCOME: "Other Income",
};
