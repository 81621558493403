export type TRouteDefinition = {
  path: string;
  name?: string;
  subroutes?: {
    [key: string]: TRouteDefinition;
  };
};

export interface IRoute {
  path: string;
  name?: string;
}

export type IRouteDefinitions = typeof ROUTES;

const ROUTES = {
  root: {
    name: "Home",
    path: "/",
  },
  /* 
    NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
    References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
  */
  accountTags: {
    name: "CoA Mapping",
    path: "/account-tags",
    subroutes: {
      uncategorizedTags: {
        path: "/account-tags/uncategorized-tags",
      },
      accountBlock: {
        path: "/account-tags/:accountBlock",
      },
    },
  },
  actionItems: {
    name: "Action Items",
    path: "/action-items/",
    subroutes: {
      closed: {
        name: "Closed Action Items",
        path: "/action-items/closed",
      },
      new: {
        path: "/action-items/new/:category",
        name: "New Action Item",
        subroutes: {
          miscellaneous: {
            path: "/action-items/new/miscellaneous",
            name: "New Action Item: Miscellaneous",
          },
          categorization: {
            path: "/action-items/new/categorization",
            name: "New Action Item: Categorization",
          },
          amortization: {
            path: "/action-items/new/amortization",
            name: "New Action Item: Amortization",
          },
        },
      },
    },
  },
  categorization: {
    name: "Categorization",
    path: "/categorization",
    subroutes: {
      suggestedRules: {
        path: "/categorization/suggested-rules",
      },
      uncategorizedTransactions: {
        path: "/categorization/uncategorized-transactions",
      },
    },
  },
  rulesVault: {
    name: "Rules Vault",
    path: "/rules-vault",
  },
  // supportingSchedules: {
  //   name: "Accruals",
  //   path: "/accruals/",
  // },
  institutions: {
    name: "Institutions",
    path: "/institutions",
  },
  portfolio: {
    name: "Portfolio",
    path: "/portfolio",
    subroutes: {
      assigned: {
        name: "Assigned Customers",
        path: "/portfolio/assigned",
      },
      all: {
        name: "All Customers",
        path: "/portfolio/all",
      },
    },
  },
  companies: {
    path: "/companies",
    subroutes: {
      company: {
        path: "/companies/:id",
        subroutes: {
          profile: {
            path: "/companies/:id/profile",
          },
        },
      },
    },
  },
  errorPage: {
    path: "/error",
  },
  login: {
    path: "/login",
  },
};

export default ROUTES;
