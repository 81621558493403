import { useActiveCompanyId } from "~/src/hooks/useActiveCompanyId";
import {
  IInitiateConnectionInput,
  isIInitiateConnectionResponseUrl,
} from "~/src/state/institutions/institutions-api-types";
import { useAppSelector } from "~/src/system/store/hooks";
import { useConnectionLocation } from "../utilities/use-connection-location";
import { useInitiateConnection } from "../queries/use-initiate-connection";
import { InstitutionId } from "~/src/types/institutions";

export const useInitiateQuickbooksConnection = () => {
  const companyId = useActiveCompanyId();
  const institutionId: InstitutionId = InstitutionId.QUICKBOOKS;
  const quickbooksConnections = useAppSelector(
    (state) =>
      state.institutions.institutions.find(
        (institution) => institution.id === institutionId
      )?.connections ?? []
  );

  const connectionLocation = useConnectionLocation();
  const { initiateConnectionQuery, initiateConnectionQueryStatus } =
    useInitiateConnection();

  // quickbooks can only have one connection at a time
  const addConnectionDisabled =
    quickbooksConnections.length >= 1 ||
    initiateConnectionQueryStatus.isLoading ||
    initiateConnectionQueryStatus.isFetching;

  const initiateConnection = async () => {
    if (addConnectionDisabled) return;
    const input: IInitiateConnectionInput = {
      ...connectionLocation,
      companyId,
      institutionId,
    };
    const response = await initiateConnectionQuery(input).unwrap();
    if (
      response.data &&
      isIInitiateConnectionResponseUrl(response.data) &&
      response.data?.url
    )
      window.location.replace(response.data.url);
  };

  return { initiateConnection, addConnectionDisabled };
};
