import React, { useState } from "react";
import { Button } from "~/src/components/button";
import { Icons } from "~/src/assets/icons";
import { Dropdown } from "~/src/components/dropdown";
import { useDeleteConnection } from "../../hooks/mutations/use-delete-connection";
import { useCheckConnectionHealth } from "../../hooks/mutations/use-test-connection";
import { useGetConnection } from "../../hooks/utilities/use-get-connection";
import { ConnectionEditForm } from "../connection-edit-form";
import { useConnectionIdInEdit } from "../../hooks/utilities/use-connection-id-in-edit";
import { ConnectionDeleteConfirmation } from "../connection-delete-confirmation";
import { useUpdateConnection } from "../../hooks/mutations/use-update-connection";
import "./connection-edit-cell.css";

interface IConnectionEditCellProps {
  connectionId: string;
}

export const ConnectionEditCell = ({
  connectionId,
}: IConnectionEditCellProps): JSX.Element => {
  const connection = useGetConnection(connectionId);
  const { updateConnectionIdInEdit, connectionIdInEdit } =
    useConnectionIdInEdit(connection?.id);

  const { checkConnectionHealthMutation, isLoading: testButtonDisabled } =
    useCheckConnectionHealth();
  const onTestConnection = () => {
    if (connection)
      checkConnectionHealthMutation({
        companyId: connection.companyId,
        institutionId: connection.institutionId,
        connectionId: connection.id,
      });
  };

  const { updateConnectionMutation, isLoading: editButtonDisabled } =
    useUpdateConnection();

  const { deleteConnectionMutation, isLoading: disconnectButtonDisabled } =
    useDeleteConnection();

  const [editModalOpen, _setEditModalOpen] = useState(false);
  const setEditModalOpen = (open: boolean) => {
    !!open && updateConnectionIdInEdit();
    _setEditModalOpen(open);
  };

  const [deleteModalOpen, _setDeleteModalOpen] = useState(false);
  const setDeleteModalOpen = (open: boolean) => {
    !!open && updateConnectionIdInEdit();
    _setDeleteModalOpen(open);
  };

  return (
    <>
      <div className="connection-edit-cell">
        <Button
          label="Test"
          size="medium"
          theme="secondary"
          onClick={onTestConnection}
          disabled={testButtonDisabled}
        />
        <Dropdown
          icon={Icons.default.Dots}
          iconAlt="Three dots in a vertical line"
          openIcon={Icons.green.Dots}
          openIconAlt="Three green dots in a vertical line"
          iconClassName="connection-edit-cell__dropdown-icon"
          buttonClassName="button button--md button--secondary px-0"
          dropdownClassName="connection-edit-cell__dropdown"
          direction="left"
        >
          <button
            className="connection-edit-cell__dropdown-menu-button"
            onClick={() => setEditModalOpen(true)}
            disabled={editButtonDisabled}
          >
            Edit
          </button>
          <button
            className="connection-edit-cell__dropdown-menu-button"
            onClick={() => setDeleteModalOpen(true)}
            disabled={disconnectButtonDisabled}
          >
            Disconnect
          </button>
        </Dropdown>
      </div>
      {connectionIdInEdit && connection ? (
        <>
          <ConnectionEditForm
            modalOpen={editModalOpen}
            setModalOpen={setEditModalOpen}
            connection={connection}
            updateConnectionMutation={updateConnectionMutation}
          />
          <ConnectionDeleteConfirmation
            modalOpen={deleteModalOpen}
            setModalOpen={setDeleteModalOpen}
            connection={connection}
            deleteConnectionMutation={deleteConnectionMutation}
          />
        </>
      ) : null}
    </>
  );
};
