import React from "react";
import { SVGHandler } from "../svg-handler";
import "./toast.css";

interface IToast {
  message: string;
  icon?: string;
  children?: React.ReactNode;
}

export const Toast = ({ message, icon, children }: IToast) => {
  return (
    <div className="toast">
      {icon ? (
        <div className="toast__icon">
          <SVGHandler image={icon} width={36} height={36} />
        </div>
      ) : null}
      <div className="toast__body">
        <p className="toast__message">{message}</p>
        {children ? <div className="toast__children">{children}</div> : null}
      </div>
    </div>
  );
};
