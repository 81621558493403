import React, { useEffect } from "react";
import { useLazyInitiateConnectionQuery } from "~/src/state/institutions/institutions-api-slice";
import { toast } from "react-toastify";
import { Toast } from "~/src/components/toast";
import { Icons } from "~/src/assets/icons";

export const useInitiateConnection = () => {
  const [initiateConnectionQuery, initiateConnectionQueryStatus] =
    useLazyInitiateConnectionQuery();

  const { isLoading, isFetching, isError, data } =
    initiateConnectionQueryStatus;

  // when the query is in flight, show loading toast, otherwise dismiss it
  useEffect(() => {
    if (isLoading || isFetching) {
      toast(
        <Toast
          message="Initiating new connection..."
          icon={Icons.green.LoadingAnimated}
        />,
        { toastId: "connectionUrl", autoClose: false }
      );
    } else toast.dismiss("connectionUrl");
  }, [isLoading, isFetching]);

  // if the query errors or returns an error, show error toast
  useEffect(() => {
    if (isError || !!data?.errors) {
      toast(
        <Toast
          message="There was a problem initiating the connection. Please try again."
          icon={Icons.green.Info}
        />
      );
    }
  }, [isError, data]);

  return { initiateConnectionQuery, initiateConnectionQueryStatus };
};
