import React from "react";
import { Icons } from "~/src/assets/icons";
import { PageHeader } from "~/src/components/page-header";
import { HelpTip } from "~/src/components/help-tip";
import { LayoutContainer } from "~/src/components/layout";
import { useAppSelector } from "~/src/system/store/hooks";

import { AccountBlockCard } from "./account-block-card";
import {
  ACCOUNT_BLOCK_DISPLAY_MAP,
  UNCATEGORIZED_TAGS_DISPLAY_PROPS,
} from "~/src/constants/accounts";
import "./account-tags.css";

/*
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable.
*/

export const AccountTags = () => {
  const accountsByBlock = useAppSelector(
    (state) => state.accounts.accountsByBlock
  );
  const { unmappedTags } = useAppSelector(
    (state) => state.accounts.accountTagMapping
  );

  return (
    <>
      <PageHeader
        icon={Icons.default.Label}
        label="CoA Mapping"
        className="account-tags__header"
      >
        <HelpTip linkTo="https://notion.so/flowfinance/Account-Mappings-fda94968110f4b9e91f062ce697dde28" />
      </PageHeader>
      <LayoutContainer mode="grid" layout="responsive-grid-four">
        {!!unmappedTags.length && (
          <AccountBlockCard
            key={UNCATEGORIZED_TAGS_DISPLAY_PROPS.displayName}
            displayProps={UNCATEGORIZED_TAGS_DISPLAY_PROPS}
            numAccounts={0}
            numTags={unmappedTags.length}
          />
        )}
        {Object.values(accountsByBlock)
          .sort(
            (a, b) =>
              ACCOUNT_BLOCK_DISPLAY_MAP[a.block].sort -
              ACCOUNT_BLOCK_DISPLAY_MAP[b.block].sort
          )
          .map((accountsOfBlock) => (
            <AccountBlockCard
              key={accountsOfBlock.block}
              displayProps={ACCOUNT_BLOCK_DISPLAY_MAP[accountsOfBlock.block]}
              numAccounts={accountsOfBlock.accounts.length}
              numTags={accountsOfBlock.tags.length}
            />
          ))}
      </LayoutContainer>
    </>
  );
};
