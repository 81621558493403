import ClassNames from "classnames";
import React from "react";
import "./pane.css";

interface IPane {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export const Pane = ({ children, className, onClick }: IPane) => {
  const paneClasses = ClassNames("pane", className ?? "");

  const handleClick = () => {
    if (!onClick) return;

    onClick();
  };

  return (
    <div onClick={handleClick} className={paneClasses}>
      {children}
    </div>
  );
};
