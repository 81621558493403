import {
  IDeleteTagMappingInput,
  IUpdateTagMappingInput,
} from "~/src/state/accounts/accounts-api-types";
import { ITag } from "~/src/types/accounts";

/*
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable.
*/

interface ITagMappingInputs {
  update?: IUpdateTagMappingInput;
  delete?: IDeleteTagMappingInput;
}

export const sortTags = (
  tags: ITag[],
  by: "name" | "id",
  direction: "asc" | "dec" = "asc"
) => {
  return tags.sort((a, b) => {
    switch (by) {
      case "name":
        if (a.name.toLowerCase() > b.name.toLowerCase())
          return direction === "asc" ? 1 : -1;
        if (a.name.toLowerCase() < b.name.toLowerCase())
          return direction === "asc" ? -1 : 1;
        return 0;
      case "id":
        if (a.id.toLowerCase() > b.id.toLowerCase())
          return direction === "asc" ? 1 : -1;
        if (a.id.toLowerCase() < b.id.toLowerCase())
          return direction === "asc" ? -1 : 1;
        return 0;
      default:
        return 0;
    }
  });
};

export const buildTagMappingInputs = (
  newTags: ITag[],
  oldTags: ITag[],
  companyId: string,
  externalAccountId: string
): ITagMappingInputs => {
  const inputs: ITagMappingInputs = {};
  const tagMappingInput = {
    companyId,
    externalAccountId,
  };
  const updateInput: IUpdateTagMappingInput = {
    ...tagMappingInput,
    tagIds: [],
  };
  const deleteInput: IDeleteTagMappingInput = {
    ...tagMappingInput,
    tagIds: [],
  };

  const newTagIds = newTags.map((tag) => tag.id);
  const oldTagIds = oldTags.map((tag) => tag.id);

  const addTagNames: string[] = [];
  newTags.forEach((tag) => {
    if (tag.id.startsWith("new")) addTagNames.push(tag.name);
    else if (!oldTagIds.includes(tag.id)) updateInput.tagIds?.push(tag.id);
  });

  oldTags.forEach((tag) => {
    if (!newTagIds.includes(tag.id)) deleteInput.tagIds.push(tag.id);
  });

  if (addTagNames.length)
    Object.assign(updateInput, { newTagNames: addTagNames });
  if (updateInput.newTagNames?.length || updateInput.tagIds?.length) {
    Object.assign(inputs, { update: updateInput });
  }
  if (deleteInput.tagIds.length) {
    Object.assign(inputs, { delete: deleteInput });
  }

  return inputs;
};
