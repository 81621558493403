import React, {
  useEffect,
  useRef,
  useState,
  MutableRefObject,
  useLayoutEffect,
  Fragment,
} from "react";
import { useDispatch } from "react-redux";
import Classnames from "classnames";
import { v4 } from "uuid";

import {
  ITagsEdit,
  updateAccountTagsEdits,
} from "~/src/state/accounts/accounts-data-slice";
import { Tag } from "~/src/components/tag";
import { IAccountWithTags, ITag } from "~/src/types/accounts";
import { useAppSelector } from "~/src/system/store/hooks";
import { TagsAutocomplete } from "./tags-autocomplete";
import { sortTags } from "../utilities";
import "./tags.css";

interface ITagsProps {
  account: IAccountWithTags;
  commitAccountTagsEdits: (
    newTags: ITag[],
    existingTags: ITag[],
    externalAccountId: string
  ) => void;
  // width (in pixels) of the tags header element
  maxColWidth: number;
}

/*
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable.
*/

export const Tags: React.FunctionComponent<ITagsProps> = ({
  account,
  commitAccountTagsEdits,
  maxColWidth,
}) => {
  const dispatch = useDispatch();
  const externalAccountId = account.account.externalId;

  // handle state/refs
  const [existingTags, setExistingTags] = useState<ITag[]>(account.tags);
  const tagsEdit = useAppSelector(
    (state) => state.accounts.accountTagsEdits[externalAccountId]
  );
  const inEditMode = !!tagsEdit;
  const tagsEditRef = useRef<ITagsEdit>(tagsEdit);
  const [tagWidths, setTagWidths] = useState<number[]>([]);
  const [tagVisibilities, setTagVisibilities] = useState<boolean[]>([]);
  const tagsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [tagInput, setTagInput] = useState<string>("");
  const [inputKeyEvent, setInputKeyEvent] =
    useState<React.KeyboardEvent<HTMLInputElement>>();
  const handleInputKeyEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (["Tab", "ArrowDown", "ArrowUp", "Escape"].includes(e.code))
      e.preventDefault();
    if (!!tagInput && e.code === "Enter") e.preventDefault();
    setInputKeyEvent(e);
  };
  const [showTags, setShowTags] = useState<boolean>(false);
  const [lastTagSelected, setLastTagSelected] = useState<boolean>(false);

  // focus input on edit when entering edit mode
  useEffect(() => {
    if (inEditMode) inputRef.current?.focus();
  }, [inEditMode]);

  // sort tags when assigned tags are updated
  useEffect(() => {
    setExistingTags(sortTags([...account.tags], "name"));
  }, [account.tags]);

  // update tagWidths array when assigned tags updated/sorted
  useEffect(() => {
    if (tagsRef.current) {
      setTagWidths(
        Array.from(tagsRef.current.children)
          .filter((element) => element.classList.contains("tags__tag-wrapper"))
          .map((element) => (element as HTMLDivElement).offsetWidth)
      );
    }
  }, [existingTags]);

  // update the tagVisibilities array when tag widths or column width changes
  const tagsGapWidth = 8;
  useEffect(() => {
    const newVisibilities: boolean[] = [];
    const moreButtonWidth = 70;
    let availableWidth = maxColWidth - moreButtonWidth;
    tagWidths.forEach((width, idx) => {
      // the 8px accounts for gap width (there's no gap on the first tag)
      const gapWidth = idx ? tagsGapWidth : 0;
      availableWidth -= width + gapWidth;
      let tagVisibility = false;
      // show the tag if there is enough room or it is the first tag
      if (availableWidth >= 0 || idx === 0) tagVisibility = true;
      newVisibilities.push(tagVisibility);
    });
    setTagVisibilities(newVisibilities);
  }, [tagWidths, maxColWidth]);

  // tag crud operations
  const removeTag = (tagId: string) => {
    dispatch(
      updateAccountTagsEdits({
        ...tagsEdit,
        tags: tagsEdit.tags.filter((tag) => tag.id !== tagId),
      })
    );
    inputRef.current?.focus();
  };
  const addExistingTag = (tag: ITag) => {
    dispatch(
      updateAccountTagsEdits({ ...tagsEdit, tags: [...tagsEdit.tags, tag] })
    );
    inputRef.current?.focus();
  };
  const addNewTag = (name: string, tagsEdit: ITagsEdit) => {
    dispatch(
      updateAccountTagsEdits({
        ...tagsEditRef.current,
        tags: [...tagsEdit.tags, { name, id: `new-${v4()}`, scope: "LOCAL" }],
      })
    );
    inputRef.current?.focus();
  };
  const onFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    commitAccountTagsEdits(
      tagsEdit.tags,
      existingTags,
      tagsEdit.externalAccountId
    );
  };

  return (
    <div
      className={Classnames("tags", {
        "tags--show-more": inEditMode || showTags,
        "tags--edit": inEditMode,
      })}
      ref={tagsRef}
      style={{
        // inline maxWidth necessary to make sure tags width is *never* wider than the column width
        maxWidth: !inEditMode ? maxColWidth : undefined,
        // inline gap is necessary to sync gap used to calculate tag visibilities with styled gap
        gap: tagsGapWidth,
      }}
    >
      {!inEditMode ? (
        existingTags.map((tag, idx) => (
          <Fragment key={tag.id}>
            {
              // is index of first hidden tag
              tagVisibilities.indexOf(false) === idx &&
                // tags are hidden
                !showTags && (
                  <button
                    onClick={() => setShowTags(!showTags)}
                    className="tags__truncate-button"
                  >
                    {`+${
                      tagVisibilities.filter((vis) => vis === false).length
                    } More`}
                  </button>
                )
            }
            <div
              key={tag.id}
              className={Classnames("tags__tag-wrapper", {
                "tags__tag-wrapper--visible": tagVisibilities[idx] || showTags,
              })}
            >
              <Tag tag={tag} scope={tag.scope} />
            </div>
            {
              // is index of last tag
              idx === existingTags.length - 1 &&
                // tags are not hidden
                showTags &&
                // but 1 or more tags would be hidden
                tagVisibilities.includes(false) && (
                  <button
                    onClick={() => setShowTags(!showTags)}
                    className="tags__truncate-button"
                  >
                    Show Less
                  </button>
                )
            }
          </Fragment>
        ))
      ) : (
        <>
          {tagsEdit.tags.map((tag, idx) => (
            <Tag
              key={tag.id}
              tag={tag}
              scope={tag.scope}
              edit={inEditMode}
              onRemove={removeTag}
              selected={lastTagSelected && idx === tagsEdit.tags.length - 1}
            />
          ))}
          <form onSubmit={onFormSubmit} className="tags__form">
            <input
              ref={inputRef}
              onKeyDown={handleInputKeyEvent}
              className="tags__input"
              placeholder="Start Typing"
              value={tagInput}
              onChange={(e) => setTagInput(e.target.value)}
            />
            {inputRef.current && (
              <TagsAutocomplete
                inputValue={tagInput}
                inputKeyEvent={inputKeyEvent}
                externalAccountId={account.account.externalId}
                formTags={tagsEdit.tags}
                setInputValue={setTagInput}
                addNewTag={addNewTag}
                addExistingTag={addExistingTag}
                removeTag={removeTag}
                lastTagSelected={lastTagSelected}
                setLastTagSelected={setLastTagSelected}
              />
            )}
          </form>
        </>
      )}
    </div>
  );
};
