export enum InstitutionId {
  KLAVIYO = "KLAVIYO",
  PLAID = "PLAID",
  QUICKBOOKS = "QUICKBOOKS",
  SHOPIFY = "SHOPIFY",
}

export interface IInstitution {
  id: InstitutionId;
  connections: IConnection[];
  documentationUrl: string;
  iconUrl: string;
  impact: string;
  displayName: string;
}

export interface IKlaviyoInstitution extends IInstitution {
  connections: IKlaviyoConnection[];
  id: InstitutionId.KLAVIYO;
}

export interface IPlaidInstitution extends IInstitution {
  connections: IPlaidConnection[];
  id: InstitutionId.PLAID;
}

export interface IQuickbooksInstitution extends IInstitution {
  connections: IQuickbooksConnection[];
  id: InstitutionId.QUICKBOOKS;
}

export interface IShopifyInstitution extends IInstitution {
  connections: IShopifyConnection[];
  id: InstitutionId.SHOPIFY;
}

export interface IConnection {
  id: string;
  companyId: string;
  institutionId: InstitutionId;
  createdAt: string;
  title: string;
  healthy: boolean;
  healthLastChecked: string;
  perInstitutionValues?: IPerInstitutionValues;
}

interface IPerInstitutionValues {
  __typename: string;
}

export interface IPlaidConnectionValues extends IPerInstitutionValues {
  accountNames: string[];
}

export interface IPerInstitutionValuesWithRedactedApiKey
  extends IPerInstitutionValues {
  redactedApiKey: string;
}

export type IKlaviyoConnectionValues = IPerInstitutionValuesWithRedactedApiKey;

export interface IKlaviyoConnection extends IConnection {
  institutionId: InstitutionId.KLAVIYO;
  perInstitutionValues: IKlaviyoConnectionValues;
}

export interface IPlaidConnection extends IConnection {
  institutionId: InstitutionId.PLAID;
  perInstitutionValues: IPlaidConnectionValues;
}

export interface IQuickbooksConnection extends IConnection {
  institutionId: InstitutionId.QUICKBOOKS;
}

export interface IShopifyConnection extends IConnection {
  institutionId: InstitutionId.SHOPIFY;
}

export interface IConnectionWithRedactedApiKey extends IConnection {
  perInstitutionValues: IPerInstitutionValuesWithRedactedApiKey;
}

// type predicate functions for checking institution/connection type
export function isConnectionOfType<T extends IConnection>(
  connection: IConnection,
  institutionId: InstitutionId
): connection is T {
  return connection.institutionId === institutionId;
}

export function isInstitutionOfType<T extends IInstitution>(
  institution: IInstitution,
  institutionId: InstitutionId
): institution is T {
  return institution.id === institutionId;
}

export function isConnectionWithRedactedApiKey(
  connection: IConnection
): connection is IConnectionWithRedactedApiKey {
  return (
    (connection.perInstitutionValues as IPerInstitutionValuesWithRedactedApiKey)
      ?.redactedApiKey !== undefined
  );
}
