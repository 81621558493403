import React from "react";
import { IQuickbooksInstitution } from "~/src/types/institutions";
import { Institution } from "..";
import { useInitiateQuickbooksConnection } from "../../../hooks/initiate-connection/use-initiate-quickbooks-connection";
import { ConnectionsTable } from "../../connections-table";

interface IQuickbooksInstitutionProps {
  institution: IQuickbooksInstitution;
}

export const QuickbooksInstitution = ({
  institution,
}: IQuickbooksInstitutionProps): JSX.Element => {
  const { initiateConnection, addConnectionDisabled } =
    useInitiateQuickbooksConnection();

  return (
    <>
      <Institution
        key={institution.id}
        institution={institution}
        addConnectionButtonDisabled={addConnectionDisabled}
        onAddConnection={initiateConnection}
      >
        {!!institution.connections.length && (
          <ConnectionsTable connections={institution.connections} />
        )}
      </Institution>
    </>
  );
};
