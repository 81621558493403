import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { GoogleLoginButton } from "~/src/components/auth/google-login-button";
import ROUTES from "~/src/constants/routes";
import { authenticated } from "~/src/system/security";

import "./login.css";

export const Login = ({}) => {
  const location = useLocation();

  return authenticated() ? (
    <Redirect to={ROUTES.root.path} />
  ) : (
    <>
      <div className="login-column">
        <header className="login-header">
          {/* This should display a toast instead */}
          {location.pathname.includes("fromLogout") && (
            <p className="text-red mb-8">You have been logged out.</p>
          )}
          <h1 className="login-title">
            Welcome to Flow Finance's{" "}
            <span className="text-green">Workbench</span>
          </h1>
          <p className="login-text">
            Flow Finance combines innovative technology for founders with
            world-class accounting services, so you can stop worrying about your
            back office and focus on running your company.
          </p>
          <GoogleLoginButton />
        </header>
      </div>
      <div className="login-column">
        <div className="login__image"></div>
      </div>
    </>
  );
};
