import { useEffect, useState } from "react";
import {
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptionsWithLinkToken,
  usePlaidLink,
} from "react-plaid-link";
import { useActiveCompanyId } from "~/src/hooks/useActiveCompanyId";
import { isIInitiateConnectionResponseToken } from "~/src/state/institutions/institutions-api-types";
import { InstitutionId } from "~/src/types/institutions";
import {
  createConnectionErrorToast,
  useCreateConnection,
} from "../mutations/use-create-connection";
import { useInitiateConnection } from "../queries/use-initiate-connection";
import { useConnectionLocation } from "../utilities/use-connection-location";

const useInitiatePlaidConnection = () => {
  const companyId = useActiveCompanyId();
  const institutionId: InstitutionId = InstitutionId.PLAID;
  const connectionLocation = useConnectionLocation();
  const { initiateConnectionQuery, initiateConnectionQueryStatus } =
    useInitiateConnection();
  const { createConnection } = useCreateConnection();
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [linkLoading, setLinkLoading] = useState(false);

  const onSuccess = (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => {
    const accountNames = metadata.accounts.reduce(
      (accum: string[], account) => {
        const accountName = [account.name, account.mask].join(" ");
        return [...accum, accountName];
      },
      []
    );

    createConnection({
      institutionId,
      companyId,
      title: metadata.institution?.name,
      plaidInput: {
        authorizationCode: publicToken,
        accountNames,
        plaidInstitutionId: metadata.institution?.institution_id,
      },
    });
  };

  const onExit = () => {
    setLinkLoading(false);
    createConnectionErrorToast();
  };

  const onEvent = () => {
    if (linkLoading) setLinkLoading(false);
  };

  const plaidConfig: PlaidLinkOptionsWithLinkToken = {
    onSuccess,
    onExit,
    onEvent,
    token: linkToken,
  };

  const addConnectionDisabled =
    initiateConnectionQueryStatus.isLoading ||
    initiateConnectionQueryStatus.isFetching ||
    linkLoading;

  const initiateConnection = async () => {
    if (addConnectionDisabled) return;

    const response = await initiateConnectionQuery({
      ...connectionLocation,
      // uncomment below line for local development
      // redirectUrl: "https://development.workbench.flowfi.com",
      companyId,
      institutionId,
    }).unwrap();

    if (
      response.data &&
      isIInitiateConnectionResponseToken(response.data) &&
      response.data?.token
    ) {
      setLinkLoading(true);
      setLinkToken(response.data.token);
    }
  };

  const { open: initiateLink, ready: linkIsReady } = usePlaidLink(plaidConfig);
  useEffect(() => {
    if (linkIsReady) initiateLink();
  }, [initiateLink, linkIsReady]);

  return { initiateConnection, addConnectionDisabled };
};

export default useInitiatePlaidConnection;
