import React, { ReactNode } from "react";
import { Button } from "~/src/components/button";
import { IInstitution } from "~/src/types/institutions";
import { SVGHandler } from "~/src/components/svg-handler";
import { HelpTip } from "~/src/components/help-tip";
import "./institution.css";

interface IInstitutionProps {
  institution: IInstitution;
  onAddConnection: (e: React.MouseEvent) => void;
  addConnectionButtonDisabled?: boolean;
  children: ReactNode;
}

export const Institution = ({
  institution,
  onAddConnection,
  addConnectionButtonDisabled,
  children,
}: IInstitutionProps): JSX.Element => {
  const { displayName, connections } = institution;
  const institutionName =
    displayName + (connections.length ? ` (${connections.length})` : "");

  return (
    <div className="institution">
      <div className="institution__header">
        <div className="institution__icon">
          <SVGHandler image={institution.iconUrl} width={26} height={26} />
        </div>
        <div className="institution__info">
          <div className="institution__name-and-tip">
            <strong>{institutionName}</strong>
            <HelpTip linkTo={institution.documentationUrl} />
          </div>
          <p className="institution__impact">{institution.impact}</p>
        </div>
        <Button
          label="Add Connection"
          size="large"
          theme="primary"
          onClick={onAddConnection}
          disabled={addConnectionButtonDisabled}
        />
      </div>
      {children}
    </div>
  );
};
