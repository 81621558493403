import { useEffect } from "react";
import { setJiraEpics } from "~/src/state/jira-epics/jira-epics-data-slice";
import { useGetJiraEpicsQuery } from "~/src/state/jira-epics/jira-epics-api-slice";
import { useActiveCompanyId } from "~/src/hooks/useActiveCompanyId";
import { useDispatch } from "react-redux";
import { useAppSelector } from "~/src/system/store/hooks";

export const useJiraEpics = () => {
  const dispatch = useDispatch();
  const companyId = useActiveCompanyId();

  const jiraEpicsQuery = useGetJiraEpicsQuery(
    {
      companyId,
    },
    { skip: !companyId }
  );

  useEffect(() => {
    if (jiraEpicsQuery.isSuccess) {
      dispatch(setJiraEpics(jiraEpicsQuery.data ?? []));
    }
  }, [jiraEpicsQuery]);

  const items = useAppSelector((state) => state.jiraEpics.jiraEpics);

  return { jiraEpics: items, isLoading: jiraEpicsQuery.isLoading };
};
