import React, { useEffect, useState } from "react";
import { Button } from "~/src/components/button";
import { PortfolioTile } from "../portfolio-tile";
import { LayoutContainer } from "~/src/components/layout";
import { Icons } from "~/src/assets/icons";
import { ICompany } from "~/src/types/company";
import "./portfolio-tab.css";

interface IPortfolioTab {
  companies: ICompany[];
}

export const PortfolioTab = ({ companies }: IPortfolioTab) => {
  const [sortedCompanies, setSortedCompanies] = useState<ICompany[]>([]);
  const [sorting, setSorting] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");

  useEffect(() => {
    // Sortable
    setSortedCompanies(companies);
  }, [companies]);

  // Simple Alpha sort
  const sortCompanies = () => {
    setSorting(!sorting);
  };

  useEffect(() => {
    // currently this just sorts alphabetically a-z when toggled on
    // if you have a short list of companies, it may not appear to do anything if they're already
    // in alpha order
    if (sorting) {
      setSortedCompanies(
        [...sortedCompanies].sort((a, b) => {
          return a.name?.localeCompare(b.name);
        })
      );
    } else {
      setSortedCompanies(companies);
    }
  }, [sorting]);

  // Filter
  const filterCompanies = (e: React.FormEvent<HTMLInputElement>) => {
    setFilter(e.currentTarget.value);
  };

  return (
    <div>
      <div className="portfolio-tab__controls">
        <input
          type="text"
          className="search-input portfolio-tab__filter"
          onInput={(e) => filterCompanies(e)}
          placeholder="Search..."
        />

        <Button
          theme="secondary"
          size="large"
          onClick={sortCompanies}
          icon={Icons.default.SortAz}
          className="ml-4"
        />
      </div>

      <LayoutContainer mode="grid" layout="responsive-grid-four">
        {sortedCompanies
          .filter((company) =>
            company.name.toLowerCase().includes(filter.toLowerCase())
          )
          .map((company) => {
            return <PortfolioTile key={company.id} company={company} />;
          })}
      </LayoutContainer>
    </div>
  );
};
