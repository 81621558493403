import { FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query";
import * as Sentry from "@sentry/browser";

// generic response type for yggdrasil api
export interface IResponse {
  [key: string]: any;
}
// TODO: extend the above with similar typing from the client app

/**
 * response/result types for AppSync (GraphQL) APIs
 * - `jonathan` is the name of the api that handles account/tag/rule/transaction/categorization data
 * - `galactus` is the name of the api that handles institutions/connections
 */
interface IAppSyncApiErrorLocation {
  line: number;
  column: number;
  sourceName: string | null;
}

export interface IAppSyncApiError {
  path: string[];
  data: string | null;
  errorType: string | null;
  errorInfo: string | null;
  locations: IAppSyncApiErrorLocation[];
  message: string | null;
}

export interface IAppSyncApiResponse {
  errors?: IAppSyncApiError[];
  data?: any;
}

export interface IGruulApiResponse {
  errors?: any;
  data?: any;
}

export interface IAppSyncApiOutput extends IAppSyncApiResponse {
  requestId?: string;
}

export interface IGruulApiOutput extends IGruulApiResponse {
  requestId?: string;
}

// temporary request id log for UAT
const tempLogging = (
  apiResponse: IAppSyncApiResponse | IGruulApiResponse,
  requestId: string | undefined
) => {
  console.log(
    `${apiResponse.errors ? "🔴 " : "✅ "}${
      Object.keys(apiResponse.data ?? {})[0]
    } endpoint request id: `,
    requestId
  );
};

// generic transformer for use with the `transformResponse` property on AppSync api endpoints
export const transformAppSyncApiResponse = async (
  response: unknown,
  meta: FetchBaseQueryMeta | undefined
) => {
  const accountsApiResponse = response as IAppSyncApiResponse;
  const errors = accountsApiResponse.errors;
  const data = Object.values(accountsApiResponse.data ?? {})[0];
  const requestId =
    meta?.response?.headers.get("x-amzn-requestid") ?? undefined;

  const sentryObj = { requestId, errors: JSON.stringify(errors) };
  if (errors) {
    await Sentry.captureException(sentryObj);
  }

  tempLogging(accountsApiResponse, requestId);

  return { errors, data, requestId } as IAppSyncApiOutput;
};

export const transformGruulApiResponse = async (
  response: unknown,
  meta: FetchBaseQueryMeta | undefined
) => {
  const apiResponse = response as IGruulApiResponse;
  const errors = apiResponse.errors;
  const data = Object.values(apiResponse.data ?? {})[0];
  const requestId =
    meta?.response?.headers.get("x-amzn-requestid") ?? undefined;

  const sentryObj = { requestId, errors: JSON.stringify(errors) };
  if (errors) {
    await Sentry.captureException(sentryObj);
  }

  tempLogging(apiResponse, requestId);

  return { errors, data, requestId } as IGruulApiOutput;
};
