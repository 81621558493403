import { EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Button } from "~/src/components/button";
import { FlowFiDatePicker } from "~/src/components/datepicker";
import { RichText, richTextToHtml } from "~/src/components/rich-text";
import { SimpleSelect } from "~/src/components/simple-select";
import { Pane } from "~/src/components/pane";
import ROUTES from "~/src/constants/routes";
import { useCreateActionItemMutation } from "~/src/state/action-items/action-items-api-slice";
import { useAppSelector } from "~/src/system/store/hooks";
import { Loading } from "~/src/components/loading";
import {
  TActionItemCategory,
  TActionItemState,
} from "~/src/types/action-items";
import { toast } from "react-toastify";
import { Toast } from "~/src/components/toast";
import { BackButton } from "~/src/components/back-button";
import { getWeekFromToday } from "./utilities";

interface IActionItemNewParams {
  category: TActionItemCategory;
}

export const ActionItemNew = () => {
  const params = useParams<IActionItemNewParams>();
  const history = useHistory();
  const [
    createActionItem,
    {
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
    },
  ] = useCreateActionItemMutation();

  const jiraEpics = useAppSelector((state) => state.jiraEpics.jiraEpics);

  const activeCompanyId = useAppSelector(
    (state) => state.companiesData.customerCompanies.active?.id ?? ""
  );
  const userId = useAppSelector(
    (state) => state.userData.profiles.flowUser.user.id ?? ""
  );

  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  const [category, setCategory] = useState(params.category ?? "miscellaneous");

  const [dueDate, setDueDate] = useState<Date>();

  const [epic, setEpic] = useState<string>("");

  const submitActionItem = async (actionItemState: TActionItemState) => {
    // convert the rich text
    const richTextString = richTextToHtml(editorState);

    // use due date if given, else if item is not a draft, use week from today, else leave undefined
    const inferredDueDate =
      dueDate?.getTime().toString() ??
      (actionItemState !== "draft" ? getWeekFromToday() : undefined);
    const payload = {
      company_id: activeCompanyId,
      bookkeeper_id: userId,
      due_date: inferredDueDate,
      question: richTextString,
      category: category,
      state: actionItemState,
      jira_id: epic,
    };

    await createActionItem(payload);
  };

  useEffect(() => {
    if (params.category !== category) {
      setCategory(params.category);
    }
  }, [params.category, category]);

  useEffect(() => {
    if (createSuccess) {
      // fire success toast
      // navigate to main action items page
      toast(<Toast message={"Action item created successfully"} />);
      history.push(ROUTES.actionItems.path);
    }
  }, [createSuccess, history]);

  useEffect(() => {
    if (createError) {
      // TODO: real error handling
      console.error("Error creating action item");
    }
  }, [createError]);

  const [validated, setValidated] = useState(false);

  // This is very basic
  useEffect(() => {
    const validation = [
      editorState.getCurrentContent().hasText(),
      jiraEpics.length ? !!epic : true, // we let this pass if there are no jira epics, otherwise check state
      !!category,
    ];

    setValidated(validation.every((item) => item === true));
  }, [category, editorState, jiraEpics, epic]);

  return (
    <>
      <BackButton onClick={() => history.push(ROUTES.actionItems.path)}>
        Create a New Action Item
      </BackButton>
      <div className="action-item-detail">
        <div>
          <Pane className="p-8">
            <label htmlFor="description">Description</label>
            <div>
              <RichText
                id="description"
                placeholder="Start typing..."
                editorState={editorState}
                editorStateChange={setEditorState}
              />
              <div className="requested-info" id="requested-info">
                <label htmlFor="requested-info">Requested Information</label>
                {category === "amortization" && (
                  <>
                    <div className="requested-info__row">
                      <div className="requested-info__input">
                        Service Period (Y/N)
                      </div>
                      <div className="requested-info__input">Start Date</div>
                      <div className="requested-info__input">Date Range</div>
                    </div>
                    <div className="requested-info__row">
                      <div className="requested-info__textarea">
                        Additional Notes (optional)
                      </div>
                    </div>
                  </>
                )}
                {category === "miscellaneous" && (
                  <div className="requested-info__row">
                    <div className="requested-info__textarea">Long Answer</div>
                  </div>
                )}
                {category === "categorization" && (
                  <>
                    <div className="requested-info__row">
                      <div className="requested-info__input">Name</div>
                      <div className="requested-info__input">Account</div>
                    </div>
                    <div className="requested-info__row">
                      <div className="requested-info__textarea">
                        Additional Notes (optional)
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Pane>
        </div>
        <div>
          <Pane>
            <div className="action-item-detail__controls">
              <div className="action-item-detail__controls-row">
                <label htmlFor="due-date">Due Date:</label>
                <FlowFiDatePicker
                  id="due-date"
                  onChange={(date) => setDueDate(date)}
                  selected={dueDate}
                  placement="bottom-start"
                  placeholder="One week after published"
                />
              </div>
              <div className="action-item-detail__controls-row">
                <label htmlFor="category-select">Category:</label>
                <SimpleSelect
                  className="w-full"
                  options={[
                    { value: "miscellaneous", label: "Miscellaneous" },
                    { value: "amortization", label: "Service Period" },
                    { value: "categorization", label: "Categorization" },
                  ]}
                  onChange={(value) =>
                    history.push(
                      ROUTES.actionItems.subroutes.new.subroutes[
                        value as TActionItemCategory
                      ].path
                    )
                  }
                  name="category"
                  id="category-select"
                  value={category}
                  icon="label"
                />
              </div>
              {jiraEpics.length ? (
                <div className="action-item-detail__controls-row">
                  <label htmlFor="epic-select">Jira Epic:</label>
                  <SimpleSelect
                    className="w-full"
                    options={jiraEpics.map((epic) => {
                      return { value: epic.id, label: epic.summary };
                    })}
                    onChange={(value) => setEpic(value)}
                    name="jira"
                    value={epic}
                    id="jira"
                    placeholder="-- Select One --"
                  />
                </div>
              ) : null}
            </div>
            <div className="action-item-detail__publish-controls">
              <Button
                size="large"
                theme="primary"
                label="Save as Draft"
                disabled={!validated}
                onClick={() => submitActionItem("draft")}
              />
              <Button
                size="large"
                theme="quaternary"
                label="Publish"
                disabled={!validated}
                onClick={() => submitActionItem("awaiting_reply")}
              />
              <Button
                size="large"
                theme="danger"
                label="Delete"
                onClick={() => history.push(ROUTES.actionItems.path)}
              />
            </div>
          </Pane>
        </div>
      </div>
      {createLoading && <Loading />}
    </>
  );
};
