import React from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { useAppSelector } from "~/src/system/store/hooks";
import { setActiveCompany } from "~/src/state/companies/companies-data-slice";
import { Dropdown } from "../dropdown";
import { ScrollContainer } from "../scroll-container";
import { Button } from "../button";
import { Badge } from "../badge";
import { CompanyIcon } from "../company-icon";
import { Icons } from "~/src/assets/icons";
import { set as lsSet } from "~/src/system/ls";
import ROUTES from "~/src/constants/routes";
import { ICompany } from "~/src/types/company";
import "./company-selector.css";

interface ICompanySelector {
  companies: any[];
}

export const CompanySelector = ({ companies }: ICompanySelector) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const activeCompany = useAppSelector(
    (state) => state.companiesData.customerCompanies.active
  );

  const openPortfolio = () => {
    history.push(ROUTES.portfolio.path);
  };

  const handleSelect = (company: ICompany) => {
    dispatch(setActiveCompany(company));
    lsSet("lastActiveCompanyId", company.id);

    history.push(`${ROUTES.companies.path}/${company.id}`);
  };

  return (
    <>
      <Dropdown
        direction="right"
        rightIcon={Icons.default.ArrowRightSm}
        customTrigger={
          <div className="company-selector__trigger">
            {activeCompany?.avatar ? (
              <CompanyIcon size="xs" company={activeCompany as ICompany} />
            ) : (
              <img src={Icons.default.UserCircle} alt="company icon" />
            )}
            <span>
              {activeCompany ? activeCompany.name : "Select a Company"}
            </span>
          </div>
        }
        isSelect
        noPadding
      >
        <div className="company-selector__dropdown">
          <h3 className="company-selector__label">Assigned Customers</h3>
          <ScrollContainer noPadding height={250}>
            <ul className="company-selector__dropdown-items">
              {companies.map((company: ICompany, i) => {
                return (
                  <li
                    key={`company-item-${i}`}
                    className="company-selector__dropdown-item"
                  >
                    <button onClick={() => handleSelect(company)}>
                      {company.name}
                    </button>
                    {/* TODO: We will need the count of action items per assigned company for this. */}
                    <Badge count={2} />
                  </li>
                );
              })}
            </ul>
          </ScrollContainer>
          <div className="company-selector__portfolio-link">
            <Button
              label="Open Portfolio"
              size="small"
              theme="primary"
              disabled={false}
              onClick={openPortfolio}
              fullWidth
            />
          </div>
        </div>
      </Dropdown>
    </>
  );
};
