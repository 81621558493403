import React from "react";
import "./badge.css";

interface IBadge {
  label?: string | null;
  count: number;
}

export const Badge = ({ count, label }: IBadge) => {
  return (
    <div className="badge">
      {count} {label ? label : ""}
    </div>
  );
};
