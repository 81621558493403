import React from "react";
import "./svg-handler.css";

interface ISVGHandler {
  width: number;
  height: number;
  image: string;
  className?: string;
  altText?: string;
}

export const SVGHandler = ({
  width,
  height,
  image,
  className,
  altText,
}: ISVGHandler) => {
  return (
    <div className="svg-icon">
      <img
        className={className || ""}
        src={image}
        width={width}
        height={height}
        alt={altText}
      />
    </div>
  );
};
