import React, { useEffect, useState } from "react";
import Classnames from "classnames";

import { Button } from "~/src/components/button";
import { Dropdown } from "~/src/components/dropdown";
import { useAppSelector } from "~/src/system/store/hooks";
import { IAccountWithTags } from "~/src/types/accounts";
import "./accounts-autocomplete.css";
import { getAccountName } from "~/src/utilities/accounts";

interface IAccountsAutocompleteProps {
  inputValue: string;
  inputKeyEvent: React.KeyboardEvent<HTMLInputElement> | undefined;
  mapTag: (externalAccountId: string) => void;
  resetForm: () => void;
}

/* 
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
*/

export const AccountsAutocomplete: React.FunctionComponent<IAccountsAutocompleteProps> = ({
  mapTag,
  inputValue,
  inputKeyEvent,
  resetForm,
}) => {
  // state
  const allAccounts = useAppSelector(
    (state) => state.accounts.accountTagMapping.accounts
  );
  const [suggestedAccounts, setSuggestedAccounts] = useState<
    IAccountWithTags[]
  >([]);
  const [selectedSuggestionIdx, setSelectedSuggestionIdx] = useState<number>(0);

  // set suggested accounts when input changes
  useEffect(() => {
    if (inputValue) {
      setSuggestedAccounts(
        allAccounts
          .filter((account) => {
            return getAccountName(account.account)
              .toLowerCase()
              .includes(inputValue.toLowerCase());
          })
          .sort((a, b) => {
            const nameA = getAccountName(a.account).toLowerCase();
            const nameB = getAccountName(b.account).toLowerCase();
            if (nameA > nameB) return 1;
            else if (nameA < nameB) return -1;
            else return 0;
          })
          .slice(0, 10)
      );
    }
    setSelectedSuggestionIdx(0);
  }, [inputValue]);

  // handle key input key events in autocomplete
  useEffect(() => {
    if (inputKeyEvent) {
      switch (inputKeyEvent.code) {
        case "Tab":
          handleMapTag();
          break;
        case "Enter":
          handleMapTag();
          break;
        case "Escape":
          resetForm();
          break;
        case "ArrowDown":
          if (selectedSuggestionIdx === suggestedAccounts.length - 1) break;
          else setSelectedSuggestionIdx(selectedSuggestionIdx + 1);
          break;
        case "ArrowUp":
          if (selectedSuggestionIdx === 0) break;
          else setSelectedSuggestionIdx(selectedSuggestionIdx - 1);
          break;
        default:
          break;
      }
    }
  }, [inputKeyEvent]);

  const handleMapTag = () => {
    if (noSuggestions) return;
    mapTag(suggestedAccounts[selectedSuggestionIdx].account.externalId);
  };
  const noSuggestions = !suggestedAccounts.length;

  return (
    <Dropdown
      direction="left"
      forceOpen={!!inputValue && !noSuggestions}
      dropdownClassName="accounts-autocomplete"
      textOnly
    >
      <div className="autocomplete">
        {inputValue &&
          suggestedAccounts.map((account, idx) => (
            <div
              key={account.account.externalId}
              className={Classnames("autocomplete__row", {
                "autocomplete__row--selected": idx === selectedSuggestionIdx,
              })}
            >
              <span>{getAccountName(account.account)}</span>
              <Button
                label="Assign To"
                theme="primary"
                size="small"
                onClick={() => mapTag(account.account.externalId)}
                noWrap
              />
            </div>
          ))}
      </div>
    </Dropdown>
  );
};
