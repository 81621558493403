import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Icons } from "~/src/assets/icons";
import { Button } from "~/src/components/button";
import { Dropdown } from "~/src/components/dropdown";
import { Loading } from "~/src/components/loading";
import { PageHeader } from "~/src/components/page-header";
import { HelpTip } from "~/src/components/help-tip";
import { SVGHandler } from "~/src/components/svg-handler";
import { Toast } from "~/src/components/toast";
import ROUTES from "~/src/constants/routes";
import {
  // useBatchCreateActionItemMutation,
  useDeleteActionItemMutation,
  useUpdateActionItemContentsMutation,
  useUpdateActionItemStateMutation,
} from "~/src/state/action-items/action-items-api-slice";
// import { useLazyTransactionLineItemsQuery } from "~/src/state/transactions/transactions-api-slice";
// import { useAppSelector } from "~/src/system/store/hooks";
import {
  // ICreateActionItemInput,
  TActionItemState,
} from "~/src/types/action-items";
import { ActionItemDetailView } from "./action-item-detail-view";
import { ActionItemNew } from "./action-item-new";
import { ActionItemRow } from "./action-item-row";
import { ActionItemsClosed } from "./action-items-closed";
import "./action-items.css";
// import { getWeekFromToday, getActionItemByTransactionId } from "./utilities";
import { getWeekFromToday } from "./utilities"; // use the above line when restoring load drafts
import { useActionItems } from "~/src/hooks/useActionItems";
import { useJiraEpics } from "~/src/hooks/useJiraEpics";

export const ActionItems = () => {
  const history = useHistory();
  const { search } = useLocation();

  const { actionItems: items, isLoading: actionItemsLoading } =
    useActionItems();

  const { isLoading: jiraEpicsLoading } = useJiraEpics();

  const [updateActionItemState, updateActionItemStateResult] =
    useUpdateActionItemStateMutation();
  const [deleteActionItem, deleteActionItemResult] =
    useDeleteActionItemMutation();
  // const [
  //   batchCreateActionItem,
  //   batchCreateActionItemResult,
  // ] = useBatchCreateActionItemMutation();
  const [updateActionItemContents, updateActionItemContentsResult] =
    useUpdateActionItemContentsMutation();
  // const [
  //   transactionLineItemsQuery,
  //   transactionLineItemsResults,
  // ] = useLazyTransactionLineItemsQuery();

  const pageLoading =
    jiraEpicsLoading ||
    actionItemsLoading ||
    updateActionItemStateResult.isLoading ||
    deleteActionItemResult.isLoading ||
    updateActionItemContentsResult.isLoading;

  // const categorizedAccountIds = useAppSelector((state) =>
  //   state.accounts.accountTagMapping.accounts
  //     .filter((account) => {
  //       return !!account.tags.filter(
  //         (tag) =>
  //           tag.name.toLowerCase() === "uncategorized expense" ||
  //           tag.name.toLowerCase() === "uncategorized income"
  //       ).length;
  //     })
  //     .map((account) => account.account.externalId)
  // );

  const totalItems = items.filter((item) => item.state !== "closed").length;
  const draftItems = items.filter((item) => item.state === "draft");
  const awaitingReplyItems = items.filter(
    (item) => item.state === "awaiting_reply"
  );
  const readyItems = items.filter((item) => item.state === "ready_for_action");

  // page variables
  const [currentTab, setCurrentTab] = useState<TActionItemState>("draft");
  const [selectedAll, setSelectedAll] = useState(false);
  const [activeItemId, setActiveItemId] = useState("");
  const filteredItems = items.filter((item) => item.state === currentTab);

  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleItemSelected = (id: string) => {
    const newSelectedItems = { ...selectedItems };
    newSelectedItems[id] = !newSelectedItems[id] ? true : false;
    setSelectedItems({ ...newSelectedItems });
  };

  const bulkSelect = (val: boolean) => {
    if (!val) {
      setSelectedItems({});
      return;
    }
    const newSelectedItems = { ...selectedItems };
    filteredItems.forEach((item) => {
      newSelectedItems[item.id] = val;
    });
    setSelectedItems({ ...newSelectedItems });
  };

  const changeTabs = (val: TActionItemState) => {
    setCurrentTab(val);
    setSelectedItems({});
    setSelectedAll(false);
  };

  const updateDraftDate = async (id: string) => {
    const draftItem = items.find(
      (item) => item.id === id && item.state === "draft" && !item.due_date
    );
    if (draftItem) {
      await updateActionItemContents({
        action_item_id: draftItem.id,
        due_date: getWeekFromToday(),
        category: draftItem.category,
        question: draftItem.question,
      });
    }
  };

  const updateSingleItemState = async (id: string, state: TActionItemState) => {
    await updateDraftDate(id);
    await updateActionItemState({
      action_item_id: id,
      state: state,
    });
  };

  const updateSelectedItems = async () => {
    if (currentTab !== "draft") {
      // if we're not on the draft page, unpublish the selected items
      Object.keys(selectedItems).forEach(async (id) => {
        await updateSingleItemState(id, "draft");
      });
      return;
    } else {
      Object.keys(selectedItems).forEach(async (id) => {
        await updateSingleItemState(id, "awaiting_reply");
      });

      // reset tab state - could also be used here to switch to the "ready" tab if Product wants that behavior
      changeTabs(currentTab);
    }
  };

  const deleteSelectedItems = async () => {
    if (currentTab !== "draft") {
      console.error("ERROR: only drafts can be deleted.");
      return;
    } else {
      Object.keys(selectedItems).forEach(async (id) => {
        await deleteActionItem({
          action_item_id: id,
        });
      });

      // reset tab state - could also be used here to switch to the "ready" tab if Product wants that behavior
      changeTabs(currentTab);
    }
  };

  // create new drafts when uncategorized line items are successfully loaded
  // useEffect(() => {
  //   if (
  //     transactionLineItemsResults.isSuccess &&
  //     !!transactionLineItemsResults.data?.data
  //   ) {
  //     const payload: ICreateActionItemInput[] = [];
  //     transactionLineItemsResults.data.data.forEach((lineItem) => {
  //       const actionItemInput: ICreateActionItemInput = {
  //         company_id: companyId,
  //         bookkeeper_id: userId,
  //         question: `
  //         <p>We saw a ${lineItem.type} transaction with memo line ${lineItem.memo}
  //         dated ${lineItem.date} for $${lineItem.amount}.</p>
  //         <p>Who was this, and how should it be categorized?</p>
  //       `,
  //         category: "categorization",
  //         state: "draft",
  //         transaction_id: lineItem.externalTransactionId,
  //         transaction_line_item_id: lineItem.lineItemId,
  //         transaction_sync_token: lineItem.transactionSyncToken,
  //         transaction_posting_type: lineItem.postingType,
  //       };
  //       const actionItemExists = !!getActionItemByTransactionId(
  //         items,
  //         lineItem.externalTransactionId,
  //         lineItem.lineItemId
  //       ).length;

  //       if (!actionItemExists) payload.push(actionItemInput);
  //     });
  //     if (payload.length) {
  //       batchCreateActionItem(payload);
  //     } else {
  //       // prodcut will probably want different copy here
  //       toast(<Toast message={"No new drafts to load."} />);
  //     }
  //   }
  // }, [transactionLineItemsResults]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search).get("item_id");
    if (searchParams !== activeItemId) {
      setActiveItemId(searchParams || "");
    }
  }, [
    activeItemId,
    updateActionItemStateResult,
    deleteActionItemResult,
    search,
    updateActionItemContentsResult,
  ]);

  // toasts
  useEffect(() => {
    if (updateActionItemStateResult.isSuccess) {
      toast(<Toast message={"Action item updated successfully"} />);
    } else if (updateActionItemStateResult.isError) {
      // TODO real error handling state (none defined yet)
      console.error("ERROR: updateActionItemState");
    }

    if (deleteActionItemResult.isSuccess) {
      toast(<Toast message={"Action item deleted successfully"} />);
    } else if (deleteActionItemResult.isError) {
      // TODO real error handling
      console.error("ERROR: deleteActionItem");
    }

    // if (batchCreateActionItemResult.isSuccess) {
    //   toast(<Toast message={"Drafts loaded successfully"} />);
    // }
  }, [
    updateActionItemStateResult,
    deleteActionItemResult,
    // batchCreateActionItemResult,
  ]);

  const topRowButtonsActive = !!Object.keys(selectedItems).length;

  // set the label which will appear on the right side of each Action Item row in the list
  const ctaLabel =
    currentTab === "draft"
      ? "Publish"
      : currentTab === "awaiting_reply"
      ? "Unpublish"
      : currentTab === "ready_for_action"
      ? "Close"
      : "";

  // set the label for bulk operations
  const bulkOperationLabel =
    currentTab === "draft" ? "Publish Items" : "Unpublish Items";

  // if a user clicks the CTA on an action item, it will update the item's status accordingly based on the current tab
  const onCtaClick = async (id: string) => {
    const newItemState =
      currentTab === "draft"
        ? "awaiting_reply"
        : currentTab === "awaiting_reply"
        ? "draft"
        : currentTab === "ready_for_action"
        ? "closed"
        : null;
    if (newItemState) {
      await updateSingleItemState(id, newItemState);
    }
  };

  return (
    <>
      <Switch>
        <Route path={ROUTES.actionItems.subroutes.new.path}>
          <ActionItemNew />
        </Route>
        <Route path={ROUTES.actionItems.subroutes.closed.path}>
          <ActionItemsClosed items={items} />
        </Route>
        <Route path={ROUTES.actionItems.path}>
          {activeItemId ? (
            <div>
              <ActionItemDetailView
                item={items.filter((item) => item.id === activeItemId)[0]}
                updateStateHandler={updateSingleItemState}
              />
            </div>
          ) : (
            <div>
              <div className="action-items-header-row">
                {/* <h2 className="page-header"></h2> */}
                {/* <Button
                  size={"large"}
                  theme={"primary"}
                  label="Load Drafts"
                  onClick={() =>
                    transactionLineItemsQuery({
                      companyId,
                      categorizedAccountIds,
                    })
                  }
                /> */}
              </div>

              <div className="action-items-header-row">
                <PageHeader
                  icon={Icons.default.Navigation}
                  label={`Action Items (${totalItems})`}
                  className="account-tags__header"
                >
                  <HelpTip linkTo="https://www.notion.so/flowfinance/Action-Items-727663f67bc944b9ab92570c4f444a05" />
                </PageHeader>
                <Dropdown
                  direction="center"
                  customTrigger={
                    <div className="action-items__create-trigger">
                      Create a New Item{" "}
                      <SVGHandler
                        width={20}
                        height={20}
                        image={Icons.white.ArrowDownLg}
                      />
                    </div>
                  }
                >
                  <ul className="dropdown__options">
                    <li className="dropdown__option">
                      <Link
                        to={
                          ROUTES.actionItems.subroutes.new.subroutes[
                            "categorization"
                          ].path
                        }
                      >
                        Categorization
                      </Link>
                    </li>
                    <li className="dropdown__option">
                      <Link
                        to={
                          ROUTES.actionItems.subroutes.new.subroutes[
                            "amortization"
                          ].path
                        }
                      >
                        Service Period
                      </Link>
                    </li>
                    <li className="dropdown__option">
                      <Link
                        to={
                          ROUTES.actionItems.subroutes.new.subroutes[
                            "miscellaneous"
                          ].path
                        }
                      >
                        Miscellaneous
                      </Link>
                    </li>
                  </ul>
                </Dropdown>
              </div>
              <ul className="action-items__tabs">
                <li>
                  <button
                    className={`action-items__tab ${
                      currentTab === "draft" ? "action-items__tab--active" : ""
                    }`}
                    onClick={() => changeTabs("draft")}
                  >
                    {`Draft (${draftItems.length})`}{" "}
                    <div className="action-items-tab__pip"></div>
                  </button>
                </li>
                <li>
                  <button
                    className={`action-items__tab ${
                      currentTab === "awaiting_reply"
                        ? "action-items__tab--active"
                        : ""
                    }`}
                    onClick={() => changeTabs("awaiting_reply")}
                  >{`Awaiting Reply (${awaitingReplyItems.length})`}</button>
                </li>
                <li>
                  <button
                    className={`action-items__tab ${
                      currentTab === "ready_for_action"
                        ? "action-items__tab--active"
                        : ""
                    }`}
                    onClick={() => changeTabs("ready_for_action")}
                  >
                    {`Ready for Action (${readyItems.length})`}
                    <div className="action-items-tab__pip action-items-tab__pip--green"></div>
                  </button>
                </li>
              </ul>
              <div className="action-items__table-controls">
                {currentTab !== "ready_for_action" && (
                  <div className="action-items__mass-controls">
                    <div className="action-items__select-all">
                      <label htmlFor="selectAll">
                        <input
                          className="mr-2"
                          type="checkbox"
                          id="selectAll"
                          checked={selectedAll}
                          onChange={(e) => {
                            bulkSelect(e.currentTarget.checked);
                            setSelectedAll(e.currentTarget.checked);
                          }}
                        />
                        Select all <strong>{filteredItems.length}</strong> items
                      </label>
                    </div>
                    <Button
                      size="medium"
                      label={bulkOperationLabel}
                      theme="secondary"
                      disabled={!topRowButtonsActive}
                      onClick={async () => await updateSelectedItems()}
                    />
                    {currentTab !== "awaiting_reply" && (
                      <Button
                        size="medium"
                        label="Delete Items"
                        theme="secondary"
                        disabled={!topRowButtonsActive}
                        onClick={async () => await deleteSelectedItems()}
                      />
                    )}
                  </div>
                )}
                <div className="action-items__closed-button-row">
                  <Button
                    size="medium"
                    label="Closed Items"
                    icon={Icons.default.ArrowRightLg}
                    iconPosition="right"
                    theme="secondary"
                    onClick={() =>
                      history.push(ROUTES.actionItems.subroutes.closed.path)
                    }
                  />
                </div>
              </div>
              <table className="table action-items-container">
                <tbody>
                  {filteredItems?.map((item) => {
                    return (
                      <ActionItemRow
                        item={item}
                        key={item.id}
                        selectable={currentTab !== "ready_for_action"}
                        selected={!!selectedItems[item.id]}
                        onSelect={toggleItemSelected}
                        ctaLabel={ctaLabel}
                        onCtaClick={async (id) => await onCtaClick(id)}
                        readyForAction={item.state === "ready_for_action"}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </Route>
      </Switch>
      {pageLoading && <Loading />}
    </>
  );
};
