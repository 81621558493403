import { configureStore } from "@reduxjs/toolkit";
import sidebarReducer from "~/src/state/sidebar/sidebar-slice";
import userDataReducer from "~/src/state/user/user-data-slice";
import companiesDataReducer from "~/src/state/companies/companies-data-slice";
import accountsReducer from "~/src/state/accounts/accounts-data-slice";
import transactionsReducer from "~/src/state/transactions/transactions-data-slice";
import actionItemsReducer from "~/src/state/action-items/action-items-data-slice";
import rulesReducer from "~/src/state/rules/rules-data-slice";
import institutionsReducer from "~/src/state/institutions/institutions-data-slice";
import jiraEpicsReducer from "~/src/state/jira-epics/jira-epics-data-slice";
import { userApi } from "~/src/state/user/user-api-slice";
import { companiesApi } from "~/src/state/companies/companies-api-slice";
import { accountsApi } from "~/src/state/accounts/accounts-api-slice";
import { transactionsApi } from "~/src/state/transactions/transactions-api-slice";
import { supportingSchedulesApi } from "~/src/state/supporting-schedules/supporting-schedules-api-slice";
import { ruleVaultApi } from "~/src/state/rules/rules-api-slice";
import { actionItemsApi } from "~/src/state/action-items/action-items-api-slice";
import { institutionsApi } from "~/src/state/institutions/institutions-api-slice";
import { jiraEpicsApi } from "~/src/state/jira-epics/jira-epics-api-slice";

export const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    userData: userDataReducer,
    [userApi.reducerPath]: userApi.reducer,
    companiesData: companiesDataReducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    accounts: accountsReducer,
    [accountsApi.reducerPath]: accountsApi.reducer,
    actionItems: actionItemsReducer,
    [actionItemsApi.reducerPath]: actionItemsApi.reducer,
    transactions: transactionsReducer,
    [transactionsApi.reducerPath]: transactionsApi.reducer,
    [supportingSchedulesApi.reducerPath]: supportingSchedulesApi.reducer,
    ruleVault: rulesReducer,
    [ruleVaultApi.reducerPath]: ruleVaultApi.reducer,
    institutions: institutionsReducer,
    [institutionsApi.reducerPath]: institutionsApi.reducer,
    jiraEpics: jiraEpicsReducer,
    [jiraEpicsApi.reducerPath]: jiraEpicsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(companiesApi.middleware)
      .concat(accountsApi.middleware)
      .concat(transactionsApi.middleware)
      .concat(supportingSchedulesApi.middleware)
      .concat(ruleVaultApi.middleware)
      .concat(actionItemsApi.middleware)
      .concat(institutionsApi.middleware)
      .concat(jiraEpicsApi.middleware);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
