import React, { useEffect } from "react";
import Classnames from "classnames";

import { Button } from "~/src/components/button";
import { Icons } from "~/src/assets/icons";
import "./table-paginator.css";
import { Pane } from "../pane";

interface ITablePaginatorProps {
  offset: number;
  setOffset: Function;
  limit: number;
  length: number;
}

/**
 * - offset: the index of the first row that should be displayed on the page
 * - setOffset: the setter function for the offset value
 * - limit: the max number of rows set to display per page
 * - length: the total number of rows from all pages
 *
 * In the parent component, a useEffect (with a dependency array including offset and limit)
 * should do something like this (for example):
 *    setPageDisplayRows(data.slice(offset, offset + limit));
 * In this example the page should render only the rows from setPageDisplayRows state.
 */
export const TablePaginator: React.FunctionComponent<ITablePaginatorProps> = ({
  offset,
  setOffset,
  limit,
  length,
}) => {
  const activePageIdx = Math.floor(offset / limit);

  const getAllButtonLabels = () => {
    const labels = [];
    let label = 0;
    for (let i = 0; i < length; i += limit) {
      labels.push(++label);
    }
    return labels;
  };

  /**
   * if <= 7 pages, show them, else get the labels for the subset of pages that
   * makes sense contextually for which page you're currently on
   */
  const gapLabel = "...";
  const getButtonLabels = () => {
    let labels: (string | number)[] = [...getAllButtonLabels()];
    const maxButtons = 7;
    if (labels.length > maxButtons) {
      if (activePageIdx >= labels.length - 4) {
        labels = [
          ...labels.slice(0, 2),
          gapLabel,
          ...labels.slice(labels.length - 4, labels.length),
        ];
      } else if (activePageIdx <= 3) {
        labels = [
          ...labels.slice(0, 4),
          gapLabel,
          ...labels.slice(labels.length - 2, labels.length),
        ];
      } else if (activePageIdx > 3) {
        labels = [
          ...labels.slice(0, 1),
          gapLabel,
          ...labels.slice(activePageIdx - 1, activePageIdx + 2),
          gapLabel,
          ...labels.slice(labels.length - 1, labels.length),
        ];
      } else {
        labels = [...labels.slice(0, maxButtons)];
      }
    }
    return labels;
  };

  // scroll to top when changing pages
  useEffect(() => {
    if (document) {
      const viewContainer = document.querySelector(
        ".scroll-container.view-container"
      );
      if (viewContainer) {
        viewContainer.scrollTop = 0;
      }
    }
  }, [offset, length]);

  // page left or right
  const page = (forward: boolean) => {
    let result = offset + (forward ? limit : limit * -1);
    if (result > length) result = offset;
    if (result < 0) result = 0;
    setOffset(result);
  };

  // jump to page number
  const jump = (label: number | string) => {
    if (typeof label === "string") return;
    setOffset(limit * (label - 1));
  };

  return length > limit ? (
    <div className="flex gap-2">
      <Button
        theme="secondary"
        size="medium"
        icon={Icons.default.ArrowLeftLg}
        onClick={() => page(false)}
      />
      <Pane className="paginator__pages">
        {getButtonLabels().map((label, idx) => (
          <button
            key={`${label}-${idx}`}
            className={Classnames("table-paginator__button", {
              "table-paginator__button--active": label === activePageIdx + 1,
            })}
            onClick={() => jump(label)}
          >
            {label}
          </button>
        ))}
      </Pane>
      <Button
        theme="secondary"
        size="medium"
        icon={Icons.default.ArrowRightLg}
        onClick={() => page(true)}
      />
    </div>
  ) : null;
};
