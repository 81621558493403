import React from "react";
import { Button } from "~/src/components/button";
import { IActionItem } from "~/src/types/action-items";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { formatActionItemCategory } from "./utilities";
import { useHistory } from "react-router";
import Classnames from "classnames";
import { formatDisplayDate } from "~/src/utilities/dates";
import ROUTES from "~/src/constants/routes";
import { ActionItemResponse } from "./action-item-response";
import { QuickApplyAction } from "./components/quick-apply";
import { useAppSelector } from "~/src/system/store/hooks";

interface IActionItemRowProps {
  item: IActionItem;
  selectable: boolean;
  selected?: boolean;
  onSelect?: (id: string) => void;
  ctaLabel: string;
  onCtaClick?: (id: string) => void;
  condensed?: boolean;
  readyForAction?: boolean;
}

export const ActionItemRow = ({
  item,
  selectable,
  selected,
  onSelect,
  ctaLabel,
  onCtaClick,
  condensed,
  readyForAction,
}: IActionItemRowProps) => {
  const history = useHistory();

  const jiraEpics = useAppSelector((state) => state.jiraEpics.jiraEpics);

  const viewDetailsOnClick = (id: string) => {
    const params = new URLSearchParams();
    params.delete("item_id");
    params.append("item_id", id);
    history.push({
      pathname: ROUTES.actionItems.path,
      search: params.toString(),
    });
  };

  const rowClasses = Classnames("action-item-row", {
    "action-item-row--condensed": condensed,
  });

  const hasResponses =
    item.miscellaneous_responses ||
    item.amortization_responses ||
    item.categorization_responses;

  // "Condensed" rows shouldn't display the select box or publish controls, so they are omitted.
  return (
    <tr className={rowClasses}>
      {!condensed && selectable && (
        <td>
          <label className="action-item-row__select" htmlFor={item.id}>
            <input
              type="checkbox"
              id={item.id}
              checked={selected}
              onChange={onSelect ? () => onSelect(item.id) : () => null}
            />
          </label>
        </td>
      )}
      <td className="action-item-row__question">
        {/* This isn't really an ideal markup scenario, but this entire UI is changing soon. */}
        <div className="mb-2 font-bold text-green">
          {jiraEpics.find((epic) => epic.id === item.jira_id)?.summary}
        </div>
        <div className="rich-text-output">{ReactHtmlParser(item.question)}</div>
        {hasResponses ? <ActionItemResponse item={item} /> : null}
      </td>
      <td className="action-item-row__date">
        {item.due_date
          ? formatDisplayDate(moment(parseInt(item.due_date)))
          : "One week from published"}
      </td>
      <td>
        <span className="action-item-row__category">
          {formatActionItemCategory(item.category)}
        </span>
      </td>

      <td className="action-item-row__controls">
        <div className="action-item-row__controls-wrapper">
          {!!readyForAction && !!onCtaClick && !!item.transaction_id ? (
            <QuickApplyAction item={item} onApply={onCtaClick} />
          ) : null}
          {ctaLabel ? (
            <Button
              size="medium"
              theme="primary"
              label={ctaLabel}
              onClick={() => {
                if (onCtaClick) onCtaClick(item.id);
              }}
            />
          ) : null}
          <Button
            size="medium"
            theme="secondary"
            label="Details"
            onClick={() => viewDetailsOnClick(item.id)}
          />
        </div>
      </td>
    </tr>
  );
};
