import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import {
  IActionItem,
  ICreateActionItemInput,
  IDeleteActionItemInput,
  IUpdateActionItemContentsInput,
  IUpdateActionItemStateInput,
} from "../../types/action-items";

const actionItemModel = `
  id
  company_id
  bookkeeper_id
  due_date
  question
  category
  state
  transaction_id
  transaction_line_item_id
  transaction_sync_token
  transaction_posting_type
  jira_id
  amortization_responses {
    id
    action_item_id
    is_amortization
    start_date
    service_period_months
    additional_notes
  }
  categorization_responses {
    id
    action_item_id
    name
    account
    additional_notes
    merchant_id
    account_id
  }
  miscellaneous_responses {
    id
    action_item_id
    long_answer
  }
`;

interface IActionItemsQueryArgs {
  userId: string;
  companyId: string;
}

export const actionItemsApi = createApi({
  reducerPath: "actionItemsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.STATE_API,
    prepareHeaders(headers) {
      headers.set("content-type", "application/json");
      return headers;
    },
  }),
  tagTypes: ["ActionItems"],
  endpoints: (build) => {
    return {
      getActionItems: build.query<IActionItem[], IActionItemsQueryArgs>({
        providesTags: ["ActionItems"],
        query: ({ userId, companyId }) => {
          const query = `
          query getActionItems($user_id: ID!, $company_id: ID!, $config: QueryConfig = {limit: 1000, offset: 0}) {
            getActionItems(user_id: $user_id, company_id: $company_id, config: $config) {
              ${actionItemModel}
            }
          }
          `;
          const variables = {
            user_id: userId,
            company_id: companyId,
          };
          return {
            url: `action-items/`,
            method: "POST",
            body: { query, variables },
          };
        },
        transformResponse: (response: {
          data?: { getActionItems: IActionItem[] };
        }) => {
          return response.data?.getActionItems || [];
        },
      }),
      createActionItem: build.mutation<void, ICreateActionItemInput>({
        query: (data) => {
          const query = `
            mutation CreateActionItem($data: CreateActionItemInput!) {
              createActionItem(data: $data) {
                id
              }
            }
          `;
          const variables = {
            data,
          };
          return {
            url: "action-items/",
            method: "POST",
            body: { query, variables },
          };
        },
        invalidatesTags: ["ActionItems"],
      }),
      updateActionItemState: build.mutation<void, IUpdateActionItemStateInput>({
        query: (data) => {
          const query = `
            mutation UpdateActionItemState($data: UpdateActionItemStateInput!) {
              updateActionItemState(data: $data) {
                id
              }
            }
          `;
          const variables = {
            data,
          };
          return {
            url: "action-items/",
            method: "POST",
            body: { query, variables },
          };
        },
        invalidatesTags: ["ActionItems"],
      }),
      updateActionItemContents: build.mutation<
        void,
        IUpdateActionItemContentsInput
      >({
        query: (data) => {
          const query = `
            mutation UpdateActionItemContents($data: UpdateActionItemContentsInput!) {
              updateActionItemContents(data: $data) {
                id
              }
            }
          `;
          const variables = {
            data,
          };
          return {
            url: "action-items/",
            method: "POST",
            body: { query, variables },
          };
        },
        invalidatesTags: ["ActionItems"],
      }),
      deleteActionItem: build.mutation<void, IDeleteActionItemInput>({
        query: (data) => {
          const query = `
            mutation DeleteActionitem($data: DeleteActionItemInput!) {
              deleteActionItem(data: $data) {
                id
              }
            }
          `;
          const variables = {
            data,
          };
          return {
            url: "action-items/",
            method: "POST",
            body: { query, variables },
          };
        },
        invalidatesTags: ["ActionItems"],
      }),
      batchCreateActionItem: build.mutation<void, ICreateActionItemInput[]>({
        query: (input) => {
          const query = `
            mutation BatchCreateActionItem($input: [CreateActionItemInput!]) {
              batchCreateActionItem(input: $input) {
                id
              }
            }
          `;
          const variables = {
            input,
          };
          return {
            url: "action-items/",
            method: "POST",
            body: { query, variables },
          };
        },
        invalidatesTags: ["ActionItems"],
      }),
    };
  },
});

export const {
  useGetActionItemsQuery,
  useCreateActionItemMutation,
  useUpdateActionItemStateMutation,
  useUpdateActionItemContentsMutation,
  useDeleteActionItemMutation,
  useBatchCreateActionItemMutation,
} = actionItemsApi;
