import { Dispatch, SetStateAction, useEffect } from "react";
import { useActiveCompanyId } from "~/src/hooks/useActiveCompanyId";
import { InstitutionId } from "~/src/types/institutions";
import { IKlaviyoInputWithTitle } from "../../components/institution/klaviyo-institution/create-klaviyo-connection-form";
import { useCreateConnection } from "../mutations/use-create-connection";

export const useCreateKlaviyoConnection = (
  setInputModalOpen: Dispatch<SetStateAction<boolean>>,
  klaviyoInput: IKlaviyoInputWithTitle | undefined,
  setKlaviyoInput: Dispatch<SetStateAction<IKlaviyoInputWithTitle | undefined>>
) => {
  const companyId = useActiveCompanyId();
  const institutionId: InstitutionId = InstitutionId.KLAVIYO;

  const { createConnection, createConnectionStatus } = useCreateConnection();

  const addConnectionDisabled = createConnectionStatus.isLoading;

  const initiateConnection = () => {
    setInputModalOpen(true);
  };

  useEffect(() => {
    if (addConnectionDisabled || !klaviyoInput) return;

    createConnection({
      companyId,
      institutionId,
      title: klaviyoInput.title,
      klaviyoInput: {
        apiKey: klaviyoInput.apiKey,
      },
    });

    return () => setKlaviyoInput(undefined);
  }, [
    addConnectionDisabled,
    klaviyoInput,
    companyId,
    institutionId,
    setKlaviyoInput,
    createConnection,
  ]);

  return { initiateConnection, addConnectionDisabled };
};
