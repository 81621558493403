import React from "react";
import moment from "moment";
import { Icons } from "~/src/assets/icons";
import { SVGHandler } from "~/src/components/svg-handler";
import "./connection-health-cell.css";

function formatConnectionHealthDate(dateString: string) {
  const momentDate = moment(dateString);
  const date = momentDate.format("MM-DD-YY");
  const time = momentDate.format("h:mma");
  return `${date} at ${time}`;
}

interface IConnectionHealthCellProps {
  healthy: boolean;
  healthLastChecked: string;
}

export const ConnectionHealthCell = ({
  healthy,
  healthLastChecked,
}: IConnectionHealthCellProps): JSX.Element => {
  return (
    <div className="connection-health-cell">
      <div className="connection-health-cell__symbol">
        <SVGHandler
          image={
            healthy
              ? Icons.green.CheckCircledFill
              : Icons.yellow.ExclamationCircledFill
          }
          width={16}
          height={16}
        />
      </div>
      <span>{formatConnectionHealthDate(healthLastChecked)}</span>
    </div>
  );
};
