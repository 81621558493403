import React from "react";
import { UserMenu } from "../user-menu";
import { CompanySelector } from "../company-selector";
import { IUser } from "~/src/types/user";
import "./top-bar.css";
import { ICustomerCompanies } from "~/src/state/companies/companies-data-slice";

interface ITopBarProps {
  user: IUser;
  customerCompanies: ICustomerCompanies;
  hidden: boolean;
}

export const TopBar = ({ user, customerCompanies, hidden }: ITopBarProps) => {
  return (
    <>
      {!hidden && (
        <div className="top-bar">
          {user && (
            <>
              <CompanySelector companies={customerCompanies.assigned} />
              <UserMenu className="ml-auto" user={user} />
            </>
          )}
        </div>
      )}
    </>
  );
};
