import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { useAppSelector } from "~/src/system/store/hooks";
import {
  removeUncatTagIdInEdit,
  setUncatTagIdInEdit,
} from "~/src/state/accounts/accounts-data-slice";
import { ITag } from "~/src/types/accounts";
import { useUpdateTagMappingMutation } from "~/src/state/accounts/accounts-api-slice";
import { Toast } from "~/src/components/toast";
import { Icons } from "~/src/assets/icons";
import { Button } from "~/src/components/button";
import { IUpdateTagMappingInput } from "~/src/state/accounts/accounts-api-types";

import { AccountsAutocomplete } from "./accounts-autocomplete";
import "./account-form.css";

interface IAccountFormProps {
  tag: ITag;
}

/* 
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable.
*/

export const AccountForm: React.FunctionComponent<IAccountFormProps> = ({
  tag,
}) => {
  const dispatch = useDispatch();

  // initialize state/refs
  const companyId = useAppSelector(
    (state) => state.companiesData.customerCompanies.active?.id || ""
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [inputKeyEvent, setInputKeyEvent] =
    useState<React.KeyboardEvent<HTMLInputElement>>();
  const handleInputKeyEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (["Tab", "ArrowDown", "ArrowUp", "Escape"].includes(e.code)) {
      e.preventDefault();
    }
    setInputKeyEvent(e);
  };
  const tagIdInEdit = useAppSelector(
    (state) => state.accounts.uncatTagIdInEdit
  );

  // tag crud
  const setTagInEdit = () => {
    dispatch(setUncatTagIdInEdit(tag.id));
    inputRef.current?.focus();
  };

  const resetForm = () => {
    setInputValue("");
    dispatch(removeUncatTagIdInEdit(tag.id));
  };

  // tag crud
  const [updateTagMapping, updateTagMappingMutation] =
    useUpdateTagMappingMutation();
  const mapTag = (externalAccountId: string) => {
    if (updateTagMappingMutation.isLoading) return;
    const updateTagMappingInput: IUpdateTagMappingInput = {
      companyId,
      externalAccountId,
      tagIds: [tag.id],
    };
    resetForm();
    updateTagMapping(updateTagMappingInput);
  };

  // manage tag mutation states
  useEffect(() => {
    if (updateTagMappingMutation.isLoading) {
      toast(
        <Toast
          message="Categorizing tag..."
          icon={Icons.green.LoadingAnimated}
        />,
        { toastId: "updateTagMappingMutationLoading", autoClose: false }
      );
    } else {
      toast.dismiss("updateTagMappingMutationLoading");
    }

    if (
      updateTagMappingMutation.isSuccess &&
      !!updateTagMappingMutation.data.data
    ) {
      toast(<Toast message="Tag categorized!" icon={Icons.green.Info} />);
    }
    if (
      updateTagMappingMutation.isError ||
      !!updateTagMappingMutation.data?.errors
    ) {
      toast(
        <Toast
          message="There was a problem categorizing the tag. Please try again."
          icon={Icons.green.Info}
        />
      );
    }
  }, [updateTagMappingMutation]);

  // focus on input &
  // add/remove click event listener to clear form on off click
  useEffect(() => {
    inputRef.current?.focus();
    if (tag.id !== tagIdInEdit) return;
    const handleClick = (e: MouseEvent) => {
      if ((e.target as HTMLElement).closest(".uncat-tags__input")) {
        return;
      }
      resetForm();
    };
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, [tagIdInEdit]);

  return (
    <div className="uncat-tags__assign">
      {tag.id === tagIdInEdit ? (
        <>
          <input
            type="text"
            className="uncat-tags__input"
            ref={inputRef}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleInputKeyEvent}
            placeholder="Start Typing"
          />
          <AccountsAutocomplete
            inputValue={inputValue}
            inputKeyEvent={inputKeyEvent}
            mapTag={mapTag}
            resetForm={resetForm}
          />
        </>
      ) : (
        <Button
          theme="secondary"
          size="medium"
          label="Assign to Account"
          onClick={setTagInEdit}
        />
      )}
    </div>
  );
};
