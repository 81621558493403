import React from "react";
import classNames from "classnames";

import "./institution-form-errors.css";

export type InstitutionFormErrors = {
  [key: string]: string[];
};

interface IInstitutionFormErrorsProps {
  errorKey: string;
  errors: { [key: string]: string[] };
  className?: string;
}

export const InstitutionFormErrors = ({
  errorKey,
  errors,
  className,
}: IInstitutionFormErrorsProps): JSX.Element => {
  const formErrors = errors[errorKey] ?? [];

  return (
    <>
      {!!formErrors.length && (
        <div className={classNames("institution-form-errors", className ?? "")}>
          <ul>
            {formErrors.map((error) => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
