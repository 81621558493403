export const connectionSchema = `
  __typename
  id
  companyId
  createdAt
  healthLastChecked
  healthy
  institutionId
  title
  perInstitutionValues {
    __typename
    ... on PlaidConnectionValues {
      accountNames
    }
    ... on KlaviyoConnectionValues {
      redactedApiKey
    }
  }
`;

export const institutionsSchema = `
  __typename
  id
  connections { ${connectionSchema} }
  displayName
  documentationUrl
  iconUrl
  impact
`;

const initiateConnectionResponseUrl = `
  url
`;

const initiateConnectionResponseToken = `
  token
`;

export const initiateConnectionSchema = `
  __typename
  ... on InitiateConnectionResponseUrl { ${initiateConnectionResponseUrl} }
  ... on InitiateConnectionResponseToken { ${initiateConnectionResponseToken} }
`;

export const deleteConnectionSchema = `
  connectionId
`;

export const checkConnectionHealthSchema = `
  __typename
  connectionId
  healthy
  healthLastChecked
`;
