import { useDispatch } from "react-redux";
import { replaceConnectionIdInEdit } from "~/src/state/institutions/institutions-data-slice";
import { useAppSelector } from "~/src/system/store/hooks";

// too many modals can slow the page down; this is used to render modals only when the appropriate connection is being edited
export const useConnectionIdInEdit = (connectionId: string | undefined) => {
  const dispatch = useDispatch();
  const updateConnectionIdInEdit = () =>
    dispatch(replaceConnectionIdInEdit(connectionId));
  const connectionIdInEdit = useAppSelector(
    (state) => state.institutions.connectionIdInEdit === connectionId
  );
  return { updateConnectionIdInEdit, connectionIdInEdit };
};
