import React from "react";
import Classnames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";

interface IFlowFiDatePicker {
  selected?: Date | undefined;
  startDate?: Date;
  endDate?: Date;
  disabled?: boolean;
  readOnly?: boolean;
  onChange: (date: Date) => void;
  className?: string;
  id?: string;
  placeholder?: string;
  label?: string;
  placement?: TDatepickerPlacements;
  labelPlacement?: TDatepickerLabelPlacements;
  showMonthYearPicker?: boolean;
  dateFormat?: string;
}

type TDatepickerPlacements =
  | "top-start"
  | "top-end"
  | "bottom-start"
  | "bottom-end"
  | "right-start"
  | "right-end"
  | "left-start"
  | "left-end"
  | "auto"
  | "auto-start"
  | "auto-end";

type TDatepickerLabelPlacements = "top" | "left";

export const FlowFiDatePicker = ({
  onChange,
  disabled,
  selected,
  startDate,
  endDate,
  readOnly,
  className,
  id,
  placeholder,
  label,
  placement,
  labelPlacement = "left",
  showMonthYearPicker,
  dateFormat,
}: IFlowFiDatePicker) => {
  const handleChange = (date: Date) => {
    onChange(date);
  };

  const classNames = Classnames("flowfi-datepicker", className || "", {
    "flowfi-datepicker--label": !!label,
    "flowfi-datepicker--label-left": labelPlacement === "left",
  });

  const wrapperClasses = Classnames("flowfi-datepicker__wrapper", {
    "flowfi-datepicker__wrapper--label-left": labelPlacement === "left",
  });

  const labelClasses = Classnames("flowfi-datepicker__label", {
    "flowfi-datepicker__label--left": labelPlacement === "left",
  });

  return (
    <div className={wrapperClasses}>
      {label && <label className={labelClasses}>{label}</label>}
      <DatePicker
        id={id}
        className={classNames}
        onChange={(date: Date) => handleChange(date)}
        disabled={disabled}
        startDate={startDate}
        endDate={endDate}
        selected={selected}
        readOnly={readOnly}
        placeholderText={placeholder}
        popperClassName={"flowfi-datepicker__popper"}
        popperPlacement={placement ?? "auto"}
        useWeekdaysShort
        showMonthYearPicker={showMonthYearPicker}
        dateFormat={dateFormat}
      />
    </div>
  );
};
