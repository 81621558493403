import React from "react";
import { Link } from "react-router-dom";
import Classnames from "classnames";
import ROUTES from "~/src/constants/routes";
import Logo from "~/src/assets/images/workbench-logo.svg";
import { SVGHandler } from "../svg-handler";
import "./app-logo.css";

interface ILogoProps {
  collapsed?: boolean;
  dark?: boolean;
  marginless?: boolean;
}

export const AppLogo = ({ collapsed, dark, marginless }: ILogoProps) => {
  const logoClasses = Classnames("workbench-logo", {
    "workbench-logo--dark": dark,
    "workbench-logo--marginless": marginless,
  });

  return (
    <Link to={ROUTES.root.path} className="hover:no-underline">
      <h1 className={logoClasses}>
        <div className="workbench-logo__icon">
          <SVGHandler image={Logo} width={36} height={36} />
        </div>

        {!collapsed && <div className="workbench-logo__label">Workbench</div>}
      </h1>
    </Link>
  );
};
