import React from "react";
import {
  IActionItem,
  IAmortizationResponse,
  ICategorizationResponse,
  IMiscellaneousResponse,
} from "~/src/types/action-items";
import ReactHtmlParser from "react-html-parser";
import { formatDisplayDate } from "~/src/utilities/dates";
import moment from "moment";

interface IActionItemResponseProps {
  item: IActionItem;
}

export const ActionItemResponse = ({ item }: IActionItemResponseProps) => {
  const responses =
    item.category === "miscellaneous"
      ? (item.miscellaneous_responses as IMiscellaneousResponse[])
      : item.category === "amortization"
      ? (item.amortization_responses as IAmortizationResponse[])
      : item.category === "categorization"
      ? (item.categorization_responses as ICategorizationResponse[])
      : [];

  const actionItemResponse = (responses || [])[0];
  return (
    <div className="action-item-row__misc-response-bubble">
      <div>
        {item.category === "miscellaneous" ? (
          <div className="rich-text-output">
            {ReactHtmlParser(
              (actionItemResponse as IMiscellaneousResponse)?.long_answer
            )}
          </div>
        ) : item.category === "amortization" ? (
          <>
            <div className="grid grid-cols-3">
              <div>
                Is Service Period:{" "}
                {(actionItemResponse as IAmortizationResponse)?.is_amortization
                  ? "Yes"
                  : "No"}
              </div>
              <div>
                Start Date:{" "}
                {(actionItemResponse as IAmortizationResponse)?.is_amortization
                  ? formatDisplayDate(
                      moment(
                        (actionItemResponse as IAmortizationResponse).start_date
                      )
                    )
                  : "N/A"}
              </div>
              <div>
                Service Period Months:{" "}
                {(actionItemResponse as IAmortizationResponse)
                  ?.service_period_months || "N/A"}
              </div>
            </div>
          </>
        ) : item.category === "categorization" ? (
          <div className="grid grid-cols-3">
            <div>
              <strong>Merchant:</strong>{" "}
              {
                ((item.categorization_responses as ICategorizationResponse[]) ||
                  [])[0]?.name
              }
            </div>
            <div>
              <strong>Account:</strong>{" "}
              {
                ((item.categorization_responses as ICategorizationResponse[]) ||
                  [])[0]?.account
              }
            </div>
          </div>
        ) : null}
      </div>
      <div>
        {item.category !== "miscellaneous" &&
        (actionItemResponse as ICategorizationResponse | IAmortizationResponse)
          ?.additional_notes ? (
          <div>
            <div className="mt-2">
              {ReactHtmlParser(
                (
                  actionItemResponse as
                    | ICategorizationResponse
                    | IAmortizationResponse
                ).additional_notes
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
