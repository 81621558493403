import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { Icons } from "~/src/assets/icons";
import { AccountTagSelector } from "~/src/components/account-tag-selector";
import { Button } from "~/src/components/button";
import { LineItemSummary } from "~/src/components/line-item-summary";
import { ScrollContainer } from "~/src/components/scroll-container";
import { SVGHandler } from "~/src/components/svg-handler";
import { useCategorizeTransactionMutation } from "~/src/state/transactions/transactions-api-slice";
import { useAppSelector } from "~/src/system/store/hooks";
import { IMerchant, IUncategorizedLineItem } from "~/src/types/transactions";
import { Toast } from "~/src/components/toast";
import {
  categorizeLineItems,
  removeMerchantCategorizationSuggestion,
} from "~/src/state/transactions/transactions-data-slice";
import { MerchantSelector } from "~/src/components/merchant-selector";
import { ICategorizeTransactionLineItemInput } from "~/src/state/transactions/transactions-api-types";

import "./manually-categorize-form.css";

interface IManuallyCategorizeFormProps {
  lineItem: IUncategorizedLineItem;
  onClose: Function;
}

export const ManuallyCategorizeForm: React.FunctionComponent<IManuallyCategorizeFormProps> =
  ({ lineItem, onClose }) => {
    const dispatch = useDispatch();

    // state & props
    const { externalTransactionId, transactionSyncToken, lineItemId } =
      lineItem;
    const companyId = useAppSelector(
      (state) => state.companiesData.customerCompanies.active?.id || ""
    );
    const [merchant, setMerchant] = useState<IMerchant>();
    const [externalAccountId, _setExternalAccountId] = useState<string>("");
    const [_tagId, _setTagId] = useState<string>("");
    const accountTagSelectorOnChange = (values: {
      accountId: string;
      tagId: string;
    }) => {
      _setExternalAccountId(values.accountId);
      _setTagId(values.tagId);
    };
    const [formReady, setFormReady] = useState<boolean>(false);

    // Handle form submit/cancel
    const [categorizeTransaction, categorizeTransactionMutation] =
      useCategorizeTransactionMutation();
    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      if (!formReady) return;
      if (merchant) {
        const categorizeTransactionLineItemInput: ICategorizeTransactionLineItemInput =
          {
            companyId,
            externalAccountId,
            externalTransactionId,
            lineItemId,
            transactionSyncToken,
            merchantId: merchant.externalId,
          };
        onClose();
        categorizeTransaction(categorizeTransactionLineItemInput);
      }
    };
    const handleCancel = () => {
      onClose();
    };

    // handle categorization mutation status
    const loadingToastId = "categorizeTransaction";
    useEffect(() => {
      if (categorizeTransactionMutation.isUninitialized) return;
      if (categorizeTransactionMutation.isLoading) {
        toast(
          <Toast
            message="Categorizing transaction..."
            icon={Icons.green.LoadingAnimated}
          />,
          { toastId: loadingToastId, autoClose: false }
        );
      } else {
        toast.dismiss(loadingToastId);
      }
      if (
        categorizeTransactionMutation.isSuccess &&
        !!categorizeTransactionMutation.data.data
      ) {
        dispatch(
          categorizeLineItems([categorizeTransactionMutation.data.data])
        );
        toast(
          <Toast message="Transaction categorized!" icon={Icons.green.Info} />
        );

        // if the user chose an account that did not match the suggestion, remove the suggestion from the merchant
        if (
          !!merchant?.categorizationSuggestion &&
          merchant.categorizationSuggestion.externalId !== externalAccountId
        ) {
          dispatch(removeMerchantCategorizationSuggestion(merchant.externalId));
        }
      }
      if (
        categorizeTransactionMutation.isError ||
        !!categorizeTransactionMutation.data?.errors
      ) {
        toast(
          <Toast
            message="There was a problem categorizing the transaction. Please try again."
            icon={Icons.green.Info}
          />
        );
      }
    }, [categorizeTransactionMutation]);

    // validate the form inputs
    useEffect(() => {
      !!merchant && !!externalAccountId
        ? setFormReady(true)
        : setFormReady(false);
    }, [merchant, externalAccountId]);

    return (
      <>
        <h3 className="section-header sidebar-form__header">
          <SVGHandler
            image={Icons.default.SettingsGear}
            width={32}
            height={32}
            altText="Load transactions edit inbox icon"
          />
          Manually Categorize
        </h3>
        <ScrollContainer noPadding fullHeight>
          <form
            onSubmit={handleSubmit}
            className="categorize-transaction-form sidebar-form"
          >
            <LineItemSummary lineItem={lineItem} />
            <div className="manually-categorize-form__row">
              <MerchantSelector
                merchant={merchant}
                setMerchant={setMerchant}
                postingType={lineItem.postingType}
                required
              />
            </div>
            <AccountTagSelector
              tagId={_tagId}
              merchantWithSuggestedAccount={
                merchant?.categorizationSuggestion ? merchant : undefined
              }
              onChange={accountTagSelectorOnChange}
              accountRequired
            />
            <div className="sidebar-form__submit-row">
              <Button
                label="Cancel"
                theme="secondary"
                size="large"
                type="button"
                onClick={handleCancel}
              />
              <Button
                label="Categorize"
                theme="primary"
                size="large"
                type="submit"
                disabled={!formReady}
              />
            </div>
          </form>
        </ScrollContainer>
      </>
    );
  };
