import React from "react";
import "./connections-table.css";
import {
  isConnectionWithRedactedApiKey,
  IConnection,
} from "~/src/types/institutions";
import { ConnectionsTableRow } from "./connections-table-row";

interface IConnectionsTable {
  connections: IConnection[];
}

export const ConnectionsTable = ({
  connections,
}: IConnectionsTable): JSX.Element => (
  <div className="connections-table__wrapper">
    <table className="connections-table">
      <thead>
        <tr>
          <th className="connections-table__title-column">Connection Title</th>
          <th className="connections-table__api-key-column">
            {isConnectionWithRedactedApiKey(connections[0]) && "Api Key"}
          </th>
          <th className="connections-table__status-column">
            Connection Status
          </th>
          <th className="connections-table__edit-column" />
        </tr>
      </thead>
      <tbody>
        {connections.map((connection, idx) => (
          <ConnectionsTableRow
            key={connection.id}
            connection={connection}
            isLast={idx === connections.length - 1}
          />
        ))}
      </tbody>
    </table>
  </div>
);
