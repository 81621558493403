import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IConnection, IInstitution } from "~/src/types/institutions";
import {
  IDeleteConnectionResponse,
  ICheckConnectionHealthResponse,
} from "./institutions-api-types";

interface IInstitutionsInitialState {
  institutions: IInstitution[];
  connectionIdInEdit?: string;
}

const initialState: IInstitutionsInitialState = {
  institutions: [],
};

const institutionsSlice = createSlice({
  name: "institutions",
  initialState,
  reducers: {
    saveInstitutions(state, action: PayloadAction<IInstitution[]>) {
      state.institutions = action.payload;
    },
    addConnection(state, action: PayloadAction<IConnection>) {
      state.institutions = [...state.institutions].map((institution) => {
        if (
          institution.id === action.payload.institutionId &&
          institution.connections.every((c) => c.id !== action.payload.id)
        )
          return {
            ...institution,
            connections: [...institution.connections, action.payload].sort(
              (a, b) => a.title.localeCompare(b.title)
            ),
          };
        else return institution;
      });
    },
    removeConnection(state, action: PayloadAction<IDeleteConnectionResponse>) {
      state.institutions = [...state.institutions].map((institution) => {
        return {
          ...institution,
          connections: institution.connections.filter(
            (c) => c.id !== action.payload.connectionId
          ),
        };
      });
    },
    updateConnectionHealth(
      state,
      action: PayloadAction<ICheckConnectionHealthResponse>
    ) {
      state.institutions = [...state.institutions].map((institution) => {
        return {
          ...institution,
          connections: institution.connections.map((c) => {
            if (c.id === action.payload.connectionId)
              return {
                ...c,
                healthy: action.payload.healthy,
                healthLastChecked: action.payload.healthLastChecked,
              };
            else return c;
          }),
        };
      });
    },
    replaceConnection(state, action: PayloadAction<IConnection>) {
      state.institutions = [...state.institutions].map((institution) => {
        return {
          ...institution,
          connections: institution.connections
            .map((c) => {
              if (c.id === action.payload.id) return action.payload;
              else return c;
            })
            .sort((a, b) => a.title.localeCompare(b.title)),
        };
      });
    },
    replaceConnectionIdInEdit(
      state,
      action: PayloadAction<string | undefined>
    ) {
      state.connectionIdInEdit = action.payload;
    },
  },
});

export const {
  saveInstitutions,
  addConnection,
  removeConnection,
  updateConnectionHealth,
  replaceConnection,
  replaceConnectionIdInEdit,
} = institutionsSlice.actions;
export default institutionsSlice.reducer;
