import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { accountsSchema, tagSchema } from "./accounts-api-schema";
import {
  IAccountTagMappingOutput,
  IAccountOutput,
  IDeleteTagMappingInput,
  IDeleteTagMappingOutput,
  IUpdateTagMappingInput,
  IUpdateTagMappingOutput,
} from "./accounts-api-types";
import { transformAppSyncApiResponse as transformResponse } from "~/src/system/resource";

/* 
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
*/

/* 
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
*/

export const accountsApi = createApi({
  reducerPath: "accountsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.JONATHAN_API,
    prepareHeaders(headers) {
      headers.set("content-type", "application/json");
      headers.set(
        "authorization",
        `Bearer ${process.env.JONATHAN_API_TOKEN || ""}`
      );
      return headers;
    },
  }),
  tagTypes: ["AccountTagsMapping", "Accounts"],
  endpoints: (build) => {
    return {
      accountTagMapping: build.query<IAccountTagMappingOutput, string>({
        query: (companyId: string) => {
          const query = `
            query AccountTagMapping($companyId: ID!) {
              accountTagMapping(companyId: $companyId) {
                accounts { ${accountsSchema} }
                unmappedTags { ${tagSchema} }
              }
            }
          `;
          const variables = {
            companyId,
          };
          return {
            url: `/`,
            method: "POST",
            body: { query, variables },
          };
        },
        providesTags: ["AccountTagsMapping", "Accounts"],
        transformResponse,
      }),
      accounts: build.query<IAccountOutput, string>({
        query: (companyId: string) => {
          const query = `
            query AccountTagMapping($companyId: ID!) {
              accountTagMapping(companyId: $companyId) {
                accounts { ${accountsSchema} }
              }
            }
          `;
          const variables = {
            companyId,
          };
          return {
            url: `/`,
            method: "POST",
            body: { query, variables },
          };
        },
        providesTags: ["Accounts"],
        transformResponse,
      }),
      updateTagMapping: build.mutation<
        IUpdateTagMappingOutput,
        IUpdateTagMappingInput
      >({
        query: (updateTagMappingInput: IUpdateTagMappingInput) => {
          const query = `
            mutation UpdateTagMapping($input: UpdateTagMappingInput!) {
              updateTagMapping(input: $input) { ${accountsSchema} }
            }
          `;

          const variables = { input: updateTagMappingInput };
          return {
            url: `/`,
            method: "POST",
            body: { query, variables },
          };
        },
        invalidatesTags: ["AccountTagsMapping"],
        transformResponse,
      }),
      deleteTagMapping: build.mutation<
        IDeleteTagMappingOutput,
        IDeleteTagMappingInput
      >({
        query: (deleteTagMappingInput: IDeleteTagMappingInput) => {
          const query = `
            mutation DeleteTagMapping($input: DeleteTagMappingInput!) {
              deleteTagMapping(input: $input) { ${accountsSchema} }
            }
          `;
          const variables = { input: deleteTagMappingInput };
          return {
            url: `/`,
            method: "POST",
            body: { query, variables },
          };
        },
        invalidatesTags: ["AccountTagsMapping"],
        transformResponse,
      }),
    };
  },
});

export const {
  useAccountTagMappingQuery,
  useAccountsQuery,
  useLazyAccountsQuery,
  useUpdateTagMappingMutation,
  useDeleteTagMappingMutation,
} = accountsApi;
