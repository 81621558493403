import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IActionItem } from "../../types/action-items";

interface IActionItemsDataState {
  actionItems: IActionItem[];
}

const initialState: IActionItemsDataState = {
  actionItems: [],
};

const actionItemsDataSlice = createSlice({
  name: "actionItems",
  initialState,
  reducers: {
    setActionItems(state, action: PayloadAction<IActionItem[]>) {
      state.actionItems = action.payload;
    },
  },
});

export const { setActionItems } = actionItemsDataSlice.actions;
export default actionItemsDataSlice.reducer;
