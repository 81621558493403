import React from "react";
import { ISelectOption, Select } from "~/src/components/select";

import "./table-limit.css";

export type TTableLimitValue = 10 | 20 | 50 | 100;

interface ITableLimitProps {
  value: TTableLimitValue;
  setValue: Function;
}

export const TableLimit: React.FunctionComponent<ITableLimitProps> = ({
  value,
  setValue,
}) => {
  const options = [
    { id: 10, label: "10 rows" },
    { id: 20, label: "20 rows" },
    { id: 50, label: "50 rows" },
    { id: 100, label: "100 rows" },
  ];

  const handleSelect = (limitOption: ISelectOption) => setValue(limitOption.id);

  return (
    <div className="table-select">
      <span className="text-gray-dark">Show:</span>
      <Select
        options={options}
        defaultOption={options.find((option) => option.id === value)}
        onSelect={handleSelect}
        // theres probably a better way to fix the height here
        buttonClassName="bg-white h-10"
      />
    </div>
  );
};
