import React from "react";
import { IPlaidInstitution } from "~/src/types/institutions";
import { Institution } from "..";
import useInitiatePlaidConnection from "../../../hooks/initiate-connection/use-initiate-plaid-connection";
import { ConnectionsTable } from "../../connections-table";

interface IPlaidInstitutionProps {
  institution: IPlaidInstitution;
}

export const PlaidInstitution = ({
  institution,
}: IPlaidInstitutionProps): JSX.Element => {
  const { initiateConnection, addConnectionDisabled } =
    useInitiatePlaidConnection();

  return (
    <>
      <Institution
        key={institution.id}
        institution={institution}
        addConnectionButtonDisabled={addConnectionDisabled}
        onAddConnection={initiateConnection}
      >
        {!!institution.connections.length && (
          <ConnectionsTable connections={institution.connections} />
        )}
      </Institution>
    </>
  );
};
