import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { decrypt, encrypt } from "~/src/system/security";
import { authenticated } from "~/src/system/security";
import * as session from "~/src/system/session";
import { useVerifyGoogleUserQuery } from "~/src/state/user/user-api-slice";
import { IGoogleUser, IUserProfiles } from "~/src/types/user";
import { useDispatch } from "react-redux";
import { saveUserData } from "~/src/state/user/user-data-slice";
import { IResponse } from "~/src/system/resource";
import { Loading } from "~/src/components/loading";
import { Toast } from "~/src/components/toast";
import { toast } from "react-toastify";
import { Icons } from "~/src/assets/icons";
import "./google-login-button.css";

const clientId = process.env.GOOGLE_CLIENT_ID || "";

interface IGoogleLoginButtonProps {
  disabled?: boolean;
}

export const GoogleLoginButton: React.FunctionComponent<IGoogleLoginButtonProps> =
  ({ disabled }) => {
    // state
    const [googleProfileObject, setGoogleProfileObject] =
      useState<IGoogleUser | null>(null);
    const [disabledState, setDisabledState] = useState<boolean>(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const { data, isFetching } = useVerifyGoogleUserQuery(googleProfileObject, {
      skip: !googleProfileObject,
    });
    useEffect(() => {
      if (data && !authenticated()) {
        initializeUser(data);
      }
    }, [data]);

    function initializeUser(data: IResponse) {
      const flowUser = decrypt(data.token);
      const googleUser = googleProfileObject;
      const userProfiles = {
        googleUser,
        flowUser,
      };
      dispatch(saveUserData(userProfiles as IUserProfiles));
      session.create(encrypt(userProfiles));

      const from = (history?.location?.state as any)?.from;
      !!from && history.push({ pathname: from.pathname, search: from.search });
    }

    async function onSuccess(
      response: GoogleLoginResponse | GoogleLoginResponseOffline
    ): Promise<void> {
      if ((response as GoogleLoginResponse).profileObj) {
        setGoogleProfileObject((response as GoogleLoginResponse).profileObj);
      }
    }

    function handleDisabledCookies() {
      toast(
        <Toast
          message="Sign in with Google is currently unavailable."
          icon={Icons.green.Info}
        />
      );
      setDisabledState(true);
    }

    function onFailure(error: any): void {
      const cookiesDisabled = error.error === "idpiframe_initialization_failed";
      if (cookiesDisabled) {
        handleDisabledCookies();
        return;
      }
      toast(
        <Toast
          message="There was a problem signing in. Please try again."
          icon={Icons.green.Info}
        />
      );
      setDisabledState(false);
    }

    return (
      <>
        <GoogleLogin
          clientId={clientId}
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy="single_host_origin"
          className="google-login-button"
          disabled={disabled || disabledState || isFetching}
        />
        {isFetching ? <Loading /> : null}
      </>
    );
  };
