import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAccountsByBlock,
  IAccountTagMapping,
  ITag,
} from "~/src/types/accounts";

/* 
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
*/

function getAccountsByBlock(accountTagMapping: IAccountTagMapping) {
  const result: IAccountsByBlock = {};
  accountTagMapping.accounts.forEach((account) => {
    const accountBlock = account.account.accountBlock;
    if (!result[accountBlock]) {
      result[accountBlock] = {
        block: accountBlock,
        accounts: [],
        tags: [],
      };
    }
    result[accountBlock]?.accounts.push(account);
    result[accountBlock]?.tags.push(...account.tags);
  });
  return result;
}

function flattenTags(accountTagMapping: IAccountTagMapping) {
  const mappedTags: ITag[] = [];
  const unmappedTags: ITag[] = [];
  accountTagMapping.accounts.forEach((account) =>
    account.tags.forEach((tag) => mappedTags.push(tag))
  );
  accountTagMapping.unmappedTags.forEach((tag) => unmappedTags.push(tag));
  return { mappedTags, unmappedTags };
}

export type IAccountTagsEdits = {
  [key: string]: ITagsEdit;
};

export interface ITagsEdit {
  externalAccountId: string;
  tags: ITag[];
}

interface IAccountsState {
  accountTagMapping: IAccountTagMapping;
  accountsByBlock: IAccountsByBlock;
  accountTagsEdits: IAccountTagsEdits;
  uncatTagIdInEdit: string;
  allTags: IAllTags;
}

interface IAllTags {
  mappedTags: ITag[];
  unmappedTags: ITag[];
}

const initialAccountTagMappingState: IAccountTagMapping = {
  accounts: [],
  unmappedTags: [],
};

const initialState: IAccountsState = {
  accountTagMapping: initialAccountTagMappingState,
  accountsByBlock: {},
  accountTagsEdits: {},
  uncatTagIdInEdit: "",
  allTags: { mappedTags: [], unmappedTags: [] },
};

const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    saveAccountTagMapping(state, action: PayloadAction<IAccountTagMapping>) {
      state.accountTagMapping = action.payload;
      state.accountsByBlock = getAccountsByBlock(action.payload);
      state.allTags = flattenTags(action.payload);
    },
    updateAccountTagsEdits(state, action: PayloadAction<ITagsEdit>) {
      Object.assign(state.accountTagsEdits, {
        [action.payload.externalAccountId]: action.payload,
      });
    },
    deleteAccountTagsEdit(state, action: PayloadAction<string>) {
      delete state.accountTagsEdits[action.payload];
    },
    clearAccountTagsEdits(state) {
      state.accountTagsEdits = {};
    },
    setUncatTagIdInEdit(state, action: PayloadAction<string>) {
      state.uncatTagIdInEdit = action.payload;
    },
    removeUncatTagIdInEdit(state, action: PayloadAction<string>) {
      if (action.payload === state.uncatTagIdInEdit) {
        state.uncatTagIdInEdit = "";
      }
    },
  },
});

export const {
  saveAccountTagMapping,
  updateAccountTagsEdits,
  deleteAccountTagsEdit,
  clearAccountTagsEdits,
  setUncatTagIdInEdit,
  removeUncatTagIdInEdit,
} = accountsSlice.actions;
export default accountsSlice.reducer;
