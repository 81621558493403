import React, { useEffect, useState } from "react";
import Classnames from "classnames";

import { Button } from "~/src/components/button";
import { Dropdown } from "~/src/components/dropdown";
import { IMerchant } from "~/src/types/transactions";
import { Icons } from "~/src/assets/icons";

import { TMerchantMutationStatus } from "..";
import { SVGHandler } from "~/src/components/svg-handler";
import "./merchant-autocomplete.css";

interface IMerchantAutocompleteProps {
  open: boolean;
  inputValue: string;
  setInputValue: Function;
  inputKeyEvent: React.KeyboardEvent<HTMLInputElement> | undefined;
  merchants: IMerchant[];
  onAddNewMerchant: () => void;
  onSelectExistingMerchant: (merchant: IMerchant) => void;
  mutationStatus: TMerchantMutationStatus;
  inputRef: React.RefObject<HTMLInputElement>;
  merchantIsSelected: boolean;
}

export const MerchantAutocomplete: React.FunctionComponent<
  IMerchantAutocompleteProps
> = ({
  open,
  inputValue,
  setInputValue,
  inputKeyEvent,
  merchants,
  onAddNewMerchant,
  onSelectExistingMerchant,
  mutationStatus,
  inputRef,
  merchantIsSelected,
}) => {
  const [suggestedMerchants, setSuggestedMerchants] = useState<IMerchant[]>([]);
  const [selectedSuggestionIdx, setSelectedSuggestionIdx] = useState<number>(0);
  const [merchantIsDuplicate, setMerchantIsDuplicate] =
    useState<boolean>(false);

  // set suggested accounts when input changes
  useEffect(() => {
    if (inputValue) {
      setSuggestedMerchants(
        merchants
          .filter((merchant) =>
            merchant.name
              .toLowerCase()
              .trim()
              .includes(inputValue.toLowerCase().trim())
          )
          .sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA > nameB) return 1;
            else if (nameA < nameB) return -1;
            else return 0;
          })
          .slice(0, 10)
      );
    }
    setSelectedSuggestionIdx(0);
  }, [inputValue]);

  // handle key input key events in autocomplete
  useEffect(() => {
    if (inputKeyEvent) {
      const confirmMerchant = () => {
        if (
          suggestedMerchants.length &&
          suggestedMerchants[selectedSuggestionIdx]
        ) {
          onSelectExistingMerchant(suggestedMerchants[selectedSuggestionIdx]);
        } else if (inputValue) {
          onAddNewMerchant();
        }
      };
      switch (inputKeyEvent.code) {
        case "Enter":
          confirmMerchant();
          break;
        case "Tab":
          confirmMerchant();
          break;
        case "Escape":
          !merchantIsSelected && setInputValue("");
          inputRef.current?.blur();
          break;
        case "ArrowDown":
          if (
            selectedSuggestionIdx ===
            suggestedMerchants.length - (merchantIsDuplicate ? 1 : 0)
          )
            break;
          else setSelectedSuggestionIdx(selectedSuggestionIdx + 1);
          break;
        case "ArrowUp":
          if (selectedSuggestionIdx === 0) break;
          else setSelectedSuggestionIdx(selectedSuggestionIdx - 1);
          break;
        default:
          break;
      }
    }
  }, [inputKeyEvent]);

  useEffect(() => {
    if (
      merchants.find(
        (merchant) =>
          merchant.name.toLowerCase().trim() === inputValue.toLowerCase().trim()
      )
    )
      setMerchantIsDuplicate(true);
    else setMerchantIsDuplicate(false);
  }, [inputValue, merchants]);

  return (
    <Dropdown direction="left" forceOpen={open} textOnly>
      {mutationStatus !== "LOADING" ? (
        <div className="autocomplete">
          <div
            onMouseLeave={() => setSelectedSuggestionIdx(-1)}
            className="autocomplete"
          >
            {!!inputValue &&
              suggestedMerchants.map((merchant, idx) => (
                <button
                  key={merchant.externalId}
                  className={Classnames("autocomplete__row", {
                    "autocomplete__row--selected":
                      idx === selectedSuggestionIdx,
                    "autocomplete__row--hover": selectedSuggestionIdx < 0,
                  })}
                  onClick={() => onSelectExistingMerchant(merchant)}
                  onMouseEnter={() => setSelectedSuggestionIdx(idx)}
                >
                  {merchant.name}
                </button>
              ))}
          </div>
          {!merchantIsDuplicate && (
            <div
              className={Classnames(
                "autocomplete__row",
                "merchant-autocomplete__last-row",
                {
                  "autocomplete__row--selected":
                    selectedSuggestionIdx === suggestedMerchants.length,
                }
              )}
            >
              <span>{inputValue}</span>
              <Button
                theme="primary"
                size="small"
                label="Add New"
                onClick={onAddNewMerchant}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-center">
          <SVGHandler
            width={56}
            height={56}
            image={Icons.green.LoadingAnimated}
          />
        </div>
      )}
    </Dropdown>
  );
};
