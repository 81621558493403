import React, { useEffect } from "react";
import { useAppSelector } from "~/src/system/store/hooks";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ROUTES from "./constants/routes";
import { LayoutContainer } from "./components/layout";
import { TopBar } from "./components/top-bar";
import { Sidebar } from "./components/sidebar";
import { AccountTags } from "./pages/account-tags";
import { Categorization } from "./pages/categorization";
import { RulesVault } from "./pages/rules-vault";
// import { SupportingSchedules } from "./pages/supporting-schedules";
import { RulesSuggestions } from "./pages/categorization-rules-suggestions";
import { ErrorPage } from "./pages/error";
import { Login } from "./pages/login";
import { Portfolio } from "./pages/portfolio";
import { PrivateRoute } from "~/src/components/private-route";
import { ScrollContainer } from "./components/scroll-container";
import { saveUserData } from "~/src/state/user/user-data-slice";
import { IUserProfiles } from "~/src/types/user";
import { get as getSession } from "~/src/system/session";
import { get as lsGet } from "~/src/system/ls";
import { useDispatch } from "react-redux";
import { CompanyProfile } from "./pages/company-profile";
import { CompanyHomepage } from "./pages/company-homepage";
import { useGetCustomerCompaniesQuery } from "./state/companies/companies-api-slice";
import {
  saveCustomerCompaniesData,
  setActiveCompany,
} from "./state/companies/companies-data-slice";
import { AccountTagsBlock } from "./pages/account-tags-block";
import { UncategorizedTags } from "./pages/account-tags-uncategorized";
import { useAccountTagMappingQuery } from "./state/accounts/accounts-api-slice";
import { saveAccountTagMapping } from "./state/accounts/accounts-data-slice";
import { ActionItems } from "./pages/action-items";
import { useRuleVaultQuery } from "~/src/state/rules/rules-api-slice";
import { saveRules } from "~/src/state/rules/rules-data-slice";
import { useMerchantsQuery } from "./state/transactions/transactions-api-slice";
import { saveMerchants } from "./state/transactions/transactions-data-slice";
import { UncategorizedTransactions } from "./pages/categorization/components/uncategorized-transactions";
import { Institutions } from "./pages/institutions";
import { useRestoreStateAfterOauth } from "./pages/institutions/hooks/use-restore-state-after-oauth";
import { useInstitutions } from "./pages/institutions/hooks/queries/use-institutions";

// Simple entry component that wraps the application in the Router component -
// this is so we can call useHistory and useLocation from the App component
export const Entry = () => {
  return (
    <Router>
      <App />
      <ToastContainer hideProgressBar position="top-center" />
    </Router>
  );
};

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const sidebarCollapsed = useAppSelector((state) => state.sidebar.collapsed);

  const userData = useAppSelector((state) => state.userData.profiles);
  const userDataLoaded = !!userData.flowUser.user.id;
  const companyData = useAppSelector(
    (state) => state.companiesData.customerCompanies
  );

  const lastActiveCompanyId = lsGet("lastActiveCompanyId") ?? "";

  const getLayout = () => {
    if (location.pathname.includes(ROUTES.login.path)) {
      return "flowfi-login";
    } else if (location.pathname.includes(ROUTES.portfolio.path)) {
      return "flowfi-bare";
    } else {
      return sidebarCollapsed ? "flowfi-condensed" : "flowfi-base";
    }
  };

  // restore hash routing and redirect to /institutions if query params have "code" and "state"
  useRestoreStateAfterOauth();

  // If user data is loaded, fetch company data from the api
  const customerCompaniesQuery = useGetCustomerCompaniesQuery(
    userData.flowUser.user.id,
    { skip: !userDataLoaded }
  );

  // Fetch account tags data
  const accountTagMappingQuery = useAccountTagMappingQuery(
    companyData.active?.id || "",
    { skip: !companyData.active?.id }
  );

  // If company data is loaded, fetch action items for currently active company
  const activeCompanyLoaded = !!companyData.active?.id;

  // Fetch Rules Vault
  const ruleVaultQuery = useRuleVaultQuery(companyData.active?.id || "", {
    skip: !companyData.active?.id,
  });

  // Fetch merchants
  const merchantsQuery = useMerchantsQuery(companyData.active?.id || "", {
    skip: !companyData.active?.id,
  });

  // fetch institutions with connections
  useInstitutions();

  // decrypt the user data and persist to the store
  useEffect(() => {
    const session = getSession();
    const userProfiles: IUserProfiles = {
      flowUser: session.flowUser,
      googleUser: session.googleUser,
    };
    dispatch(saveUserData(userProfiles));
  }, [dispatch]);

  useEffect(() => {
    if (customerCompaniesQuery.isSuccess) {
      dispatch(saveCustomerCompaniesData(customerCompaniesQuery.data));

      // if the user had previously selected a company, check ls for its ID so we can set it in the store
      if (lastActiveCompanyId) {
        const lastActiveCompany = customerCompaniesQuery.data.all.find(
          (company) => company.id === lastActiveCompanyId
        );
        if (lastActiveCompany) dispatch(setActiveCompany(lastActiveCompany));
      }
    } else if (customerCompaniesQuery.isError) {
      // TODO correctly inform user about error
      console.error("customerCompaniesQuery ERROR");
    }
  }, [
    dispatch,
    lastActiveCompanyId,
    customerCompaniesQuery.data,
    customerCompaniesQuery.isSuccess,
    customerCompaniesQuery.isError,
  ]);

  useEffect(() => {
    if (accountTagMappingQuery.isSuccess && accountTagMappingQuery.data.data) {
      dispatch(saveAccountTagMapping(accountTagMappingQuery.data.data));
    } else if (
      accountTagMappingQuery.isError ||
      !!accountTagMappingQuery.data?.errors
    ) {
      // TODO correctly inform user about error
      console.error("accountTagMappingQuery ERROR");
    }
  }, [
    dispatch,
    accountTagMappingQuery.isSuccess,
    accountTagMappingQuery.isError,
    accountTagMappingQuery.data,
  ]);

  useEffect(() => {
    if (ruleVaultQuery.isSuccess && ruleVaultQuery.data.data) {
      dispatch(saveRules(ruleVaultQuery.data.data));
    } else if (ruleVaultQuery.isError) {
      console.error("ruleVaultQuery ERROR");
    }
  }, [dispatch, ruleVaultQuery]);

  useEffect(() => {
    if (merchantsQuery.isSuccess && merchantsQuery.data.data) {
      dispatch(saveMerchants(merchantsQuery.data.data));
    } else if (merchantsQuery.isError || !!merchantsQuery.data?.errors) {
      console.error("merchantsQuery ERROR");
    }
  }, [
    dispatch,
    merchantsQuery.isSuccess,
    merchantsQuery.data,
    merchantsQuery.isError,
  ]);

  return (
    <LayoutContainer mode="grid" layout={getLayout()} fullHeight>
      <Switch>
        <Route path={`${ROUTES.login.path}/:referrer?`}>
          <Login />
        </Route>
        <PrivateRoute path={ROUTES.root.path}>
          {/* If the user doesn't have an active company selected, we send them to the portfolio to choose one. */}
          {!companyData.active?.id && !lastActiveCompanyId && (
            <Redirect to={ROUTES.portfolio.path} />
          )}
          <Sidebar
            hidden={location.pathname.includes(ROUTES.portfolio.path)}
            activeCompanyId={companyData?.active?.id}
          />
          <div className="app-view-container">
            {!userDataLoaded ? (
              <div>Loading...</div>
            ) : (
              <>
                <TopBar
                  user={userData.flowUser.user}
                  customerCompanies={companyData}
                  hidden={location.pathname.includes(ROUTES.portfolio.path)}
                />
                <Switch>
                  <Route path={ROUTES.portfolio.path}>
                    <Portfolio
                      user={userData.flowUser.user}
                      customerCompanies={companyData}
                    />
                  </Route>
                  <Route
                    path={ROUTES.categorization.subroutes.suggestedRules.path}
                  >
                    <RulesSuggestions />
                  </Route>
                  <Route
                    path={
                      ROUTES.categorization.subroutes.uncategorizedTransactions
                        .path
                    }
                  >
                    <UncategorizedTransactions />
                  </Route>
                  <Route>
                    <ScrollContainer viewContainer fullHeight>
                      <Switch>
                        <Route exact path={ROUTES.root.path}>
                          {activeCompanyLoaded ? (
                            <Redirect
                              to={`${ROUTES.companies.path}/${companyData.active?.id}`}
                            />
                          ) : (
                            <Redirect to={ROUTES.portfolio.path} />
                          )}
                        </Route>
                        <Route path={ROUTES.categorization.path}>
                          <Categorization />
                        </Route>
                        <Route path={ROUTES.actionItems.path}>
                          <ActionItems />
                        </Route>
                        {/* <Route exact path={ROUTES.supportingSchedules.path}>
                          <SupportingSchedules />
                        </Route> */}
                        {/* 
                          NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
                          References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
                        */}
                        <Route path={ROUTES.institutions.path}>
                          <Institutions />
                        </Route>
                        {/* <Route exact path={ROUTES.supportingSchedules.path}>
                          <SupportingSchedules />
                        </Route> */}
                        {/* 
                          NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
                          References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
                        */}
                        <Route exact path={ROUTES.accountTags.path}>
                          <AccountTags />
                        </Route>
                        <Route
                          path={
                            ROUTES.accountTags.subroutes.uncategorizedTags.path
                          }
                        >
                          <UncategorizedTags />
                        </Route>
                        <Route
                          path={ROUTES.accountTags.subroutes.accountBlock.path}
                        >
                          <AccountTagsBlock />
                        </Route>
                        <Route exact path={ROUTES.rulesVault.path}>
                          <RulesVault />
                        </Route>
                        <Route path={ROUTES.errorPage.path}>
                          <ErrorPage />
                        </Route>
                        <Route
                          path={
                            ROUTES.companies.subroutes.company.subroutes.profile
                              .path
                          }
                        >
                          <CompanyProfile />
                        </Route>
                        <Route path={ROUTES.companies.subroutes.company.path}>
                          <CompanyHomepage />
                        </Route>
                      </Switch>
                    </ScrollContainer>
                  </Route>
                </Switch>
              </>
            )}
          </div>
        </PrivateRoute>
      </Switch>
    </LayoutContainer>
  );
};
