import React, { useState } from "react";
import { ConnectionHealthCell } from "../connection-health-cell";
import { ConnectionEditCell } from "../connection-edit-cell";
import "./connections-table.css";
import {
  isConnectionWithRedactedApiKey,
  IConnection,
  isConnectionOfType,
  IPlaidConnection,
  InstitutionId,
} from "~/src/types/institutions";
import { Icons } from "~/src/assets/icons";
import { SVGHandler } from "~/src/components/svg-handler";
import classNames from "classnames";

export function formatRedactedApiKey(redactedApiKey: string): string {
  return "****..." + redactedApiKey;
}

interface IConnectionsTableRow {
  connection: IConnection;
  isLast: boolean;
}

export const ConnectionsTableRow = ({
  connection,
  isLast,
}: IConnectionsTableRow): JSX.Element => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <tr className="connections-table__connection-row">
        <td className="connections-table__title-column">
          <div
            className={classNames("connections-table__title", {
              expandable: isConnectionOfType<IPlaidConnection>(
                connection,
                InstitutionId.PLAID
              ),
            })}
            onClick={() => setExpanded(!expanded)}
          >
            {isConnectionOfType<IPlaidConnection>(
              connection,
              InstitutionId.PLAID
            ) && (
              <SVGHandler
                className={classNames("connections-table__caret", { expanded })}
                image={Icons.default.ArrowRightLg}
                width={22}
                height={22}
              />
            )}
            <span>{connection.title}</span>
          </div>
        </td>
        <td className="connections-table__api-key-column">
          {isConnectionWithRedactedApiKey(connection) &&
            formatRedactedApiKey(
              connection.perInstitutionValues.redactedApiKey
            )}
        </td>
        <td className="connections-table__status-column">
          <ConnectionHealthCell
            healthy={connection.healthy}
            healthLastChecked={connection.healthLastChecked}
          />
        </td>
        <td className="connections-table__edit-column">
          <ConnectionEditCell connectionId={connection.id} />
        </td>
      </tr>
      {isConnectionOfType<IPlaidConnection>(
        connection,
        InstitutionId.PLAID
      ) && (
        <>
          {connection.perInstitutionValues.accountNames.map((accountName) => (
            <tr key={connection.id + accountName}>
              <td
                className="connections-table__account-name-column"
                colSpan={5}
              >
                <div
                  className={classNames("connections-table__account-name", {
                    expanded,
                  })}
                >
                  {accountName}
                </div>
              </td>
            </tr>
          ))}
          {isLast && (
            <tr>
              <td colSpan={5}>
                <div
                  className={classNames(
                    "connections-table__after-account-names",
                    {
                      expanded,
                    }
                  )}
                />
              </td>
            </tr>
          )}
        </>
      )}
    </>
  );
};
