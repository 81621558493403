import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

import { Icons } from "~/src/assets/icons";
import { Toast } from "~/src/components/toast";
import { categorizeLineItems } from "~/src/state/transactions/transactions-data-slice";
import { useUpsertAndApplyRuleMutation } from "~/src/state/rules/rules-api-slice";
import {
  IRuleUpsertInput,
  IUpsertAndApplyRuleInput,
} from "~/src/state/rules/rules-api-types";
import { useAppSelector } from "~/src/system/store/hooks";
import { formatRequestDate } from "~/src/utilities/dates";

export const useUpsertAndApplyRule = () => {
  const dispatch = useDispatch();

  // store
  const companyId = useAppSelector(
    (state) => state.companiesData.customerCompanies.active?.id || ""
  );
  const lastCategorizeWorkflowDates = useAppSelector(
    (state) => state.transactions.categorizationWorkflowDateRange
  );

  // run upsertAndApplyRule with inputted rule and last used categorizationWorkflow input
  const [upsertAndApplyRule, upsertAndApplyRuleMutation] =
    useUpsertAndApplyRuleMutation();
  const handleUpsertAndApplyRule = async (
    ruleUpsertInput: IRuleUpsertInput
  ) => {
    const upsertAndApplyRuleInput: IUpsertAndApplyRuleInput = {
      rule: ruleUpsertInput,
      workflow: {
        companyId,
        start: formatRequestDate(moment.utc(lastCategorizeWorkflowDates.start)),
        end: formatRequestDate(moment.utc(lastCategorizeWorkflowDates.end)),
      },
    };
    return await upsertAndApplyRule(upsertAndApplyRuleInput).unwrap();
  };

  // save mutation results to the store; manage toasts
  useEffect(() => {
    if (upsertAndApplyRuleMutation.isUninitialized) return;
    if (upsertAndApplyRuleMutation.isLoading) {
      toast(
        <Toast message="Applying rule..." icon={Icons.green.LoadingAnimated} />,
        { toastId: "upsertAndApplyRuleLoading", autoClose: false }
      );
    } else {
      toast.dismiss("upsertAndApplyRuleLoading");
    }

    if (
      upsertAndApplyRuleMutation.isSuccess &&
      !!upsertAndApplyRuleMutation.data.data
    ) {
      toast(<Toast message="Rule applied!" icon={Icons.green.Info} />, {
        toastId: "upsertAndApplyRuleSuccess",
      });
      dispatch(
        categorizeLineItems(
          upsertAndApplyRuleMutation.data.data.categorizedTransactionLineItems
        )
      );
    }
    if (
      upsertAndApplyRuleMutation.isError ||
      !!upsertAndApplyRuleMutation.data?.errors
    ) {
      toast(
        <Toast
          message="There was a problem applying the rule. Please try again."
          icon={Icons.green.Info}
        />,
        { toastId: "upsertAndApplyRuleError" }
      );
    }
  }, [upsertAndApplyRuleMutation, dispatch]);

  return {
    upsertAndApplyRule: handleUpsertAndApplyRule,
    upsertAndApplyRuleStatus: upsertAndApplyRuleMutation,
  };
};
