import React from "react";
import Classnames from "classnames";
import { Icons } from "~/src/assets/icons";

import "./tag.css";
import { ITag, TTagScope } from "~/src/types/accounts";

interface ITagProps {
  tag: ITag;
  scope: TTagScope;
  edit?: boolean;
  onRemove?: (tagId: string) => void;
  selected?: boolean;
}

/* 
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
*/

export const Tag: React.FunctionComponent<ITagProps> = ({
  edit,
  tag,
  scope,
  onRemove,
  selected,
}) => {
  const { id, name } = tag;
  const handleRemove = () => {
    !!onRemove && onRemove(id);
  };

  return (
    <div
      className={Classnames("tag", {
        "tag--global": scope === "GLOBAL",
        "tag--local": scope === "LOCAL",
        "tag--selected": selected,
      })}
    >
      <span>{name}</span>
      {edit && (
        <button onClick={handleRemove}>
          <img className="h-4" src={Icons.default.Remove} />
        </button>
      )}
    </div>
  );
};
