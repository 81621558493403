import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import {
  IInitiateConnectionInput,
  IInitiateConnectionOutput,
  ICreateConnectionInput,
  ICreateConnectionOutput,
  IDeleteConnectionInput,
  IDeleteConnectionOutput,
  IInstitutionsOutput,
  ICheckConnectionHealthInput,
  ICheckConnectionHealthOutput,
  IUpdateConnectionInput,
  IUpdateConnectionOutput,
} from "./institutions-api-types";
import { transformAppSyncApiResponse as transformResponse } from "~/src/system/resource";
import {
  connectionSchema,
  initiateConnectionSchema,
  deleteConnectionSchema,
  institutionsSchema,
  checkConnectionHealthSchema,
} from "./institutions-api-schema";

export const institutionsApi = createApi({
  reducerPath: "institutionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.GALACTUS_API,
    prepareHeaders(headers) {
      headers.set("content-type", "application/json");
      headers.set("x-api-key", process.env.GALACTUS_API_KEY || "");
      return headers;
    },
  }),
  tagTypes: ["institutions"],
  endpoints: (build) => {
    return {
      // Get all institutions with connections for a company
      institutions: build.query<IInstitutionsOutput, string>({
        query: (companyId: string) => {
          const query = `
            query institutions($companyId: ID!) {
              institutions(companyId: $companyId) { ${institutionsSchema} }
            }
          `;
          const variables = {
            companyId,
          };
          return {
            url: `/`,
            method: "POST",
            body: { query, variables },
          };
        },
        providesTags: ["institutions"],
        transformResponse,
      }),
      // Get the url or token to use when connection to an institution
      initiateConnection: build.query<
        IInitiateConnectionOutput,
        IInitiateConnectionInput
      >({
        query: (input: IInitiateConnectionInput) => {
          const query = `
            query initiateConnection($input: InitiateConnectionInput!) {
              initiateConnection(input: $input) { ${initiateConnectionSchema} }
            }
          `;
          const variables = {
            input,
          };
          return {
            url: `/`,
            method: "POST",
            body: { query, variables },
          };
        },
        transformResponse,
      }),
      // Create a new connection
      createConnection: build.mutation<
        ICreateConnectionOutput,
        ICreateConnectionInput
      >({
        query: (input: ICreateConnectionInput) => {
          const query = `
            mutation createConnection($input: CreateConnectionInput!) {
              createConnection(input: $input) { ${connectionSchema} }
            }
          `;
          const variables = {
            input,
          };
          return {
            url: `/`,
            method: "POST",
            body: { query, variables },
          };
        },
        transformResponse,
      }),
      // Delete a connection
      deleteConnection: build.mutation<
        IDeleteConnectionOutput,
        IDeleteConnectionInput
      >({
        query: (input: IDeleteConnectionInput) => {
          const query = `
            mutation deleteConnection($input: DeleteConnectionInput!) {
              deleteConnection(input: $input) { ${deleteConnectionSchema} }
            }
          `;
          const variables = {
            input,
          };
          return {
            url: `/`,
            method: "POST",
            body: { query, variables },
          };
        },
        transformResponse,
      }),
      // check the health of a connection
      checkConnectionHealth: build.mutation<
        ICheckConnectionHealthOutput,
        ICheckConnectionHealthInput
      >({
        query: (input: ICheckConnectionHealthInput) => {
          const query = `
            mutation checkConnectionHealth($companyId: ID!, $institutionId: InstitutionId!, $connectionId: ID!) {
              checkConnectionHealth(companyId: $companyId, institutionId: $institutionId, connectionId: $connectionId) { ${checkConnectionHealthSchema} }
            }
          `;
          const variables = { ...input };
          return {
            url: `/`,
            method: "POST",
            body: { query, variables },
          };
        },
        transformResponse,
      }),
      // Update a connection
      updateConnection: build.mutation<
        IUpdateConnectionOutput,
        IUpdateConnectionInput
      >({
        query: (input: IUpdateConnectionInput) => {
          const query = `
            mutation updateConnection($input: UpdateConnectionInput!) {
              updateConnection(input: $input) { ${connectionSchema} }
            }
          `;
          const variables = { input };
          return {
            url: `/`,
            method: "POST",
            body: { query, variables },
          };
        },
        transformResponse,
      }),
    };
  },
});

export const {
  useInstitutionsQuery,
  useLazyInitiateConnectionQuery,
  useCreateConnectionMutation,
  useDeleteConnectionMutation,
  useCheckConnectionHealthMutation,
  useUpdateConnectionMutation,
} = institutionsApi;
