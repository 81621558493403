import React from "react";
import { Icons } from "~/src/assets/icons";
import { HelpTip } from "~/src/components/help-tip";
import { PageHeader } from "~/src/components/page-header";
import { useAppSelector } from "~/src/system/store/hooks";
import {
  IKlaviyoInstitution,
  InstitutionId,
  IPlaidInstitution,
  IQuickbooksInstitution,
  IShopifyInstitution,
  isInstitutionOfType,
} from "~/src/types/institutions";
import { KlaviyoInstitution } from "./components/institution/klaviyo-institution";
import { PlaidInstitution } from "./components/institution/plaid-institution";
import { QuickbooksInstitution } from "./components/institution/quickbooks-institution";
import { ShopifyInstitution } from "./components/institution/shopify-institution";
import { useCompleteOAuthConnection } from "./hooks/use-complete-oauth-connection";
import "./institutions.css";

export const Institutions: React.FunctionComponent = () => {
  const institutionsData = useAppSelector(
    (state) => state.institutions.institutions
  );

  useCompleteOAuthConnection();

  return (
    <>
      <PageHeader
        icon={Icons.default.Bank}
        label={"Institutions"}
        className="institutions__header"
      >
        <HelpTip linkTo="https://www.notion.so/flowfinance/Institutions-40b319d096d84ed7b49c229ecdaa1716" />
      </PageHeader>
      {institutionsData.map((institution) => {
        if (
          isInstitutionOfType<IKlaviyoInstitution>(
            institution,
            InstitutionId.KLAVIYO
          )
        ) {
          return (
            <KlaviyoInstitution
              key={institution.id}
              institution={institution}
            />
          );
        }
        if (
          isInstitutionOfType<IPlaidInstitution>(
            institution,
            InstitutionId.PLAID
          )
        ) {
          return (
            <PlaidInstitution key={institution.id} institution={institution} />
          );
        }
        if (
          isInstitutionOfType<IQuickbooksInstitution>(
            institution,
            InstitutionId.QUICKBOOKS
          )
        ) {
          return (
            <QuickbooksInstitution
              key={institution.id}
              institution={institution}
            />
          );
        }
        if (
          isInstitutionOfType<IShopifyInstitution>(
            institution,
            InstitutionId.SHOPIFY
          )
        ) {
          return (
            <ShopifyInstitution
              key={institution.id}
              institution={institution}
            />
          );
        }
        return null;
      })}
    </>
  );
};
