import React, { useState } from "react";
import { IShopifyInstitution } from "~/src/types/institutions";
import { Institution } from "..";
import { useInitiateShopifyConnection } from "../../../hooks/initiate-connection/use-initiate-shopify-connection";
import { ConnectionsTable } from "../../connections-table";
import { InitiateShopifyConnectionForm } from "./initiate-shopify-connection-form";

interface IShopifyInstitutionProps {
  institution: IShopifyInstitution;
}

export const ShopifyInstitution = ({
  institution,
}: IShopifyInstitutionProps): JSX.Element => {
  const [shopName, setShopName] = useState<string>();
  const [inputModalOpen, setInputModalOpen] = useState(false);
  const { initiateConnection, addConnectionDisabled } =
    useInitiateShopifyConnection(setInputModalOpen, shopName, setShopName);

  return (
    <>
      <Institution
        key={institution.id}
        institution={institution}
        addConnectionButtonDisabled={addConnectionDisabled}
        onAddConnection={initiateConnection}
      >
        {!!institution.connections.length && (
          <ConnectionsTable connections={institution.connections} />
        )}
      </Institution>
      <InitiateShopifyConnectionForm
        {...{ inputModalOpen, setInputModalOpen, setShopName }}
      />
    </>
  );
};
