import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Toast } from "~/src/components/toast";
import { useActiveCompanyId } from "~/src/hooks/useActiveCompanyId";
import { useInstitutionsQuery } from "~/src/state/institutions/institutions-api-slice";
import { saveInstitutions } from "~/src/state/institutions/institutions-data-slice";
import { Icons } from "~/src/assets/icons";
import { useLocation } from "react-router";
import ROUTES from "~/src/constants/routes";

export const useInstitutions = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const activeCompanyId = useActiveCompanyId();
  const institutionsQuery = useInstitutionsQuery(activeCompanyId, {
    skip: !activeCompanyId,
  });

  const { isLoading, isFetching, isError, isSuccess, data } = institutionsQuery;

  // when the query is in flight and the user is on the institutions page, show loading toast, otherwise dismiss it
  useEffect(() => {
    if (
      (isLoading || isFetching) &&
      location.pathname === ROUTES.institutions.path
    ) {
      toast(
        <Toast
          message="Loading institutions..."
          icon={Icons.green.LoadingAnimated}
        />,
        { toastId: "institutions", autoClose: false }
      );
    } else toast.dismiss("institutions");
  }, [isLoading, isFetching, location.pathname]);

  // if the query is successful and returns data, update store
  useEffect(() => {
    if (isSuccess && !!data.data) {
      dispatch(saveInstitutions(data.data));
    }
  }, [isSuccess, data]);

  // if the query errors or returns an error, log error
  useEffect(() => {
    if (isError || !!data?.errors) {
      console.error("institutionsQuery ERROR");
    }
  }, [isError, data]);
};
