import { EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { BackButton } from "~/src/components/back-button";
import { Button } from "~/src/components/button";
import { FlowFiDatePicker } from "~/src/components/datepicker";
import { Loading } from "~/src/components/loading";
import { Pane } from "~/src/components/pane";
import {
  htmlToRichText,
  RichText,
  richTextToHtml,
} from "~/src/components/rich-text";
import { SimpleSelect } from "~/src/components/simple-select";
import { Toast } from "~/src/components/toast";
import ROUTES from "~/src/constants/routes";
import {
  useDeleteActionItemMutation,
  useUpdateActionItemContentsMutation,
} from "~/src/state/action-items/action-items-api-slice";
import { useAppSelector } from "~/src/system/store/hooks";
import {
  IActionItem,
  TActionItemCategory,
  TActionItemState,
} from "~/src/types/action-items";
import { ActionItemResponse } from "./action-item-response";

interface IActionItemDetailViewProps {
  item?: IActionItem;
  updateStateHandler: (id: string, newState: TActionItemState) => void;
}

export const ActionItemDetailView = ({
  item,
  updateStateHandler,
}: IActionItemDetailViewProps) => {
  const history = useHistory();
  const [editActionItem, editActionItemResult] =
    useUpdateActionItemContentsMutation();
  const [deleteActionItem, deleteActionItemResult] =
    useDeleteActionItemMutation();

  const [editActive, setEditActive] = useState(false);

  const jiraEpics = useAppSelector((state) => state.jiraEpics.jiraEpics);

  const [category, setCategory] = useState(item?.category || "");
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createWithContent(htmlToRichText(item?.question ?? ""))
  );

  const [richTextString, setRichTextString] = useState(item?.question);
  const getDefaultDueDate = () =>
    item?.due_date ? new Date(parseInt(item.due_date)) : undefined;
  const [dueDate, setDueDate] = useState(getDefaultDueDate());

  const [epic, setEpic] = useState<string>(item?.jira_id || "");

  const onEditorStateChange = (editorContent: EditorState) => {
    setEditorState(editorContent);
    const stringValue = richTextToHtml(editorState);
    setRichTextString(stringValue);
  };

  const resetForm = () => {
    setEditActive(false);
    setRichTextString(item?.question);
    setCategory(item?.category || "");
    setDueDate(getDefaultDueDate());
  };

  const initForm = () => {
    setEditActive(true);
    setDueDate(getDefaultDueDate());
  };

  useEffect(() => {
    if (editActionItemResult.isLoading || deleteActionItemResult.isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [editActionItemResult.isLoading, deleteActionItemResult.isLoading]);

  useEffect(() => {
    if (editActionItemResult.isSuccess) {
      toast(<Toast message={"Action item updated successfully"} />);
    } else if (editActionItemResult.isError) {
      console.error("ERROR: edit action item.");
    } else if (deleteActionItemResult.isSuccess) {
      toast(<Toast message={"Action item deleted successfully"} />);
      history.push(ROUTES.actionItems.path);
    } else if (deleteActionItemResult.isError) {
      console.error("ERROR: edit action item.");
    }
  }, [editActionItemResult, deleteActionItemResult, history]);

  const [validated, setValidated] = useState(false);

  // This is very basic
  useEffect(() => {
    const validation = [
      editorState.getCurrentContent().hasText(),
      jiraEpics.length ? !!epic : true, // we let this pass if there are no jira epics, otherwise check state
      !!category,
    ];

    setValidated(validation.every((item) => item === true));
  }, [category, editorState, jiraEpics, epic]);

  return (
    <>
      <BackButton onClick={() => history.push(ROUTES.actionItems.path)}>
        Action Item Details
      </BackButton>
      <div className="action-item-detail">
        {!!item && (
          <>
            <div>
              <Pane className="p-8">
                <label htmlFor="description">Description</label>
                <div className="rich-text-output" id="description">
                  {!editActive ? (
                    ReactHtmlParser(item?.question || "No description.")
                  ) : (
                    <RichText
                      id="description"
                      placeholder="Start typing..."
                      editorState={editorState}
                      editorStateChange={onEditorStateChange}
                    />
                  )}
                </div>
                {(!!item?.amortization_responses?.length ||
                  !!item?.miscellaneous_responses?.length ||
                  !!item?.categorization_responses?.length) && (
                  <div>
                    <p className="mt-4 mb-2">Requested Info:</p>
                    {<ActionItemResponse item={item} />}
                  </div>
                )}
              </Pane>
            </div>
            <Pane>
              <div className="action-item-detail__controls">
                <div className="action-item-detail__controls-row">
                  <label htmlFor="due-date">Due Date:</label>
                  <FlowFiDatePicker
                    disabled={!editActive}
                    id="due-date"
                    onChange={(date) => {
                      setDueDate(date);
                    }}
                    placeholder="One week after published"
                    selected={dueDate}
                  />
                </div>
                <div className="action-item-detail__controls-row">
                  <label htmlFor="category">Category:</label>
                  <SimpleSelect
                    className="w-full"
                    options={[
                      { value: "miscellaneous", label: "Miscellaneous" },
                      { value: "amortization", label: "Service Period" },
                      { value: "categorization", label: "Categorization" },
                    ]}
                    disabled={!editActive}
                    onChange={(value) => {
                      setCategory(value as TActionItemCategory);
                    }}
                    name="category"
                    id="category-select"
                    value={editActive ? category : item?.category || ""}
                    icon="label"
                  />
                </div>
                {jiraEpics.length ? (
                  <div className="action-item-detail__controls-row">
                    <label htmlFor="epic-select">Jira Epic:</label>
                    <SimpleSelect
                      className="w-full"
                      options={jiraEpics.map((epic) => {
                        return { value: epic.id, label: epic.summary };
                      })}
                      disabled={!editActive}
                      onChange={(value) => setEpic(value)}
                      name="jira"
                      value={epic}
                      id="jira"
                      placeholder="-- Select One --"
                    />
                  </div>
                ) : null}
              </div>
              {editActive && item.state === "draft" ? (
                <>
                  <div className="action-item-detail__publish-controls">
                    <Button
                      size={"large"}
                      theme={"primary"}
                      label="Save Changes"
                      onClick={async () => {
                        await editActionItem({
                          action_item_id: item.id,
                          question: richTextString as string,
                          due_date: dueDate?.getTime().toString(),
                          category: category as TActionItemCategory,
                          jira_id: epic,
                        });
                        setEditActive(false);
                      }}
                    />
                  </div>
                </>
              ) : null}
              {item.state !== "closed" && !editActive ? (
                <>
                  <div className="action-item-detail__publish-controls">
                    <Button
                      size="large"
                      theme="primary"
                      disabled={!validated}
                      label={
                        item.state === "draft"
                          ? "Publish"
                          : item.state === "awaiting_reply"
                          ? "Unpublish"
                          : item.state === "ready_for_action"
                          ? "Close"
                          : ""
                      }
                      onClick={async () => {
                        const newState: TActionItemState | null =
                          item.state === "draft"
                            ? "awaiting_reply"
                            : item.state === "awaiting_reply"
                            ? "draft"
                            : item.state === "ready_for_action"
                            ? "closed"
                            : null;
                        if (!newState) {
                          console.error("Invalid action item state.");
                          return;
                        }
                        try {
                          await updateStateHandler(item.id, newState);
                          history.push(ROUTES.actionItems.path);
                        } catch (e) {
                          console.error(e);
                        }
                      }}
                    />
                  </div>
                </>
              ) : null}
              {item.state === "draft" ? (
                <>
                  <div className="action-item-detail__publish-controls">
                    <Button
                      size="large"
                      theme="secondary"
                      label={editActive ? "Cancel Changes" : "Edit"}
                      onClick={() => {
                        if (editActive) {
                          resetForm();
                        } else {
                          initForm();
                        }
                      }}
                    />
                  </div>
                  <div className="action-item-detail__publish-controls action-item-detail__delete-button">
                    <Button
                      size={"large"}
                      theme={"danger"}
                      label="Delete"
                      onClick={async () => {
                        await deleteActionItem({
                          action_item_id: item.id,
                        });
                      }}
                    />
                  </div>
                </>
              ) : null}
            </Pane>
            {loading && <Loading />}
          </>
        )}
      </div>
    </>
  );
};
