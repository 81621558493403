import React, { useEffect } from "react";
import { useCheckConnectionHealthMutation } from "~/src/state/institutions/institutions-api-slice";
import { toast } from "react-toastify";
import { Toast } from "~/src/components/toast";
import { Icons } from "~/src/assets/icons";
import { updateConnectionHealth } from "~/src/state/institutions/institutions-data-slice";
import { useDispatch } from "react-redux";

export const useCheckConnectionHealth = () => {
  const dispatch = useDispatch();
  const [checkConnectionHealthMutation, checkConnectionHealthStatus] =
    useCheckConnectionHealthMutation();

  const { isLoading, isError, isSuccess, data } = checkConnectionHealthStatus;

  // when the query is in flight, show loading toast, otherwise dismiss it
  useEffect(() => {
    if (isLoading) {
      toast(
        <Toast
          message="Testing connection..."
          icon={Icons.green.LoadingAnimated}
        />,
        { toastId: "testConnection", autoClose: false }
      );
    } else toast.dismiss("testConnection");
  }, [isLoading]);

  // if the query is successful and returns data, show success toast and update store
  useEffect(() => {
    if (isSuccess && !!data.data) {
      toast(
        <Toast
          message="Connection was successfully tested!"
          icon={Icons.green.Info}
        />
      );
      dispatch(updateConnectionHealth(data.data));
    }
  }, [isSuccess, data, dispatch]);

  // if the query errors or returns an error, show error toast
  useEffect(() => {
    if (isError || !!data?.errors) {
      toast(
        <Toast
          message="There was a problem testing the connection. Please try again."
          icon={Icons.green.Info}
        />
      );
    }
  }, [isError, data, dispatch]);

  return { checkConnectionHealthMutation, isLoading };
};
