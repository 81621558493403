import React, { useEffect, useState } from "react";
import Classnames from "classnames";
import { toast } from "react-toastify";

import { Button } from "~/src/components/button";
import { Dropdown } from "~/src/components/dropdown";
import { useAppSelector } from "~/src/system/store/hooks";
import { ITag } from "~/src/types/accounts";
import {
  deleteAccountTagsEdit,
  ITagsEdit,
} from "~/src/state/accounts/accounts-data-slice";
import { useDispatch } from "react-redux";
import { Toast } from "~/src/components/toast";
import { Icons } from "~/src/assets/icons";

interface ITagsAutocompleteProps {
  inputValue: string;
  inputKeyEvent: React.KeyboardEvent<HTMLInputElement> | undefined;
  externalAccountId: string;
  formTags: ITag[];
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  addNewTag: (tagName: string, tagsEdit: ITagsEdit) => void;
  addExistingTag: (tag: ITag) => void;
  removeTag: (tagId: string) => void;
  lastTagSelected: boolean;
  setLastTagSelected: React.Dispatch<React.SetStateAction<boolean>>;
}

/* 
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
*/

export const TagsAutocomplete: React.FunctionComponent<ITagsAutocompleteProps> = ({
  inputValue,
  inputKeyEvent,
  externalAccountId,
  formTags,
  setInputValue,
  addNewTag,
  addExistingTag,
  removeTag,
  lastTagSelected,
  setLastTagSelected,
}) => {
  const dispatch = useDispatch();

  // state
  const unmappedTags = useAppSelector(
    (state) => state.accounts.accountTagMapping.unmappedTags
  );
  const tagsEdit = useAppSelector(
    (state) => state.accounts.accountTagsEdits[externalAccountId]
  );
  const [suggestedTags, setSuggestedTags] = useState<ITag[]>([]);
  const [selectedSuggestionIdx, setSelectedSuggestionIdx] = useState<number>(0);
  const allTags = useAppSelector((state) => state.accounts.allTags);

  // tag edit updates
  const handleAddExistingTag = (tag: ITag) => {
    addExistingTag(tag);
    resetInput();
  };
  const resetInput = () => {
    setInputValue("");
  };
  const handleAddNewTag = () => {
    if (newTagAlreadyExists()) return;
    addNewTag(inputValue, tagsEdit);
    resetInput();
  };
  const newTagAlreadyExists = (): boolean => {
    if (
      !!formTags.find((tag) => tag.name === inputValue) ||
      !!allTags.mappedTags
        .concat(allTags.unmappedTags)
        .find((tag) => tag.name === inputValue)
    ) {
      toast(
        <Toast message="This tag already exists." icon={Icons.green.Info} />
      );
      return true;
    }
    return false;
  };

  // get unmapped tags for autocomplete suggestions
  useEffect(() => {
    setSuggestedTags(
      unmappedTags
        .filter((tag) => {
          const formTagIds = formTags.map((tag) => tag.id);
          return (
            tag.name.toLowerCase().includes(inputValue.toLowerCase()) &&
            !formTagIds.includes(tag.id)
          );
        })
        .sort((a, b) => {
          const tagA = a.name.toLowerCase();
          const tagB = b.name.toLowerCase();
          if (tagA > tagB) return 1;
          if (tagA < tagB) return -1;
          return 0;
        })
        .slice(0, 10)
    );
    setSelectedSuggestionIdx(0);
  }, [inputValue]);

  useEffect(() => {
    if (inputValue) setLastTagSelected(false);
  }, [inputValue]);

  // handle key input key events in autocomplete
  useEffect(() => {
    if (inputKeyEvent) {
      const addSelectedTag = () => {
        if (!inputValue) return;
        if (suggestedTags.length && suggestedTags[selectedSuggestionIdx]) {
          handleAddExistingTag(suggestedTags[selectedSuggestionIdx]);
        } else {
          handleAddNewTag();
        }
      };
      switch (inputKeyEvent.code) {
        case "Tab":
          addSelectedTag();
          break;
        case "Enter":
          // if there is no input value, form submit default is enabled
          // otherwise, form submit default is prevented and the selected tag is added
          if (inputValue) addSelectedTag();
          resetInput();
          break;
        case "Backspace":
          if (!inputValue && !lastTagSelected) {
            setLastTagSelected(!lastTagSelected);
          }
          if (!inputValue && lastTagSelected) {
            removeTag(formTags[formTags.length - 1]?.id || "");
            setLastTagSelected(!lastTagSelected);
          }
          break;
        case "Escape":
          resetInput();
          dispatch(deleteAccountTagsEdit(externalAccountId));
          break;
        case "ArrowDown":
          if (selectedSuggestionIdx === suggestedTags.length) break;
          else setSelectedSuggestionIdx(selectedSuggestionIdx + 1);
          break;
        case "ArrowUp":
          if (selectedSuggestionIdx === 0) break;
          else setSelectedSuggestionIdx(selectedSuggestionIdx - 1);
          break;
        default:
          break;
      }
    }
  }, [inputKeyEvent]);

  return (
    <Dropdown direction="center" forceOpen={!!inputValue} textOnly>
      {!!inputValue && (
        <div
          className="autocomplete"
          onMouseLeave={() => setSelectedSuggestionIdx(-1)}
        >
          {suggestedTags.map((tag, idx) => (
            <button
              key={tag.id}
              className={Classnames("autocomplete__row", {
                "autocomplete__row--selected": idx === selectedSuggestionIdx,
                "autocomplete__row--hover": selectedSuggestionIdx < 0,
              })}
              onClick={() => handleAddExistingTag(tag)}
              onMouseEnter={() => setSelectedSuggestionIdx(idx)}
            >
              {tag.name}
            </button>
          ))}
          <div
            className={Classnames("autocomplete__row", {
              "autocomplete__row--selected":
                selectedSuggestionIdx === suggestedTags.length,
            })}
            onMouseEnter={() => setSelectedSuggestionIdx(-1)}
          >
            <Button
              theme="primary"
              size="small"
              label="Add New"
              onClick={handleAddNewTag}
              fullWidth
            />
          </div>
        </div>
      )}
    </Dropdown>
  );
};
