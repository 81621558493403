import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { InstitutionId } from "~/src/types/institutions";
import { useCreateConnection } from "./mutations/use-create-connection";
import { useConnectionLocation } from "./utilities/use-connection-location";

interface IStateParams {
  path?: string;
  query?: string;
  institution_id?: string;
  company_id?: string;
}

export const useCompleteOAuthConnection = () => {
  const location = useLocation();
  const { redirectUrl } = useConnectionLocation();
  const history = useHistory();
  const { createConnection } = useCreateConnection();
  const queryParams = new URLSearchParams(location.search);

  const state: IStateParams = JSON.parse(queryParams.get("state") || "{}");
  const authorizationCode = queryParams.get("code");
  const companyId = state.company_id;
  const institutionId = state.institution_id as InstitutionId;
  const query = state.query;

  const realmId = queryParams.get("realmId") ?? undefined;
  const shopName = queryParams.get("shop") ?? undefined;

  useEffect(() => {
    if (
      !!companyId &&
      !!InstitutionId[institutionId] &&
      !!authorizationCode &&
      !!(realmId || shopName)
    ) {
      // restore any query params from before oauth
      history.replace({
        search: query,
      });

      createConnection({
        companyId,
        institutionId,
        quickbooksInput: realmId
          ? { authorizationCode, realmId, redirectUrl }
          : undefined,
        shopifyInput: shopName ? { authorizationCode, shopName } : undefined,
      });
    }
  }, [
    authorizationCode,
    companyId,
    createConnection,
    history,
    institutionId,
    query,
    realmId,
    shopName,
    redirectUrl,
  ]);
};
