import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

import { Icons } from "~/src/assets/icons";
import { Toast } from "~/src/components/toast";
import { useAppSelector } from "~/src/system/store/hooks";
import { formatRequestDate } from "~/src/utilities/dates";
import { saveCategorizationWorkflow } from "~/src/state/transactions/transactions-data-slice";
import { ICategorizationWorkflowInput } from "~/src/state/transactions/transactions-api-types";
import { useCategorizationWorkflowMutation } from "~/src/state/transactions/transactions-api-slice";

export const useApplyRules = () => {
  const dispatch = useDispatch();

  // store
  const companyId = useAppSelector(
    (state) => state.companiesData.customerCompanies.active?.id || ""
  );
  const lastCategorizeWorkflowDates = useAppSelector(
    (state) => state.transactions.categorizationWorkflowDateRange
  );

  // rerun categorizeWorkflow with last used dates, excluding rule suggestions
  const [categorizationWorkflow, categorizationWorkflowMutation] =
    useCategorizationWorkflowMutation();
  const reloadTransactions = async () => {
    const categorizeWorkflowInput: ICategorizationWorkflowInput = {
      companyId,
      start: formatRequestDate(moment.utc(lastCategorizeWorkflowDates.start)),
      end: formatRequestDate(moment.utc(lastCategorizeWorkflowDates.end)),
      excludeSuggestions: true,
    };
    await categorizationWorkflow(categorizeWorkflowInput);
  };

  // save mutation results to the store; manage toasts
  useEffect(() => {
    if (categorizationWorkflowMutation.isUninitialized) return;
    if (categorizationWorkflowMutation.isLoading) {
      toast(
        <Toast
          message="Applying rules..."
          icon={Icons.green.LoadingAnimated}
        />,
        { toastId: "useApplyRules", autoClose: false }
      );
    } else {
      toast.dismiss("useApplyRules");
    }

    if (
      categorizationWorkflowMutation.isSuccess &&
      !!categorizationWorkflowMutation.data.data
    ) {
      toast(<Toast message="Rules applied!" icon={Icons.green.Info} />);
      dispatch(
        saveCategorizationWorkflow({
          categorizationWorkflow: categorizationWorkflowMutation.data.data,
        })
      );
    }
    if (
      categorizationWorkflowMutation.isError ||
      !!categorizationWorkflowMutation.data?.errors
    ) {
      toast(
        <Toast
          message="There was a problem applying rules. Please try again."
          icon={Icons.green.Info}
        />
      );
    }
  }, [categorizationWorkflowMutation]);

  return reloadTransactions;
};
