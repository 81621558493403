import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "~/src/system/store/store";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// Sentry
Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

// Pages/Components
import { Entry } from "./app";

// CSS
import "./styles/index.css";
import "react-toastify/dist/ReactToastify.min.css";

class SkyForge extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Entry />
      </Provider>
    );
  }
}

ReactDOM.render(<SkyForge />, document.getElementById("root"));
