import React from "react";
import "./help-tip.css";

interface IHelpTip {
  linkTo: string;
}

export const HelpTip = ({ linkTo }: IHelpTip): JSX.Element => {
  return (
    <>
      <div className="learn-more">
        i{" "}
        <div className="learn-more__tip">
          <a className="learn-more__link" href={linkTo} target="_blank">
            Learn More
          </a>
        </div>
      </div>
    </>
  );
};
