import React, { useEffect, useState } from "react";
import { useAppSelector } from "~/src/system/store/hooks";
import { useDispatch } from "react-redux";
import {
  useRuleVaultQuery,
  useDeleteRuleMutation,
} from "~/src/state/rules/rules-api-slice";
import { saveRules } from "~/src/state/rules/rules-data-slice";
import { Button } from "~/src/components/button";
import { Drawer } from "~/src/components/drawer";
import { RuleUpsertForm } from "./components/rule-upsert-form";
import { PageHeader } from "~/src/components/page-header";
import { HelpTip } from "~/src/components/help-tip";
import { IRule } from "~/src/types/rules";
import { Icons } from "~/src/assets/icons";
import { TableLimit, TTableLimitValue } from "~/src/components/table-limit";
import { TablePaginator } from "~/src/components/table-paginator";
import { Toast } from "~/src/components/toast";
import { toast } from "react-toastify";
import { POSTING_TYPE_DISPLAY_MAP } from "~/src/constants/rules";
import "./rules-vault.css";
import { ViewEmpty } from "~/src/components/view-empty";

export const RulesVault = () => {
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const activeCompanyId = useAppSelector(
    (state) => state.companiesData.customerCompanies.active?.id ?? ""
  );
  const accountTagMapping = useAppSelector(
    (state) => state.accounts.accountTagMapping
  );

  // Rule queries/state
  const skip = !activeCompanyId;
  const ruleVaultQuery = useRuleVaultQuery(activeCompanyId, { skip });
  const allRules = useAppSelector((state) => state.ruleVault.rules);
  const [loading, setLoading] = useState<boolean>(false);

  // Pagination
  const [tableOffset, setTableOffset] = useState<number>(0);
  const [tableLimit, setTableLimit] = useState<TTableLimitValue>(50);

  // Sort/filter
  const [sortedRules, setSortedRules] = useState<IRule[]>([]);
  const [sorting, setSorting] = useState<boolean>(false);
  const [textFilter, setTextFilter] = useState<string>("");

  // Rule to be edited/deleted
  const [activeRule, setActiveRule] = useState<IRule | null>(null);
  const [
    deleteRule,
    {
      isLoading: deleteUpdating,
      isSuccess: deleteSuccess,
      isError: deleteError,
      data: deleteData,
    },
  ] = useDeleteRuleMutation();

  // If we have rules, copy them over to the sortable array;
  useEffect(() => {
    if (ruleVaultQuery.isLoading) setLoading(true);
    if (ruleVaultQuery.isSuccess && !!ruleVaultQuery.data.data) {
      setLoading(false);
      setSortedRules(ruleVaultQuery.data.data);
      dispatch(saveRules(ruleVaultQuery.data.data));
    }
  }, [ruleVaultQuery, activeCompanyId]);

  // Open the rule editor
  const openDrawer = (rule: IRule | null = null) => {
    rule ? setActiveRule(rule) : setActiveRule(null);
    setDrawerOpen(true);
  };

  // Close the rule editor
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  // Util to get the account for a rule.
  const findAccountForTag = (tagId: string) => {
    const acct = accountTagMapping.accounts
      .filter((account) => account.tags.length)
      .filter((account) => account.tags.find((tag) => tag.id === tagId));
    return acct.length
      ? `${acct[0]?.account?.accountNumber || ""} ${
          acct[0]?.account?.displayName
        }`
      : "N/A";
  };

  // Filter Rules
  const filterRules = (e: React.FormEvent<HTMLInputElement>) => {
    setTextFilter(e.currentTarget.value.toLowerCase());
  };

  useEffect(() => {
    if (textFilter) setTableOffset(0);
    const filteredRules = [...allRules].filter((rule) => {
      const ruleName = rule.merchant.name.toLowerCase();
      const memoConditions = rule.conditions.memoConditions.reduce(
        (accum, condition) => {
          return accum + condition.argument.toLowerCase();
        },
        ""
      );
      return [
        ruleName.includes(textFilter),
        memoConditions.includes(textFilter),
      ].some((condition) => !!condition);
    });

    setSortedRules(filteredRules);
  }, [textFilter, allRules]);

  // Sort Rules
  const sortRules = () => {
    setSorting(!sorting);
  };

  useEffect(() => {
    if (sorting) {
      setSortedRules(
        [...sortedRules].sort((a, b) => {
          return a.merchant.name?.localeCompare(b.merchant.name);
        })
      );
    } else {
      setSortedRules(allRules);
    }
  }, [sorting]);

  // Pagination
  const [pagedRules, setPagedRules] = useState<IRule[]>([]);

  useEffect(() => {
    setPagedRules(sortedRules.slice(tableOffset, tableOffset + tableLimit));
  }, [tableLimit, tableOffset, sortedRules]);

  // Display toasts.
  useEffect(() => {
    if (ruleVaultQuery.isLoading) {
      toast(
        <Toast message="Loading rules..." icon={Icons.green.LoadingAnimated} />,
        { toastId: "ruleVaultQueryLoading", autoClose: false }
      );
    } else {
      toast.dismiss("ruleVaultQueryLoading");
    }
  }, [ruleVaultQuery]);

  useEffect(() => {
    if (deleteSuccess && !!deleteData?.data) {
      toast(<Toast message="Rule deleted." icon={Icons.green.Trash} />);
    }
  }, [deleteSuccess, deleteData]);

  useEffect(() => {
    if (deleteError || !!deleteData?.errors) {
      toast(
        <Toast
          message="There was a problem when deleting this rule."
          icon={Icons.green.Info}
        />
      );
    }
  }, [deleteError, deleteData]);

  // Determines how to punctuate the clauses in a rule
  const punctuationizer = (array: any[], index: number, next: boolean) => {
    if (index + 1 <= array.length && next) {
      return `; `;
    } else if (index + 1 === array.length && !next) {
      return ``;
    } else {
      return `; `;
    }
  };

  return (
    <div>
      <div className="rules-page-header">
        <PageHeader icon={Icons.default.Vault} label={`Rules `}>
          <span className="text-orange">({allRules.length})</span>
          <HelpTip linkTo="https://www.notion.so/flowfinance/Rules-69eae01377dd493cb9ab3c5a9e7f3bf0" />
        </PageHeader>
        <input
          type="text"
          className="search-input rule-filter"
          onInput={(e) => filterRules(e)}
          placeholder="Search..."
        />
        <Button
          theme="secondary"
          size="large"
          onClick={sortRules}
          icon={Icons.default.SortAz}
          className="ml-4"
        />
        <Button
          onClick={() => openDrawer(null)}
          label="Create New Rule"
          theme="primary"
          size="large"
          disabled={loading}
        />
      </div>

      {allRules.length ? (
        <table className="table rules-table">
          <thead>
            <tr>
              <th scope="col">Merchant</th>
              <th scope="col">Posting Type</th>
              <th scope="col">Mapping</th>
              <th scope="col">Account</th>
              <th scope="col" className="rules-table__conditions">
                IF Statement
              </th>
              <th scope="col" className="rules-table__controls">
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody>
            {pagedRules.map((rule) => {
              return (
                <tr key={rule.id} className="">
                  <td>{rule.merchant.name}</td>
                  <td>
                    {
                      POSTING_TYPE_DISPLAY_MAP[
                        rule.conditions.postingTypeCondition.postingType
                      ]
                    }
                  </td>
                  <td>{rule.tag.name}</td>
                  <td>{findAccountForTag(rule.tag.id)}</td>
                  <td className="rules-table__conditions">
                    {rule.conditions.memoConditions.map((condition, index) => {
                      return (
                        <span
                          className="rule-condition"
                          key={`memo-condition-${index}`}
                        >
                          <span className="rule-condition__operator">
                            Memo Line {condition.operator}:&nbsp;
                          </span>
                          {condition.argument}
                          {punctuationizer(
                            rule.conditions.memoConditions,
                            index,
                            !!(
                              rule.conditions.memoStopWordConditions?.length ||
                              rule.conditions.amountConditions?.length ||
                              rule.conditions.sourceAccountCondition?.account
                                .externalId
                            )
                          )}
                        </span>
                      );
                    })}
                    {rule.conditions.memoStopWordConditions?.map(
                      (condition, index) => {
                        return (
                          <span
                            className="rule-condition"
                            key={`memo-condition-${index}`}
                          >
                            <span className="rule-condition__operator">
                              Memo Line {"DOES_NOT_CONTAIN"}:&nbsp;
                            </span>
                            {condition.argument}
                            {punctuationizer(
                              rule.conditions.memoStopWordConditions || [],
                              index,
                              !!(
                                rule.conditions.amountConditions?.length ||
                                rule.conditions.sourceAccountCondition?.account
                                  .externalId
                              )
                            )}
                          </span>
                        );
                      }
                    )}
                    {rule.conditions.amountConditions?.map(
                      (condition, index) => {
                        return (
                          <span
                            className="rule-condition"
                            key={`amount-condition-${index}`}
                          >
                            <span className="rule-condition__operator">
                              Amount {condition.operator}&nbsp;
                            </span>
                            {/* TODO: The currency symbol should be localized */}
                            ${condition.argument}
                            {punctuationizer(
                              rule.conditions.amountConditions || [],
                              index,
                              !!rule.conditions.sourceAccountCondition?.account
                                .externalId
                            )}
                          </span>
                        );
                      }
                    )}
                    {rule.conditions.sourceAccountCondition?.account
                      ?.externalId ? (
                      <span className="rule-condition">
                        <span className="rule-condition__operator">
                          Source account:
                        </span>{" "}
                        {
                          rule.conditions.sourceAccountCondition.account
                            ?.accountNumber
                        }{" "}
                        {
                          rule.conditions.sourceAccountCondition.account
                            ?.displayName
                        }
                        {`.`}
                      </span>
                    ) : null}
                  </td>
                  <td className="rules-table__controls">
                    <Button
                      onClick={() => openDrawer(rule)}
                      label="Edit Rule"
                      theme="primary"
                      size="medium"
                    />
                    <Button
                      onClick={() =>
                        deleteRule({
                          ruleId: rule.id,
                          companyId: activeCompanyId,
                        })
                      }
                      label="Delete Rule"
                      theme="secondary"
                      size="medium"
                      disabled={deleteUpdating}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <ViewEmpty message="There are no rules for this customer." />
      )}

      <div className="table__pagination">
        <TableLimit value={tableLimit} setValue={setTableLimit} />
        <TablePaginator
          offset={tableOffset}
          setOffset={setTableOffset}
          limit={tableLimit}
          length={sortedRules.length}
        />
      </div>

      <Drawer placement="right" isOpen={drawerOpen} onClose={closeDrawer}>
        {drawerOpen && (
          <RuleUpsertForm
            companyId={activeCompanyId}
            rule={activeRule}
            onClose={closeDrawer}
            filterAccounts
          />
        )}
      </Drawer>
    </div>
  );
};
