import { useActiveCompanyId } from "~/src/hooks/useActiveCompanyId";
import { isIInitiateConnectionResponseUrl } from "~/src/state/institutions/institutions-api-types";
import { useConnectionLocation } from "../utilities/use-connection-location";
import { useInitiateConnection } from "../queries/use-initiate-connection";
import { InstitutionId } from "~/src/types/institutions";
import { Dispatch, SetStateAction, useEffect } from "react";

export const useInitiateShopifyConnection = (
  setInputModalOpen: Dispatch<SetStateAction<boolean>>,
  shopName: string | undefined,
  setShopName: Dispatch<SetStateAction<string | undefined>>
) => {
  const companyId = useActiveCompanyId();
  const institutionId: InstitutionId = InstitutionId.SHOPIFY;

  const connectionLocation = useConnectionLocation();
  const { initiateConnectionQuery, initiateConnectionQueryStatus } =
    useInitiateConnection();

  const addConnectionDisabled =
    initiateConnectionQueryStatus.isLoading ||
    initiateConnectionQueryStatus.isFetching;

  const initiateConnection = async () => {
    setInputModalOpen(true);
  };

  useEffect(() => {
    (async () => {
      if (addConnectionDisabled || !shopName) return;

      const response = await initiateConnectionQuery({
        ...connectionLocation,
        // uncomment below line for local development
        // redirectUrl: "https://development.workbench.flowfi.com",
        companyId,
        institutionId,
        shopifyInput: { shopName },
      }).unwrap();

      if (
        response.data &&
        isIInitiateConnectionResponseUrl(response.data) &&
        response.data?.url
      )
        window.location.replace(response.data.url);
    })();
    return () => setShopName(undefined);
  }, [
    addConnectionDisabled,
    shopName,
    connectionLocation,
    companyId,
    institutionId,
    initiateConnectionQuery,
    setShopName,
  ]);

  return { initiateConnection, addConnectionDisabled };
};
