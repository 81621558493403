import React, { useEffect, useRef } from "react";
import { Button } from "~/src/components/button";
import { Modal } from "~/src/components/modal";
import { IDeleteConnectionInput } from "~/src/state/institutions/institutions-api-types";
import { IConnection } from "~/src/types/institutions";
import "./connection-delete-confirmation.css";

interface IConnectionDeleteConfirmationProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  deleteConnectionMutation: (input: IDeleteConnectionInput) => void;
  connection?: IConnection;
}

export const ConnectionDeleteConfirmation = ({
  modalOpen,
  setModalOpen,
  deleteConnectionMutation,
  connection,
}: IConnectionDeleteConfirmationProps): JSX.Element => {
  const onDisconnect = () => {
    if (connection)
      deleteConnectionMutation({
        companyId: connection.companyId,
        institutionId: connection.institutionId,
        connectionId: connection.id,
      });
    setModalOpen(!modalOpen);
  };

  const onCancel = () => setModalOpen(!modalOpen);

  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    // inside a modal, focus() will not work without setTimeout
    setTimeout(() => {
      if (buttonRef.current) buttonRef.current.focus();
    });
  }, [modalOpen]);

  return (
    <Modal
      title={`Are you sure you want to disconnect "${connection?.title}"?`}
      className="connection-delete-confirmation__modal"
      isOpen={modalOpen}
      onClose={onCancel}
    >
      <div className="connection-delete-confirmation__buttons">
        <Button
          label="Yes, disconnect"
          theme="secondary"
          size="medium"
          type="submit"
          className="connection-delete-confirmation__button"
          onClick={onDisconnect}
          ref={buttonRef}
        />
        <Button
          label="No, keep it connected"
          theme="primary"
          size="medium"
          type="button"
          className="connection-delete-confirmation__button"
          onClick={onCancel}
        />
      </div>
    </Modal>
  );
};
