import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRule } from "~/src/types/rules";

interface IRuleVaultState {
  rules: IRule[];
}

const initialState: IRuleVaultState = {
  rules: [],
};

const ruleVaultSlice = createSlice({
  name: "rules",
  initialState,
  reducers: {
    saveRules(state, action: PayloadAction<IRule[]>) {
      state.rules = action.payload;
    },
  },
});

export const { saveRules } = ruleVaultSlice.actions;
export default ruleVaultSlice.reducer;
