import React, { useEffect } from "react";
import { useUpdateConnectionMutation } from "~/src/state/institutions/institutions-api-slice";
import { toast } from "react-toastify";
import { Toast } from "~/src/components/toast";
import { Icons } from "~/src/assets/icons";
import { useDispatch } from "react-redux";
import { replaceConnection } from "~/src/state/institutions/institutions-data-slice";

export const useUpdateConnection = () => {
  const dispatch = useDispatch();
  const [updateConnectionMutation, updateConnectionStatus] =
    useUpdateConnectionMutation();

  const { isLoading, isError, isSuccess, data } = updateConnectionStatus;

  // when the query is in flight, show loading toast, otherwise dismiss it
  useEffect(() => {
    if (isLoading) {
      toast(
        <Toast
          message="Updating connection..."
          icon={Icons.green.LoadingAnimated}
        />,
        { toastId: "updateConnection", autoClose: false }
      );
    } else toast.dismiss("updateConnection");
  }, [isLoading]);

  // if the query is successful and returns data, show success toast and update store
  useEffect(() => {
    if (isSuccess && !!data.data) {
      toast(
        <Toast
          message="Connection was successfully updated!"
          icon={Icons.green.Info}
        />
      );
      dispatch(replaceConnection(data.data));
    }
  }, [isSuccess, data]);

  // if the query errors or returns an error, show error toast
  useEffect(() => {
    if (isError || !!data?.errors) {
      toast(
        <Toast
          message="There was a problem updating the connection. Please try again."
          icon={Icons.green.Info}
        />
      );
    }
  }, [isError, data]);

  return { updateConnectionMutation, isLoading };
};
