import States from "./states";

export interface ICompany {
  id: string;
  name: string;
  domain: string;
  avatar?: string;
  state: States | string;
  created?: string;
  updated?: string;
  employees?: string;
  stage?: string;
  industry?: string;
  location_city?: string;
  location_state?: string;
  location_zip?: string;

  type?: TCompanyType;
  primary_poc?: string;
  primary_business_activity?: string;
  revenue_model?: string;
  fiscal_start?: string | Date;
  books_start?: string | Date;
}

export interface ICompanyProfileUpdateInput {
  id: string;
  name: string;
  type?: TCompanyType;
  primary_poc?: string;
  primary_business_activity?: string;
  revenue_model?: string;
  fiscal_start?: string | Date;
  books_start?: string | Date;
}

export type TCompanyType =
  | "C-Corp"
  | "S-Corp"
  | "B-Corp"
  | "Sole Proprietorship"
  | "Partnership"
  | "LLC"
  | "Nonprofit";

export const CompanyTypes: TCompanyType[] = [
  "C-Corp",
  "S-Corp",
  "B-Corp",
  "Sole Proprietorship",
  "Partnership",
  "LLC",
  "Nonprofit",
];
