import React from "react";
import { Switch, Route, Redirect } from "react-router";
import { PortfolioTab } from "./components/portfolio-tab";
import { HelpTip } from "~/src/components/help-tip";
import { NavTabs } from "~/src/components/nav-tabs";
import { AppLogo } from "~/src/components/app-logo";
import { UserMenu } from "~/src/components/user-menu";
import ROUTES from "~/src/constants/routes";
import { IUser } from "~/src/types/user";
import { ICustomerCompanies } from "~/src/state/companies/companies-data-slice";
import "./portfolio.css";
import { ScrollContainer } from "~/src/components/scroll-container";

interface IPortfolio {
  user: IUser;
  customerCompanies: ICustomerCompanies;
}

export const Portfolio = ({ customerCompanies, user }: IPortfolio) => {
  return (
    <ScrollContainer viewContainer fullHeight noPadding>
      <div className="portfolio__header">
        <AppLogo dark marginless />
        {user.id && <UserMenu className="ml-auto" user={user} />}
      </div>
      <div className="portfolio-content">
        {/* TODO: These tabs need to display the count of each company list in a Badge */}
        <NavTabs
          routes={[
            ROUTES.portfolio.subroutes.assigned,
            ROUTES.portfolio.subroutes.all,
          ]}
        >
          <HelpTip linkTo="https://www.notion.so/flowfinance/Navigating-Portfolios-a1dab7f820de414ab737635b035fce1b" />
        </NavTabs>

        <Switch>
          <Route exact path={ROUTES.portfolio.path}>
            <Redirect to={ROUTES.portfolio.subroutes.assigned.path} />
          </Route>
          <Route path={ROUTES.portfolio.subroutes.assigned.path}>
            <PortfolioTab companies={customerCompanies.assigned} />
          </Route>
          <Route path={ROUTES.portfolio.subroutes.all.path}>
            <PortfolioTab companies={customerCompanies.all} />
          </Route>
        </Switch>
      </div>
    </ScrollContainer>
  );
};
