import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { Pane } from "~/src/components/pane";
import { Icons } from "~/src/assets/icons";
import { SVGHandler } from "~/src/components/svg-handler";
import { FlowFiDatePicker } from "~/src/components/datepicker";
import { Button } from "~/src/components/button";
import { useCategorizationWorkflowMutation } from "~/src/state/transactions/transactions-api-slice";
import { useAppSelector } from "~/src/system/store/hooks";
import { ICategorizationWorkflowInput } from "~/src/state/transactions/transactions-api-types";
import { saveCategorizationWorkflow } from "~/src/state/transactions/transactions-data-slice";
import { Drawer } from "~/src/components/drawer";
import { formatRequestDate } from "~/src/utilities/dates";
import { Toast } from "~/src/components/toast";
import ROUTES from "~/src/constants/routes";
import { ScrollContainer } from "~/src/components/scroll-container";
import "./load-transactions.css";

export const LoadTransactions: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // state
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const getStartDate = () => {
    if (startDate) {
      return moment.utc({
        year: startDate.getFullYear(),
        month: startDate.getMonth(),
        day: startDate.getDate(),
      });
    }
    return moment.utc(0);
  };
  const getEndDate = () => {
    const date = endDate ?? new Date();
    return moment.utc({
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
    });
  };
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const companyId = useAppSelector(
    (state) => state.companiesData.customerCompanies.active?.id || ""
  );
  const categorizationWorkflow = useAppSelector(
    (state) => state.transactions.categorizationWorkflow
  );

  // submit load transactions form
  const [startCategorizationWorkflow, categorizationWorkflowMutation] =
    useCategorizationWorkflowMutation();
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (categorizationWorkflowMutation.isLoading) return;

    // validate form input
    if (getStartDate().isAfter(getEndDate())) {
      toast(
        <Toast
          message="End date must be after start date."
          icon={Icons.green.Info}
        />
      );
      return;
    }

    // build mutation input
    const categorizationWorkflowInput: ICategorizationWorkflowInput = {
      companyId,
      start: formatRequestDate(getStartDate()), // start date or start of time
      end: formatRequestDate(getEndDate()), // end date or today
    };

    startCategorizationWorkflow(categorizationWorkflowInput);
  };

  // go to rule suggestions or close drawer if there are none
  const handleProceed = () => {
    history.push(ROUTES.categorization.subroutes.suggestedRules.path);
  };

  // save categorization workflow data to store and manage query status
  useEffect(() => {
    if (categorizationWorkflowMutation.isUninitialized) return;
    if (categorizationWorkflowMutation.isLoading) {
      toast(
        <Toast
          message="Loading transactions and processing Customer Rules..."
          icon={Icons.green.LoadingAnimated}
        />,
        { toastId: "categorizationWorkflowMutationLoading", autoClose: false }
      );
    } else {
      toast.dismiss("categorizationWorkflowMutationLoading");
    }

    if (
      categorizationWorkflowMutation.isSuccess &&
      !!categorizationWorkflowMutation.data.data
    ) {
      dispatch(
        saveCategorizationWorkflow({
          categorizationWorkflow: categorizationWorkflowMutation.data.data,
          dateRange: {
            start: getStartDate().valueOf(),
            end: getEndDate().valueOf(),
          },
        })
      );
      if (
        categorizationWorkflowMutation.data.data.uncategorizedLineItems
          .length ??
        0
      ) {
        setDrawerOpen(true);
      } else {
        toast(
          <Toast
            message="No transactions were found for this date range."
            icon={Icons.green.Info}
          />
        );
      }
    }
    if (
      categorizationWorkflowMutation.isError ||
      !!categorizationWorkflowMutation.data?.errors
    ) {
      toast(
        <Toast
          message="There was a problem loading transactions. Please try again."
          icon={Icons.green.Info}
        />
      );
    }
  }, [categorizationWorkflowMutation]);

  const numCategorized = categorizationWorkflow.categorizedLineItems.length;
  const numUncategorized = categorizationWorkflow.uncategorizedLineItems.length;
  const numTransactions = numCategorized + numUncategorized;

  return (
    <>
      {!!companyId && (
        <Pane className="load-transactions">
          <LoadTransactionsHeader />
          <form className="load-transactions__form" onSubmit={handleSubmit}>
            <div className="load-transactions__date-pickers">
              <FlowFiDatePicker
                onChange={(date: Date) => setStartDate(date)}
                placeholder="Start of Time"
                selected={startDate}
                label="From"
              />
              <FlowFiDatePicker
                onChange={(date: Date) => setEndDate(date)}
                placeholder="Today"
                selected={endDate}
                label="To"
              />
            </div>
            <Button
              className="justify-self-end"
              label="Load Uncategorized Transactions"
              theme="primary"
              size="large"
              type="submit"
              noWrap
            />
          </form>
          <Drawer
            isOpen={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            placement="right"
          >
            <ScrollContainer noPadding fullHeight>
              <div className="load-transactions__drawer">
                <div>
                  <LoadTransactionsHeader />
                </div>
                <div className="load-transactions__drawer-insights text-center">
                  <SVGHandler
                    image={Icons.green.CheckCircled}
                    width={54}
                    height={54}
                    altText="green checkmark in circle"
                  />
                  <h3>Categorization Successful</h3>
                  <p>
                    <span className="font-bold">{`${numCategorized} of ${numTransactions} transactions`}</span>{" "}
                    have been auto-categorized
                  </p>
                </div>
                <div>
                  <Break />
                  <Button
                    label="Proceed"
                    theme="primary"
                    size="large"
                    onClick={handleProceed}
                    fullWidth
                  />
                </div>
              </div>
            </ScrollContainer>
          </Drawer>
        </Pane>
      )}
    </>
  );
};

const LoadTransactionsHeader = () => (
  <>
    <h3 className="section-header">
      <SVGHandler
        image={Icons.default.VaultEdit}
        width={32}
        height={32}
        altText="Load transactions edit inbox icon"
      />
      Load Transactions
    </h3>
    <Break />
  </>
);

const Break = () => <div className="load-transaction__break" />;
