import React from "react";
import { useDispatch } from "react-redux";
import { setActiveCompany } from "~/src/state/companies/companies-data-slice";
import { useHistory } from "react-router";
import { CompanyIcon } from "~/src/components/company-icon";
import { Badge } from "~/src/components/badge";
import { set as lsSet } from "~/src/system/ls";
import { ICompany } from "~/src/types/company";
import "./portfolio-tile.css";

interface IPortfolioTile {
  company: ICompany;
}

// TODO: once action items are implemented, enable the lower portion and pass the count to the badge
export const PortfolioTile = ({ company }: IPortfolioTile) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const goToCompany = () => {
    dispatch(setActiveCompany(company));
    lsSet("lastActiveCompanyId", company.id);
    history.push(`/companies/${company.id}`);
  };

  return (
    <>
      {company && (
        <div className="portfolio-tile" onClick={() => goToCompany()}>
          <div className="portfolio-tile__upper">
            <CompanyIcon company={company} size="xl" />
            <h3 className="ml-4">{company.name}</h3>
          </div>
          {/* <div className="portfolio-tile__lower">
            <h4>Action Items:</h4>
            <Badge count={4} label="pending" />
          </div> */}
        </div>
      )}
    </>
  );
};
