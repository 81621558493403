import React, { useState, useEffect } from "react";
import { Icons } from "~/src/assets/icons";
import Classnames from "classnames";
import "./dropdown.css";

type DropdownDirections = "left" | "right" | "center";

interface IDropdownProps {
  direction: DropdownDirections;
  icon?: string;
  iconAlt?: string;
  openIcon?: string;
  openIconAlt?: string;
  rightIcon?: string;
  rightIconAlt?: string;
  textOnly?: boolean;
  label?: string;
  toggleText?: boolean;
  openText?: string;
  closeText?: string;
  children: React.ReactChild | React.ReactChildren | React.ReactNode;
  iconClassName?: string;
  buttonClassName?: string;
  dropdownClassName?: string;
  customTrigger?: React.ReactNode;
  isSelect?: boolean;
  noPadding?: boolean;
  forceOpen?: boolean;
}

export const Dropdown = ({
  direction,
  children,
  icon,
  iconAlt,
  openIcon,
  openIconAlt,
  rightIcon,
  rightIconAlt,
  textOnly,
  label,
  toggleText,
  openText,
  closeText,
  iconClassName,
  buttonClassName,
  dropdownClassName,
  customTrigger,
  isSelect,
  noPadding,
  forceOpen,
}: IDropdownProps) => {
  const [open, setOpen] = useState(false);

  // Attaches an offClick handler if the flyout is open and removes it if closed.
  useEffect(() => {
    if (open) {
      document.addEventListener("click", handleOffClick);
    }
    if (!open && forceOpen === true) {
      setOpen(!open);
    }
    if (open && forceOpen === false) {
      setOpen(!open);
    }
    return () => document.removeEventListener("click", handleOffClick);
  }, [open, forceOpen]);

  // Closes the flyout.
  const handleOffClick = () => {
    setOpen(false);
  };

  const dropdownClasses = Classnames(
    "dropdown__items",
    dropdownClassName ?? "",
    {
      "dropdown__items--left": direction === "left",
      "dropdown__items--right": direction === "right",
      "dropdown__items--center": direction === "center",
      "dropdown__items--select": isSelect,
      "dropdown__items--no-padding": noPadding,
    }
  );

  const buttonClasses = Classnames("dropdown__trigger", buttonClassName ?? "", {
    "dropdown__trigger--text": textOnly,
    "dropdown__trigger--select": isSelect,
  });

  return (
    <div className="dropdown">
      <button className={buttonClasses} onClick={() => setOpen(!open)}>
        {customTrigger && customTrigger}
        {!customTrigger && !textOnly && (
          <>
            <img
              src={(open && openIcon) || icon || Icons.default.MenuBurger}
              alt={(open && openIconAlt) || iconAlt || "More Actions Icon"}
              className={`dropdown__icon ${iconClassName}`}
            />
            <span className="w-full">{label}</span>
          </>
        )}
        {!customTrigger && textOnly && toggleText && (
          <span>{open ? closeText || "Close" : openText || "Open"}</span>
        )}
        {!customTrigger && textOnly && !toggleText && (
          <span className="w-full">{label}</span>
        )}
        {rightIcon && (
          <img
            src={rightIcon ? rightIcon : Icons.default.ArrowDownSm}
            alt={rightIconAlt ? rightIconAlt : "Down Arrow"}
            className={`dropdown__right-icon ${iconClassName}`}
          />
        )}
      </button>

      {open && (
        <div className={dropdownClasses} onClick={() => setOpen(false)}>
          {children}
        </div>
      )}
    </div>
  );
};
