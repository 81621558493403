const prefix = process.env.LS_PREFIX;

/**
 * Set auto-prefixed value to local storage
 */
function set(name: string, data: any) {
  let str = data;

  if (data && (data.constructor === Object || data.constructor === Array)) {
    str = JSON.stringify(data);
  }

  window.localStorage.setItem(`${prefix}.${name}`, str || "");
}

/**
 * Get ls value with auto-prefix or return undefined
 */
function get(name: string): any {
  const str = window.localStorage.getItem(`${prefix}.${name}`);

  try {
    return JSON.parse(str || "");
  } catch (e) {
    return str || undefined;
  }
}

/**
 * Clear auto prefixed ls item
 */
function del(name: string) {
  window.localStorage.removeItem(`${prefix}.${name}`);
}

export { get, set, del };
