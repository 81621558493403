/* 
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
*/

export const accountSchema = `
  externalId
  displayName
  accountBlock
  accountType
  accountNumber
`;

export const tagSchema = `
  id
  name
  scope
`;

export const accountsSchema = `
  account { ${accountSchema} }
  tags { ${tagSchema} }
`;
