import moment from "moment";
import React from "react";
import { useHistory } from "react-router";
import { Icons } from "~/src/assets/icons";
import { Button } from "~/src/components/button";
import { CompanyIcon } from "~/src/components/company-icon";
import { Loading } from "~/src/components/loading";
import { Pane } from "~/src/components/pane";
import { PageHeader } from "~/src/components/page-header";
import { HelpTip } from "~/src/components/help-tip";
import { ViewEmpty } from "~/src/components/view-empty";
import ROUTES from "~/src/constants/routes";
import { useUpdateActionItemStateMutation } from "~/src/state/action-items/action-items-api-slice";
import { useAppSelector } from "~/src/system/store/hooks";
import { formatDisplayDate } from "~/src/utilities/dates";
import { ActionItemRow } from "../action-items/action-item-row";
import { useActionItems } from "~/src/hooks/useActionItems";
import "./company-homepage.css";
import { stripSpecialCharacters } from "~/src/utilities/strings";

export const CompanyHomepage = () => {
  const history = useHistory();
  const selectedCompany = useAppSelector(
    (state) => state.companiesData.customerCompanies.active
  );
  const { actionItems, isLoading: actionItemsLoading } = useActionItems();
  const filteredActionItems = actionItems.filter(
    (item) => item.state === "ready_for_action"
  );
  const profileUrl = selectedCompany
    ? "/companies/" + selectedCompany.id + "/profile"
    : "";

  const [
    updateActionItemState,
    updateActionItemStateResult,
  ] = useUpdateActionItemStateMutation();

  const loading = actionItemsLoading || updateActionItemStateResult.isLoading;

  return (
    <>
      <PageHeader icon={Icons.default.Home} label="Home">
        <HelpTip linkTo="https://www.notion.so/flowfinance/Customer-Page-9db3c376acde47808ad5d26b34971866" />
      </PageHeader>
      {selectedCompany && (
        <div className="company-homepage">
          <div className="">
            <div className="subheader-row">
              <div>
                <h3>To Do List ({filteredActionItems.length})</h3>
              </div>
              <div className="text-right">
                <Button
                  size={"medium"}
                  theme={"secondary"}
                  label="View Full List"
                  onClick={() => history.push(ROUTES.actionItems.path)}
                />
              </div>
            </div>

            {filteredActionItems.length ? (
              <table className="table action-items-container">
                <tbody>
                  {filteredActionItems?.map((item) => {
                    return (
                      <ActionItemRow
                        item={item}
                        key={item.id}
                        selectable={false}
                        ctaLabel={"Close"}
                        condensed
                        onCtaClick={async () => {
                          // handle close action item here
                          await updateActionItemState({
                            action_item_id: item.id,
                            state: "closed",
                          });
                        }}
                      />
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <ViewEmpty message="There are no action items that are ready." />
            )}
          </div>
          <div className="company-profile-info-box w-full">
            <Pane>
              <div className="company-profile__meta">
                <div className="company-profile__logo">
                  <CompanyIcon company={selectedCompany} size="2xl" />
                  <h3 className="company-profile__name">
                    {selectedCompany.name}
                  </h3>
                </div>
              </div>
              {selectedCompany.type && (
                <div className="company-profile-info-box__row">
                  <div className="text-left">Entity Type</div>
                  <div>{selectedCompany.type}</div>
                </div>
              )}
              {selectedCompany.books_start && (
                <div className="company-profile-info-box__row">
                  <div className="text-left">First Month of Books</div>
                  <div>
                    {formatDisplayDate(moment(selectedCompany.books_start))}
                  </div>
                </div>
              )}
              {selectedCompany.fiscal_start && (
                <div className="company-profile-info-box__row">
                  <div className="text-left">Fiscal Start</div>
                  <div>
                    {formatDisplayDate(moment(selectedCompany.fiscal_start))}
                  </div>
                </div>
              )}
              {selectedCompany.primary_poc && (
                <div className="company-profile-info-box__row">
                  <div className="text-left">Primary POC</div>
                  <div>{selectedCompany.primary_poc}</div>
                </div>
              )}
              {selectedCompany.primary_business_activity && (
                <div className="company-profile-info-box__row">
                  <div className="text-left">Primary Bus. Activity</div>
                  <div>{selectedCompany.primary_business_activity}</div>
                </div>
              )}
              {selectedCompany.revenue_model && (
                <div className="company-profile-info-box__row">
                  <div className="text-left">Revenue Model</div>
                  <div>{selectedCompany.revenue_model}</div>
                </div>
              )}
              {selectedCompany.domain && (
                <div className="company-profile-info-box__row">
                  <div className="text-left">Unique Inbox</div>
                  <div>{`${stripSpecialCharacters(
                    selectedCompany.domain
                  )}@flowfi.com`}</div>
                </div>
              )}

              <Button
                label="Edit Information"
                theme="secondary"
                size="large"
                fullWidth
                onClick={() => {
                  if (profileUrl) history.push(profileUrl);
                }}
              />
            </Pane>
          </div>
        </div>
      )}
      {loading && <Loading />}
    </>
  );
};
