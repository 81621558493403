import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import {
  CreateSupportingScheduleInput,
  DeleteSupportingScheduleInput,
  DeleteSupportingScheduleResponse,
  SupportingSchedule,
  SupportingScheduleEntry,
  SyncSupportingScheduleWithQBOInput,
} from "~/src/graphql/gruul/api";
import {
  SupportingScheduleEntriesQueryVariables,
  SupportingSchedulesQueryVariables,
  PreviewSupportingScheduleQueryVariables,
  TransactionInfoInput,
} from "~/src/graphql/gruul/api";
import {
  supportingSchedules,
  supportingScheduleEntries,
  previewSupportingSchedule,
} from "~/src/graphql/gruul/queries";
import { transformGruulApiResponse } from "~/src/system/resource";
import {
  deleteSupportingSchedule,
  syncSupportingScheduleWithQBO,
} from "~/src/graphql/gruul/mutations";

export const supportingSchedulesApi = createApi({
  reducerPath: "supportingSchedulesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.GRUUL_API,
    prepareHeaders(headers) {
      headers.set("content-type", "application/json");
      headers.set(
        "authorization",
        `Bearer ${process.env.GRUUL_API_TOKEN || ""}`
      );
      return headers;
    },
  }),
  tagTypes: [
    "supportingSchedules",
    "supportingScheduleEntries",
    "supportingSchedulesPreview",
  ],
  endpoints: (build) => ({
    supportingSchedules: build.query<
      { data?: SupportingSchedule[] },
      SupportingSchedulesQueryVariables
    >({
      query: (variables: SupportingSchedulesQueryVariables) => {
        const query = supportingSchedules;
        return {
          url: "/",
          method: "POST",
          body: { query, variables },
        };
      },
      providesTags: ["supportingSchedules"],
      transformResponse: transformGruulApiResponse,
    }),
    previewSupportingSchedules: build.query<
      { data?: SupportingSchedule },
      PreviewSupportingScheduleQueryVariables
    >({
      query: (variables: PreviewSupportingScheduleQueryVariables) => {
        const query = previewSupportingSchedule;
        return {
          url: "/",
          method: "POST",
          body: { query, variables },
        };
      },
      providesTags: ["supportingSchedulesPreview"],
      transformResponse: transformGruulApiResponse,
    }),
    supportingScheduleEntries: build.query<
      { data?: SupportingScheduleEntry[] },
      SupportingScheduleEntriesQueryVariables
    >({
      query: (variables: SupportingScheduleEntriesQueryVariables) => {
        const query = supportingScheduleEntries;
        return {
          url: "/",
          method: "POST",
          body: { query, variables },
        };
      },
      providesTags: ["supportingScheduleEntries"],
      transformResponse: transformGruulApiResponse,
    }),
    createSupportingSchedule: build.mutation<
      { data?: SupportingSchedule },
      { companyId: string; input: CreateSupportingScheduleInput }
    >({
      query: (variables: {
        companyId: string;
        input: CreateSupportingScheduleInput;
      }) => {
        // Don't use the autogenerated mutation that asks for a
        // supportingSchedule response with nested supportingScheduleEntries.
        // It crashes.
        const query = `mutation CreateSupportingSchedule(
          $companyId: ID!
          $input: CreateSupportingScheduleInput!
        ) {
          createSupportingSchedule(companyId: $companyId, input: $input) {
            id
          }
        }
      `;
        return {
          url: "/",
          method: "POST",
          body: { query, variables },
        };
      },
      invalidatesTags: ["supportingSchedules"],
      transformResponse: transformGruulApiResponse,
    }),
    syncSupportingSchedule: build.mutation<
      { data?: SupportingScheduleEntry[] },
      { companyId: string; input: SyncSupportingScheduleWithQBOInput }
    >({
      query: (variables: {
        companyId: string;
        input: SyncSupportingScheduleWithQBOInput;
      }) => ({
        url: "/",
        method: "POST",
        body: { query: syncSupportingScheduleWithQBO, variables },
      }),
      invalidatesTags: ["supportingSchedules"],
      transformResponse: transformGruulApiResponse,
    }),
    addTransactionInfoToSupportingSchedule: build.mutation<
      { data?: SupportingSchedule },
      { companyId: string; input: TransactionInfoInput }
    >({
      query: (variables: {
        companyId: string;
        input: TransactionInfoInput;
      }) => {
        const query = `mutation AddTransactionInfoToSupportingSchedule(
          $companyId: ID!
          $input: TransactionInfoInput!
        ) {
          addTransactionInfoToSupportingSchedule(companyId: $companyId, input: $input) {
            id
          }
        }
      `;
        return {
          url: "/",
          method: "POST",
          body: { query, variables },
        };
      },
      invalidatesTags: ["supportingSchedules"],
      transformResponse: transformGruulApiResponse,
    }),
    deleteSupportingSchedule: build.mutation<
      { data?: DeleteSupportingScheduleResponse },
      { companyId: string; input: DeleteSupportingScheduleInput }
    >({
      query: (variables: {
        companyId: string;
        input: DeleteSupportingScheduleInput;
      }) => ({
        url: "/",
        method: "POST",
        body: { query: deleteSupportingSchedule, variables },
      }),
      invalidatesTags: ["supportingSchedules"],
      transformResponse: transformGruulApiResponse,
    }),
  }),
});

export const {
  useAddTransactionInfoToSupportingScheduleMutation,
  useCreateSupportingScheduleMutation,
  useDeleteSupportingScheduleMutation,
  useLazyPreviewSupportingSchedulesQuery,
  useSupportingScheduleEntriesQuery,
  useSupportingSchedulesQuery,
  useSyncSupportingScheduleMutation,
} = supportingSchedulesApi;
