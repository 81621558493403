import React from "react";
import Classnames from "classnames";
import "./layout.css";

interface ILayoutProps {
  mode: TLayoutModes;
  fullHeight?: boolean;
  direction?: TFlexDirections;
  cols?: TColRange;
  gap?: TGapRange;
  layout?: TLayouts;
  children?: React.ReactNode | React.ReactChild | React.ReactChildren;
  className?: string;
}

type TColRange = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type TGapRange = 2 | 4 | 8;

type TLayoutModes = "flex" | "grid";

type TFlexDirections = "row" | "col";

type TLayouts =
  | "flowfi-bare"
  | "flowfi-login"
  | "flowfi-base"
  | "flowfi-condensed"
  | "flowfi-two"
  | "flowfi-three"
  | "responsive-grid-four";

/**
 * The purpose of the layout container is to provide a general component to wrap
 * either grid or flex items and provide theme support where necessary.
 */
export const LayoutContainer = ({
  mode,
  fullHeight,
  direction,
  cols,
  gap,
  layout,
  children,
  className,
}: ILayoutProps) => {
  const gridClasses = Classnames("flowfi-grid", {
    [`grid-cols-${cols}`]: cols && !layout,
    [`gap-${gap}`]: gap && !layout,
    [`${layout}`]: layout,
  });

  const flexClasses = Classnames("flowfi-flex", {
    "flex-row": direction === "row",
    "flex-col": direction === "col",
  });

  const heightClasses = Classnames("", {
    "h-full": fullHeight,
  });

  return (
    <div
      className={`${
        mode === "grid" ? gridClasses : flexClasses
      } ${heightClasses} ${className || null}`}
    >
      {children}
    </div>
  );
};
