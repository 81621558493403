import React, { useState } from "react";
import { Redirect, useHistory } from "react-router";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

import ROUTES from "~/src/constants/routes";
import { useAppSelector } from "~/src/system/store/hooks";
import { Drawer } from "~/src/components/drawer";
import { Button } from "~/src/components/button";
import { SVGHandler } from "~/src/components/svg-handler";
import { Icons } from "~/src/assets/icons";
import { IRuleSuggestion, IMerchant } from "~/src/types/transactions";
import { Toast } from "~/src/components/toast";
import { getAccountName } from "~/src/utilities/accounts";
import { formatCurrency } from "~/src/utilities/numbers";
import { useCreateMerchantMutation } from "~/src/state/transactions/transactions-api-slice";
import { formatDisplayDate } from "~/src/utilities/dates";
import { ScrollContainer } from "~/src/components/scroll-container";
import { TRANSACTION_TYPE_DISPLAY_MAP } from "~/src/constants/transactions";
import { POSTING_TYPE_DISPLAY_MAP } from "~/src/constants/rules";
import "./rules-suggestions.css";
import {
  ICreateMerchantInput,
  ICreateMerchantOutput,
} from "~/src/state/transactions/transactions-api-types";
import { IRuleUpsertInput } from "~/src/state/rules/rules-api-types";
import { useUpsertAndApplyRule } from "../categorization/hooks/use-upsert-and-apply-rule";

export const RulesSuggestions = () => {
  const history = useHistory();
  const { upsertAndApplyRule, upsertAndApplyRuleStatus } =
    useUpsertAndApplyRule();
  const [createMerchant, createMerchantMutation] = useCreateMerchantMutation();

  // state/store
  const activeCompanyId = useAppSelector(
    (state) => state.companiesData.customerCompanies.active?.id ?? ""
  );
  const ruleSuggestions = useAppSelector<IRuleSuggestion[] | undefined>(
    (state) => state.transactions.categorizationWorkflow.ruleSuggestions
  );
  const merchantList = useAppSelector((state) => state.transactions.merchants);
  const [ruleIdx, setRuleIdx] = useState<number>(0);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  // Compares this company's merchants to the one provided by the rule
  const findExistingMerchant = async (merchant: IMerchant) =>
    merchantList.find(
      (m) => m.name.toLowerCase() === merchant.name.toLowerCase()
    );

  // Adds either a new vendor or customer, depending on posting type
  const addNewMerchant = async (merchantData: ICreateMerchantInput) => {
    const newMerchant = (await createMerchant(merchantData)) as {
      data: ICreateMerchantOutput;
    };
    if (newMerchant.data.data) {
      return newMerchant.data.data.externalId;
    }
    return "";
  };

  // Assemble the upsert input
  const handleAddRule = async () => {
    try {
      if (ruleSuggestions) {
        const { merchant, tag, conditions } = ruleSuggestions[ruleIdx].rule;
        let merchantId = "";

        await findExistingMerchant(merchant).then(
          async (data: IMerchant | undefined) => {
            if (data) {
              // if the filter returns a match, set merchant ID to the existing merchant
              merchantId = data?.externalId;
            } else {
              // if the filter doesn't return a match, create a new merchant using the rule's merchant name
              const newMerchantData: ICreateMerchantInput = {
                companyId: activeCompanyId,
                name: merchant.name,
                postingType: conditions.postingTypeCondition.postingType,
              };

              const newMerchantId = await addNewMerchant(newMerchantData);
              merchantId = newMerchantId;
            }
          }
        );

        if (!merchantId) {
          toast(
            <Toast
              message="There was a problem adding this rule. Please try again."
              icon={Icons.green.Info}
            />
          );
          return;
        }

        const ruleInput: IRuleUpsertInput = {
          id: uuidv4(), // NEW id, not the existing rule id
          companyId: activeCompanyId,
          merchantId: merchantId,
          tagId: tag.id,
          conditions: {
            postingTypeCondition: conditions.postingTypeCondition,
            memoConditions: conditions.memoConditions,
            memoStopWordConditions: conditions.memoStopWordConditions,
            amountConditions: conditions.amountConditions,
            sourceAccountCondition: conditions.sourceAccountCondition
              ? {
                  externalAccountId:
                    conditions.sourceAccountCondition.account.externalId,
                }
              : undefined,
          },
        };

        await upsertAndApplyRule(ruleInput).then((res) => {
          if (res.data?.rule) advanceSuggestions();
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Go to the next rule, or next page if finished
  const advanceSuggestions = () => {
    const scrollMe = document.querySelector(".scroll-container.view-container");
    if (scrollMe) scrollMe.scrollTop = 0;

    if (ruleSuggestions && ruleIdx < ruleSuggestions.length - 1) {
      setRuleIdx((current) => current + 1);
    } else {
      history.push(
        ROUTES.categorization.subroutes.uncategorizedTransactions.path
      );
    }
  };

  // Handle rejection
  const handleRejectRule = () => {
    const toastId = "rejectRule";
    const toastComponent = (
      <Toast
        message={`Rule ${ruleIdx + 1} rejected.`}
        icon={Icons.green.Info}
      />
    );
    if (toast.isActive(toastId))
      toast.update(toastId, { render: toastComponent });
    else toast(toastComponent, { toastId });

    advanceSuggestions();
  };

  let conditionNum = 0;
  return ruleSuggestions?.length ? (
    <div className="rule-suggestions">
      <ScrollContainer fullHeight>
        <h2 className="page-header">
          <SVGHandler image={Icons.default.Remove} width={36} height={36} />
          <span>
            Suggested Rules {ruleIdx + 1}/{ruleSuggestions?.length || 1}
          </span>
        </h2>
        <header>
          {ruleSuggestions ? (
            <div>
              <dl className="text-sm">
                {ruleSuggestions[ruleIdx].rule?.conditions.memoConditions.map(
                  (condition) => {
                    conditionNum++;
                    return (
                      <React.Fragment key={`condition-${conditionNum}`}>
                        {/* TODO: IF/AND/OR prefixes for conditions */}
                        <dt className={conditionNum > 1 ? "clear-none" : ""}>
                          Condition {conditionNum}:
                        </dt>
                        <dd className="mr-4">
                          Memo Line {condition.operator} {condition.argument}
                        </dd>
                      </React.Fragment>
                    );
                  }
                )}
                {ruleSuggestions[
                  ruleIdx
                ].rule?.conditions.memoStopWordConditions?.map((condition) => {
                  conditionNum++;
                  return (
                    <React.Fragment key={`condition-${conditionNum}`}>
                      {/* TODO: IF/AND/OR prefixes for conditions */}
                      <dt className="clear-none">Condition {conditionNum}:</dt>
                      <dd className="mr-4">
                        Memo Line DOES_NOT_CONTAIN {condition.argument}
                      </dd>
                    </React.Fragment>
                  );
                })}
                {ruleSuggestions[
                  ruleIdx
                ].rule?.conditions.amountConditions?.map((condition) => {
                  conditionNum++;
                  return (
                    <React.Fragment key={`condition-${conditionNum}`}>
                      {/* TODO: IF/AND/OR prefixes for conditions */}
                      <dt className="clear-none">Condition {conditionNum}:</dt>
                      <dd className="mr-4">
                        Amount {condition.operator} {condition.argument}
                      </dd>
                    </React.Fragment>
                  );
                })}
                <dt>Posting Type:</dt>
                <dd>
                  {
                    POSTING_TYPE_DISPLAY_MAP[
                      ruleSuggestions[ruleIdx].rule?.conditions
                        .postingTypeCondition.postingType
                    ]
                  }
                </dd>
                <dt>Vendor:</dt>
                <dd>{ruleSuggestions[ruleIdx].rule?.merchant.name}</dd>
                <dt>Tag:</dt>
                <dd>{ruleSuggestions[ruleIdx].rule?.tag.name}</dd>
                <dt>QBO Account:</dt>
                <dd>
                  {ruleSuggestions[ruleIdx].mappedAccount?.accountNumber}{" "}
                  {ruleSuggestions[ruleIdx].mappedAccount?.displayName}
                </dd>
              </dl>
            </div>
          ) : null}
        </header>

        <div>
          {ruleSuggestions ? (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    {
                      ruleSuggestions[ruleIdx].matchingUncategorizedLineItems
                        .length
                    }{" "}
                    Transactions
                  </th>
                  <th scope="col">Transaction Type</th>
                  <th scope="col">Source Account</th>
                  <th className="text-right" scope="col">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {ruleSuggestions[ruleIdx].matchingUncategorizedLineItems.map(
                  (item, i) => (
                    <tr
                      key={`${item.externalTransactionId}-${item.lineItemId}-${i}`}
                    >
                      <td>
                        <strong className="pr-4">
                          {formatDisplayDate(moment(item.date))}
                        </strong>{" "}
                        {item.memo}
                      </td>
                      <td>{TRANSACTION_TYPE_DISPLAY_MAP[item.type]}</td>
                      <td>{getAccountName(item.sourceAccountInfo)}</td>
                      <td className="text-right">
                        {/* TODO: currency should be localized */}
                        <strong>{formatCurrency(item.amount)}</strong>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          ) : null}
        </div>
      </ScrollContainer>

      <div className="rule-suggestions__buttons">
        <Button
          size="large"
          label="Reject Rule"
          theme="secondary"
          icon={Icons.default.Check}
          onClick={handleRejectRule}
          disabled={
            upsertAndApplyRuleStatus.isLoading ||
            createMerchantMutation.isLoading
          }
          className="ml-auto"
        />
        <Button
          size="large"
          label="Add Rule"
          theme="primary"
          icon={Icons.white.Check}
          onClick={handleAddRule}
          disabled={
            upsertAndApplyRuleStatus.isLoading ||
            createMerchantMutation.isLoading
          }
        />
      </div>

      <Drawer
        placement="right"
        isOpen={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        {/* EXPL: Editing rules inline comes later */}
      </Drawer>
    </div>
  ) : (
    <Redirect
      to={ROUTES.categorization.subroutes.uncategorizedTransactions.path}
    />
  );
};
