import React, { useState } from "react";
import { IKlaviyoInstitution } from "~/src/types/institutions";
import { Institution } from "..";
import { useCreateKlaviyoConnection } from "../../../hooks/create-connection/use-create-klaviyo-connection";
import { ConnectionsTable } from "../../connections-table";
import {
  CreateKlaviyoConnectionForm,
  IKlaviyoInputWithTitle,
} from "./create-klaviyo-connection-form";

interface IKlaviyoInstitutionProps {
  institution: IKlaviyoInstitution;
}

export const KlaviyoInstitution = ({
  institution,
}: IKlaviyoInstitutionProps): JSX.Element => {
  const [klaviyoInput, setKlaviyoInput] =
    useState<IKlaviyoInputWithTitle | undefined>();
  const [inputModalOpen, setInputModalOpen] = useState(false);
  const { initiateConnection, addConnectionDisabled } =
    useCreateKlaviyoConnection(
      setInputModalOpen,
      klaviyoInput,
      setKlaviyoInput
    );

  return (
    <>
      <Institution
        key={institution.id}
        institution={institution}
        addConnectionButtonDisabled={addConnectionDisabled}
        onAddConnection={initiateConnection}
      >
        {!!institution.connections.length && (
          <ConnectionsTable connections={institution.connections} />
        )}
      </Institution>
      <CreateKlaviyoConnectionForm
        {...{
          inputModalOpen,
          setInputModalOpen,
          setKlaviyoInput,
          addConnectionDisabled,
        }}
      />
    </>
  );
};
