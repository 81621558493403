import { useAppSelector } from "~/src/system/store/hooks";
import { IConnection } from "~/src/types/institutions";

export const useGetConnection = (connectionId: string) => {
  return useAppSelector((state) =>
    state.institutions.institutions
      .reduce(
        (connections: IConnection[], institution) => [
          ...connections,
          ...institution.connections,
        ],
        []
      )
      .find((connection) => connection.id === connectionId)
  );
};
