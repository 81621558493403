/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSupportingSchedule = /* GraphQL */ `
  mutation CreateSupportingSchedule(
    $companyId: ID!
    $input: CreateSupportingScheduleInput!
  ) {
    createSupportingSchedule(companyId: $companyId, input: $input) {
      id
      type
      merchantName
      merchantId
      merchantType
      description
      transactionDate
      servicePeriodStart
      servicePeriodEnd
      recognitionInterval
      totalAmount
      originatingTransactionId
      originatingTransactionLineItemId
      originatingTransactionType
      incomeOrExpenseAccountId
      incomeOrExpenseAccountName
      assetOrLiabilityAccountId
      assetOrLiabilityAccountName
      supportingScheduleEntries {
        id
        supportingScheduleId
        date
        recognitionAmount
        remainingAmount
        totalAmount
        syncState
        numberOfEntries
        entryNumber
      }
    }
  }
`;
export const deleteSupportingSchedule = /* GraphQL */ `
  mutation DeleteSupportingSchedule(
    $companyId: ID!
    $input: DeleteSupportingScheduleInput!
  ) {
    deleteSupportingSchedule(companyId: $companyId, input: $input) {
      supportingScheduleId
    }
  }
`;
export const updateSupportingSchedule = /* GraphQL */ `
  mutation UpdateSupportingSchedule(
    $companyId: ID!
    $input: UpdateSupportingScheduleInput!
  ) {
    updateSupportingSchedule(companyId: $companyId, input: $input) {
      id
      type
      merchantName
      merchantId
      merchantType
      description
      transactionDate
      servicePeriodStart
      servicePeriodEnd
      recognitionInterval
      totalAmount
      originatingTransactionId
      originatingTransactionLineItemId
      originatingTransactionType
      incomeOrExpenseAccountId
      incomeOrExpenseAccountName
      assetOrLiabilityAccountId
      assetOrLiabilityAccountName
      supportingScheduleEntries {
        id
        supportingScheduleId
        date
        recognitionAmount
        remainingAmount
        totalAmount
        syncState
        numberOfEntries
        entryNumber
      }
    }
  }
`;
export const syncSupportingScheduleWithQBO = /* GraphQL */ `
  mutation SyncSupportingScheduleWithQBO(
    $companyId: ID!
    $input: SyncSupportingScheduleWithQBOInput!
  ) {
    syncSupportingScheduleWithQBO(companyId: $companyId, input: $input) {
      id
      supportingScheduleId
      date
      recognitionAmount
      remainingAmount
      totalAmount
      syncState
      numberOfEntries
      entryNumber
    }
  }
`;
export const addTransactionInfoToSupportingSchedule = /* GraphQL */ `
  mutation AddTransactionInfoToSupportingSchedule(
    $companyId: ID!
    $input: TransactionInfoInput!
  ) {
    addTransactionInfoToSupportingSchedule(
      companyId: $companyId
      input: $input
    ) {
      id
      type
      merchantName
      merchantId
      merchantType
      description
      transactionDate
      servicePeriodStart
      servicePeriodEnd
      recognitionInterval
      totalAmount
      originatingTransactionId
      originatingTransactionLineItemId
      originatingTransactionType
      incomeOrExpenseAccountId
      incomeOrExpenseAccountName
      assetOrLiabilityAccountId
      assetOrLiabilityAccountName
      supportingScheduleEntries {
        id
        supportingScheduleId
        date
        recognitionAmount
        remainingAmount
        totalAmount
        syncState
        numberOfEntries
        entryNumber
      }
    }
  }
`;
