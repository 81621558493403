import React from "react";
import Classnames from "classnames";
import "./scroll-container.css";

interface IScrollContainer {
  children: React.ReactNode | React.ReactChild | React.ReactChildren;
  noPadding?: boolean;
  height?: number;
  fullHeight?: boolean;
  viewContainer?: boolean;
}

export const ScrollContainer = ({
  children,
  noPadding,
  fullHeight,
  height,
  viewContainer,
}: IScrollContainer) => {
  const classes = Classnames("scroll-container", {
    "scroll-container--full-height": fullHeight,
    "scroll-container--no-padding": noPadding,
    "view-container": viewContainer,
  });

  return (
    <div className={classes} style={height ? { maxHeight: height } : {}}>
      {children}
    </div>
  );
};
