import React, { ForwardedRef } from "react";
import Classnames from "classnames";
import "./button.css";

type TButtonSizes = "small" | "medium" | "large" | "xl";

type TButtonThemes =
  | "primary"
  | "secondary"
  | "tertiary"
  | "quaternary"
  | "danger";

type TButtonTypes = "submit" | "button";

type TIconPosition = "left" | "right";

interface IButtonProps {
  size: TButtonSizes;
  fullWidth?: boolean;
  theme: TButtonThemes;
  icon?: string;
  iconPosition?: TIconPosition;
  label?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  type?: TButtonTypes;
  noWrap?: boolean;
}

export const Button = React.forwardRef(
  (
    {
      size,
      theme,
      icon,
      label,
      disabled,
      onClick,
      fullWidth,
      className,
      type,
      noWrap,
      iconPosition = "left",
    }: IButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const buttonClasses = Classnames("button", {
      "button--sm": size === "small",
      "button--md": size === "medium",
      "button--lg": size === "large",
      "button--xl": size === "xl",
      "button--primary": theme === "primary",
      "button--secondary": theme === "secondary",
      "button--tertiary": theme === "tertiary",
      "button--quaternary": theme === "quaternary",
      "button--danger": theme === "danger",
      "button--with-icon": icon && label,
      "button--icon-only": icon && !label,
      "button--disabled": disabled,
      "button--full-width": fullWidth,
      "button--no-wrap": noWrap,
    });

    const handleClick = (e: React.MouseEvent) => {
      if (!onClick) return;
      onClick(e);
    };

    const iconLeft = !!icon && iconPosition === "left";
    const iconRight = !!icon && !iconLeft;

    return (
      <button
        type={type}
        onClick={(e: React.MouseEvent) => handleClick(e)}
        className={`${buttonClasses} ${className || null}`}
        disabled={disabled}
        ref={ref}
      >
        {iconLeft && (
          <div>
            <img src={icon} />
          </div>
        )}
        {label && <div className="w-full text-center">{label}</div>}
        {iconRight && (
          <div>
            <img src={icon} />
          </div>
        )}
      </button>
    );
  }
);
