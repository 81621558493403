import React from "react";
import { ContentBlock, ContentState } from "draft-js";

// Link strategy
// TODO: type the callback, it's probably in the Draft docs
export const linkStrategy = (
  contentBlock: ContentBlock,
  callback: any,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges((char) => {
    const entityKey: string = char.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

// The actual Link component that gets inserted.
interface ILink {
  contentState: ContentState;
  entityKey: string;
  children: React.ReactNode;
}

export const Link = ({ contentState, entityKey, children }: ILink) => {
  const { url } = contentState.getEntity(entityKey).getData();

  // ContentEditable links shouldn't be clickable by design, but we can use the onClick code to force it.
  return (
    <a
      className="rich-text__link"
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      aria-label={url}
      title={url}
      // onClick={() => window.open(url, "_blank")} // this is for debugging. see above.
    >
      {children}
    </a>
  );
};

// The plugin we send to our editor.
const addLinkPlugin = {
  decorators: [
    {
      strategy: linkStrategy,
      component: Link,
    },
  ],
};

export default addLinkPlugin;
