// regex matches space, -, _, tab, new line, and . characters
const splitSting = (string: string) =>
  string.split(/[ \-\_\t\n\r\.]/g).filter((word) => !!word);

export function capitalizeFirstLetter(string: string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convertToPascalCase(string: string) {
  if (!string) return "";
  return splitSting(string).reduce(
    (accum, word) => accum + capitalizeFirstLetter(word.toLowerCase()),
    ""
  );
}

export function convertToCamelCase(string: string) {
  if (!string) return "";
  return splitSting(string).reduce(
    (accum, word, idx) =>
      accum +
      (idx ? capitalizeFirstLetter(word.toLowerCase()) : word.toLowerCase()),
    ""
  );
}

export function convertToKebabCase(string: string) {
  if (!string) return "";
  return splitSting(string).reduce(
    (accum, word, idx) => accum + (idx ? "-" : "") + word.toLowerCase(),
    ""
  );
}

export function convertToTitleCase(string: string) {
  if (!string) return "";
  return splitSting(string).reduce(
    (accum, word, idx) =>
      accum + (idx ? " " : "") + capitalizeFirstLetter(word.toLowerCase()),
    ""
  );
}

export const stripSpecialCharacters = (companyDomain: string): string => {
  return companyDomain.replace(/[^\w\s]/gi, "");
};
