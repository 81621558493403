import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button } from "~/src/components/button";
import { Modal } from "~/src/components/modal";
import { useConnectionTitleManager } from "~/src/pages/institutions/hooks/utilities/use-connection-title-manager";
import { ICreateKlaviyoConnectionInput } from "~/src/state/institutions/institutions-api-types";
import { InstitutionId } from "~/src/types/institutions";
import { InstitutionFormErrors } from "../../../institution-form-errors";

export interface IKlaviyoInputWithTitle extends ICreateKlaviyoConnectionInput {
  title?: string;
}

interface ICreateKlaviyoConnectionFormProps {
  inputModalOpen: boolean;
  setInputModalOpen: Dispatch<SetStateAction<boolean>>;
  setKlaviyoInput: Dispatch<SetStateAction<IKlaviyoInputWithTitle | undefined>>;
  addConnectionDisabled: boolean;
}

export const CreateKlaviyoConnectionForm = ({
  inputModalOpen,
  setInputModalOpen,
  setKlaviyoInput,
  addConnectionDisabled,
}: ICreateKlaviyoConnectionFormProps): JSX.Element => {
  const firstInputRef = useRef<HTMLInputElement>(null);
  const { suggestedTitle, titleIsUnique } = useConnectionTitleManager(
    InstitutionId.KLAVIYO
  );
  const [title, setTitle] = useState<string>(suggestedTitle);
  const defaultApiKey = "";
  const [apiKey, setApiKey] = useState<string>(defaultApiKey);
  const defaultFormErrors: InstitutionFormErrors = useMemo(() => ({}), []);
  const [formErrors, setFormErrors] =
    useState<InstitutionFormErrors>(defaultFormErrors);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const validateForm = () => {
    const errors: InstitutionFormErrors = {};

    const titleErrors: string[] = [];
    if (!title) titleErrors.push("Title is required");
    if (!titleIsUnique(title))
      titleErrors.push(`Connection "${title}" already exists`);
    if (titleErrors.length) errors.title = titleErrors;

    const apiKeyErrors: string[] = [];
    if (!apiKey) apiKeyErrors.push("Private Api Key is required");
    if (apiKeyErrors.length) errors.apiKey = apiKeyErrors;

    setFormErrors(errors);

    if (Object.keys(errors).length) return false;
    return true;
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formValid = validateForm();
    if (!formValid) return;
    if (submitDisabled) return;
    setSubmitDisabled(true);
    setKlaviyoInput({ title, apiKey });
    setInputModalOpen(false);
  };

  useEffect(() => {
    if (addConnectionDisabled) setSubmitDisabled(true);
    else setSubmitDisabled(false);
  }, [addConnectionDisabled]);

  useEffect(() => {
    // reset form on open
    if (inputModalOpen) {
      setTitle(suggestedTitle);
      setApiKey(defaultApiKey);
      setFormErrors(defaultFormErrors);
    }

    // inside a modal, focus() will not work without setTimeout
    setTimeout(() => {
      if (firstInputRef.current) firstInputRef.current.focus();
    });
  }, [inputModalOpen, suggestedTitle, defaultApiKey, defaultFormErrors]);

  return (
    <Modal
      title="Add Klaviyo connection"
      isOpen={inputModalOpen}
      onClose={() => setInputModalOpen(false)}
    >
      <form className="institution-form" onSubmit={onSubmit}>
        <div>
          <label
            className="institution__label"
            htmlFor="klaviyo-connection-form__title"
          >
            Title *
          </label>
          <input
            id="klaviyo-connection-form__title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            ref={firstInputRef}
          />
        </div>
        <InstitutionFormErrors errorKey="title" errors={formErrors} />

        <div>
          <label
            className="institution__label"
            htmlFor="klaviyo-connection-form__api-key"
          >
            Private Api Key *
          </label>
          <input
            id="klaviyo-connection-form__api-key"
            type="text"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
          />
        </div>
        <InstitutionFormErrors errorKey="apiKey" errors={formErrors} />

        <div className="institution-form__buttons">
          <Button
            label="Connect"
            type="submit"
            size="medium"
            theme="primary"
            disabled={submitDisabled}
          />
        </div>
      </form>
    </Modal>
  );
};
