import jwt from "jsonwebtoken";
import * as session from "~/src/system/session";

export function encrypt(data: object, config = {}) {
  return jwt.sign(data, process.env.APP_SECRET || "", config);
}

export function decrypt(token: string): Object {
  return jwt.verify(token, process.env.APP_SECRET || "");
}

export function authenticated(): boolean {
  const currentSession = session.get();
  return session.validate(currentSession);
}
