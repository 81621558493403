import React from "react";
import "./view-empty.css";

interface IViewEmpty {
  message: string;
  image?: string;
  imageAlt?: string;
}

export const ViewEmpty = ({ message, image, imageAlt }: IViewEmpty) => {
  return (
    <div className="view-empty">
      {image ? <img src={image} alt={imageAlt} /> : null}
      <p>{message}</p>
    </div>
  );
};
