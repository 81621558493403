import { Icons } from "~/src/assets/icons";

export type TRichLabel =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "Blockquote"
  | "Unordered List"
  | "Ordered List"
  | "Code Block"
  | "Bold"
  | "Italic"
  | "Underline"
  | "Strikethrough"
  | "Monospace"
  | "Link"
  | "UnLink"
  | "Attachment";

export type TRichStyle =
  | "none"
  | "header-one"
  | "header-two"
  | "header-three"
  | "header-four"
  | "header-five"
  | "header-six"
  | "blockquote"
  | "unordered-list-item"
  | "ordered-list-item"
  | "code-block"
  | "BOLD"
  | "ITALIC"
  | "UNDERLINE"
  | "STRIKETHROUGH"
  | "CODE"
  | "LINK";

export interface IRichStyle {
  label: TRichLabel;
  style: TRichStyle;
  icon: string;
}

// Commenting out styles unused in the comps for now.
// TODO: Icons for other styles
export const BLOCK_STYLES: IRichStyle[] = [
  // {label: 'h1', style: 'header-one', icon: ''},
  // {label: 'h2', style: 'header-two', icon: ''},
  // {label: 'h3', style: 'header-three', icon: ''},
  // {label: 'h4', style: 'header-four', icon: ''},
  // {label: 'h5', style: 'header-five', icon: ''},
  // {label: 'h6', style: 'header-six', icon: ''},
  {
    label: "Unordered List",
    style: "unordered-list-item",
    icon: Icons.default.UnorderedList,
  },
  {
    label: "Ordered List",
    style: "ordered-list-item",
    icon: Icons.default.OrderedList,
  },
  { label: "Blockquote", style: "blockquote", icon: Icons.default.Quote },
  // {label: 'Code Block', style: 'code-block', icon: ''},
];

export const INLINE_STYLES: IRichStyle[] = [
  { label: "Bold", style: "BOLD", icon: Icons.default.Bold },
  { label: "Italic", style: "ITALIC", icon: Icons.default.Italic },
  { label: "Underline", style: "UNDERLINE", icon: Icons.default.Underline },
  {
    label: "Strikethrough",
    style: "STRIKETHROUGH",
    icon: Icons.default.Strike,
  },
  // {label: 'Monospace', style: 'CODE', icon: ''},
];
