import Classnames from "classnames";
import React from "react";
import { HashRouter as Router } from "react-router-dom";
import { Icons } from "~/src/assets/icons";
import ROUTES from "~/src/constants/routes";
import { toggle } from "~/src/state/sidebar/sidebar-slice";
import { useAppDispatch, useAppSelector } from "~/src/system/store/hooks";
import { AppLogo } from "../app-logo";
import { SideNavButton, SideNavItem } from "./components/side-nav-item";
import "./sidebar.css";

interface ISidebar {
  hidden: boolean;
  activeCompanyId: string | undefined;
}

export const Sidebar = ({ hidden, activeCompanyId }: ISidebar) => {
  const dispatch = useAppDispatch();
  const collapsed = useAppSelector((state) => state.sidebar.collapsed);

  const sidebarClasses = Classnames("flowfi-sidebar", {
    "flowfi-sidebar--collapsed": collapsed,
  });

  const toggleSidebar = () => {
    dispatch(toggle());
  };

  const goToDashboard = () => {
    if (!process.env.DASHBOARD_URL) return;

    window.open(
      `${process.env.DASHBOARD_URL}/#/select-company/${activeCompanyId}`,
      "_blank"
    );
  };

  return (
    <>
      {!hidden && (
        <aside className={sidebarClasses}>
          <AppLogo collapsed={collapsed} />
          <nav className="h-full">
            <Router>
              <ul className="flex flex-col h-full">
                <SideNavItem
                  path={`${ROUTES.companies.path}/${activeCompanyId}`}
                  label="Home"
                  icon={Icons.default.Home}
                  activeIcon={Icons.green.Home}
                  collapsed={collapsed}
                />
                <SideNavItem
                  path={ROUTES.accountTags.path}
                  label="CoA Mapping"
                  icon={Icons.default.Label}
                  activeIcon={Icons.green.Label}
                  collapsed={collapsed}
                />
                <SideNavItem
                  path={ROUTES.actionItems.path}
                  label="Action Items"
                  icon={Icons.default.Navigation}
                  activeIcon={Icons.green.Navigation}
                  collapsed={collapsed}
                />
                {/* <SideNavItem
                  path={ROUTES.supportingSchedules.path}
                  label="Accruals"
                  icon={Icons.default.Navigation}
                  activeIcon={Icons.green.Navigation}
                  collapsed={collapsed}
                /> */}
                <SideNavItem
                  path={ROUTES.institutions.path}
                  label="Institutions"
                  icon={Icons.default.Bank}
                  activeIcon={Icons.green.Bank}
                  collapsed={collapsed}
                />
                <SideNavItem
                  path={ROUTES.categorization.path}
                  label="Categorization"
                  icon={Icons.default.Book}
                  activeIcon={Icons.green.Book}
                  collapsed={collapsed}
                />
                <SideNavItem
                  path={ROUTES.rulesVault.path}
                  label="Rules Vault"
                  icon={Icons.default.Vault}
                  activeIcon={Icons.green.Vault}
                  collapsed={collapsed}
                />
                {process.env.DASHBOARD_URL && activeCompanyId && (
                  <SideNavButton
                    className="mt-auto"
                    onClick={goToDashboard}
                    label="Customer Dashboard"
                    icon={Icons.default.Dashboard}
                    collapsed={collapsed}
                  />
                )}
                <SideNavButton
                  label="Toggle Sidebar"
                  onClick={toggleSidebar}
                  icon={Icons.default.Object}
                  collapsed={collapsed}
                />
              </ul>
            </Router>
          </nav>
        </aside>
      )}
    </>
  );
};
