import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { CompanyIcon } from "~/src/components/company-icon";
import { BackButton } from "~/src/components/back-button";
import { Pane } from "~/src/components/pane";
import { Button } from "~/src/components/button";
import { Icons } from "~/src/assets/icons";
import { FlowFiDatePicker } from "~/src/components/datepicker";
import { Toast } from "~/src/components/toast";
import { toast } from "react-toastify";
import { useUpdateCompanyProfileMutation } from "~/src/state/companies/companies-api-slice";
import { useAppSelector } from "~/src/system/store/hooks";
import {
  CompanyTypes,
  TCompanyType,
  ICompanyProfileUpdateInput,
} from "~/src/types/company";
import "./company-profile.css";
import ROUTES from "~/src/constants/routes";
import { ICompanyRouteParams } from "~/src/types/routes";
import { stripSpecialCharacters } from "~/src/utilities/strings";

export const CompanyProfile = () => {
  const { id } = useParams<ICompanyRouteParams>();
  const history = useHistory();
  const companyData = useAppSelector(
    (state) => state.companiesData.customerCompanies
  );

  const [name, setName] = useState<string>();
  const [type, setType] = useState<TCompanyType | undefined>(undefined);
  const [primaryPoc, setPrimaryPoc] = useState<string | undefined>(undefined);
  const [activity, setActivity] = useState<string | undefined>(undefined);
  const [revenueModel, setRevenueModel] = useState<string | undefined>(
    undefined
  );
  const [fiscalStart, setFiscalStart] = useState<string | Date | undefined>(
    undefined
  );
  const [booksStart, setBooksStart] = useState<string | Date | undefined>(
    undefined
  );

  const [
    updateCompanyProfile,
    {
      isLoading: profileUpdating,
      isSuccess: profileSuccess,
      isError: profileError,
    },
  ] = useUpdateCompanyProfileMutation();

  // Populate state
  useEffect(() => {
    setName(companyData?.active?.name);
    setType(companyData?.active?.type);
    setPrimaryPoc(companyData?.active?.primary_poc);
    setActivity(companyData?.active?.primary_business_activity);
    setRevenueModel(companyData?.active?.revenue_model);
    setFiscalStart(companyData?.active?.fiscal_start);
    setBooksStart(companyData?.active?.books_start);
  }, [companyData]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (!companyData.active) return;

      const input: ICompanyProfileUpdateInput = {
        id: companyData.active.id,
        name: name || "",
        type,
        primary_poc: primaryPoc,
        primary_business_activity: activity,
        revenue_model: revenueModel,
        fiscal_start: fiscalStart,
        books_start: booksStart,
      };

      updateCompanyProfile(input);
    } catch (e) {
      console.error(e);
    }
  };

  // Display toasts for update operations.
  useEffect(() => {
    if (profileSuccess) {
      toast(<Toast message="Profile Updated" icon={Icons.green.Info} />);
      history.push(`${ROUTES.companies.path}/${id}`);
    } else if (profileError) {
      toast(
        <Toast
          message="There was a problem when editing this profile."
          icon={Icons.green.Info}
        />
      );
    }
  }, [profileSuccess, profileError]);

  return (
    <>
      <BackButton
        onClick={() => history.push(`${ROUTES.companies.path}/${id}`)}
      />

      {companyData && companyData.active && (
        <>
          <div className="company-profile">
            <div>
              <Pane>
                <div className="company-profile__meta">
                  <div className="company-profile__logo">
                    <CompanyIcon company={companyData.active} size="2xl" />
                    <h3 className="company-profile__name">
                      {companyData.active.name}
                    </h3>
                  </div>
                </div>
              </Pane>
            </div>

            <div>
              <Pane>
                <div className="company-profile__details"></div>
                <form className="company-profile__form" onSubmit={handleSubmit}>
                  <h2>Profile Information</h2>
                  <div className="company-profile__form-row">
                    <div>
                      <label htmlFor="company-name">Company Name</label>
                      <input
                        id="company-name"
                        type="text"
                        value={name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setName(e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <label htmlFor="unique-id">Unique ID</label>
                      <input
                        id="unique-id"
                        type="text"
                        value={stripSpecialCharacters(
                          companyData.active.domain
                        )}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="company-profile__form-row">
                    <div>
                      <label htmlFor="primary-poc">Primary POC</label>
                      <input
                        id="primary-poc"
                        type="text"
                        value={primaryPoc}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setPrimaryPoc(e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <label>Primary Business Activity</label>
                      <input
                        type="text"
                        value={activity}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setActivity(e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="company-profile__form-row">
                    <div>
                      <label>Revenue Model</label>
                      <input
                        type="text"
                        value={revenueModel}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setRevenueModel(e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <label>Entity Type</label>
                      <select
                        className="w-full"
                        value={type}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                          setType(e.target.value as TCompanyType)
                        }
                      >
                        <option>Select</option>
                        {CompanyTypes.map((t) => (
                          <option key={t} value={t}>
                            {t}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="company-profile__form-row">
                    <div>
                      <label>First Month of Books</label>
                      <FlowFiDatePicker
                        placement="bottom-start"
                        selected={
                          booksStart && typeof booksStart === "string"
                            ? new Date(booksStart)
                            : undefined
                        }
                        onChange={(date) => setBooksStart(date.toISOString())}
                      />
                    </div>
                    <div>
                      <label>Fiscal Start</label>
                      <FlowFiDatePicker
                        placement="bottom-start"
                        selected={
                          fiscalStart && typeof fiscalStart === "string"
                            ? new Date(fiscalStart)
                            : undefined
                        }
                        onChange={(date) => setFiscalStart(date.toISOString())}
                      />
                    </div>
                  </div>

                  <div className="company-profile__form-buttons">
                    <Button
                      size="large"
                      label="Cancel"
                      theme="secondary"
                      type="button"
                      onClick={() =>
                        history.push(`${ROUTES.companies.path}/${id}`)
                      }
                    />
                    <Button
                      size="large"
                      label="Save Changes"
                      theme="primary"
                      type="submit"
                      disabled={profileUpdating}
                    />
                  </div>
                </form>
              </Pane>
            </div>
          </div>
        </>
      )}
    </>
  );
};
