import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ICategorizationWorkflow,
  ICategorizedLineItem,
  IMerchant,
  IUncategorizedLineItem,
} from "~/src/types/transactions";

interface ICategorizationWorkflowDateRange {
  start: number;
  end: number;
}

interface ISaveCategorizationWorkflowPayload {
  categorizationWorkflow: ICategorizationWorkflow;
  dateRange?: ICategorizationWorkflowDateRange;
}

interface IAccountsState {
  categorizationWorkflow: ICategorizationWorkflow;
  categorizationWorkflowDateRange: ICategorizationWorkflowDateRange;
  merchants: IMerchant[];
}

const initialCategorizationWorkflow = {
  uncategorizedLineItems: [],
  categorizedLineItems: [],
};
const initialState: IAccountsState = {
  categorizationWorkflow: initialCategorizationWorkflow,
  categorizationWorkflowDateRange: {
    start: 0, // unix (ms)
    end: 0, // unix (ms)
  },
  merchants: [],
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    saveCategorizationWorkflow(
      state,
      action: PayloadAction<ISaveCategorizationWorkflowPayload>
    ) {
      state.categorizationWorkflow = action.payload.categorizationWorkflow;
      if (action.payload.dateRange)
        state.categorizationWorkflowDateRange = action.payload.dateRange;
    },
    categorizeLineItems(state, action: PayloadAction<ICategorizedLineItem[]>) {
      type TNewlyCategorized = {
        [key: string]: {
          [key: string]: ICategorizedLineItem;
        };
      };
      const newlyCategorized: TNewlyCategorized = {};
      action.payload.forEach((lineItem) => {
        if (!newlyCategorized[lineItem.externalTransactionId]) {
          Object.assign(newlyCategorized, {
            [lineItem.externalTransactionId]: {},
          });
        }
        Object.assign(newlyCategorized[lineItem.externalTransactionId], {
          ...newlyCategorized[lineItem.externalTransactionId],
          [lineItem.lineItemId]: lineItem,
        });
      });

      const updatedCategorizationWorkflow = { ...state.categorizationWorkflow };
      const updatedUncategorizedLineItems: IUncategorizedLineItem[] = [];
      updatedCategorizationWorkflow.uncategorizedLineItems.forEach(
        (lineItem) => {
          const newlyCategorizedLineItems =
            newlyCategorized[lineItem.externalTransactionId];

          if (
            // if this line item was categorized: return
            newlyCategorizedLineItems &&
            newlyCategorizedLineItems[lineItem.lineItemId]
          ) {
            return;
          } else {
            if (newlyCategorizedLineItems) {
              const newSyncToken = Object.values(newlyCategorizedLineItems)[0]
                .transactionSyncToken;
              lineItem.transactionSyncToken = newSyncToken;
            }
            updatedUncategorizedLineItems.push(lineItem);
          }
        }
      );
      updatedCategorizationWorkflow.uncategorizedLineItems =
        updatedUncategorizedLineItems;
      state.categorizationWorkflow = updatedCategorizationWorkflow;
    },
    saveMerchants(state, action: PayloadAction<IMerchant[]>) {
      state.merchants = action.payload;
    },
    removeMerchantCategorizationSuggestion(
      state,
      action: PayloadAction<string>
    ) {
      const updatedMerchants = state.merchants;
      const updatedIdx = updatedMerchants
        .map((merchant) => merchant.externalId)
        .indexOf(action.payload);

      delete updatedMerchants[updatedIdx].categorizationSuggestion;
      state.merchants = updatedMerchants;
    },
  },
});

export const {
  saveCategorizationWorkflow,
  categorizeLineItems,
  saveMerchants,
  removeMerchantCategorizationSuggestion,
} = transactionsSlice.actions;
export default transactionsSlice.reducer;
