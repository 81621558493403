import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button } from "~/src/components/button";
import { Modal } from "~/src/components/modal";
import { IUpdateConnectionInput } from "~/src/state/institutions/institutions-api-types";
import {
  isConnectionWithRedactedApiKey,
  IConnection,
} from "~/src/types/institutions";
import { useConnectionTitleManager } from "../../hooks/utilities/use-connection-title-manager";
import { formatRedactedApiKey } from "../connections-table/connections-table-row";
import { InstitutionFormErrors } from "../institution-form-errors";

interface IConnectionEditFormProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  updateConnectionMutation: (input: IUpdateConnectionInput) => void;
  connection: IConnection;
}

export const ConnectionEditForm = ({
  modalOpen,
  setModalOpen,
  updateConnectionMutation,
  connection,
}: IConnectionEditFormProps): JSX.Element => {
  const { titleIsUnique } = useConnectionTitleManager(connection.institutionId);
  const originalTitle = connection?.title;
  const [newTitle, setNewTitle] = useState(originalTitle);
  const originalApiKey =
    !!connection && isConnectionWithRedactedApiKey(connection)
      ? connection.perInstitutionValues.redactedApiKey
      : undefined;
  const [newApiKey, setNewApiKey] = useState("");
  const defaultFormErrors: InstitutionFormErrors = useMemo(() => ({}), []);
  const [formErrors, setFormErrors] =
    useState<InstitutionFormErrors>(defaultFormErrors);

  const validateForm = () => {
    const errors: InstitutionFormErrors = {};

    const titleErrors: string[] = [];
    if (!titleIsUnique(newTitle))
      titleErrors.push(`Connection "${newTitle}" already exists`);
    if (titleErrors.length) errors.title = titleErrors;

    const generalErrors: string[] = [];
    if ([!newTitle, !newApiKey].every((criteria) => !!criteria))
      generalErrors.push(
        `At least one updated value is required to edit connection`
      );
    if (generalErrors.length) errors.general = generalErrors;

    setFormErrors(errors);

    if (Object.keys(errors).length) return false;
    return true;
  };

  const submitDisabled = [newTitle === originalTitle].some(
    (criteria) => !!criteria
  );

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const formValid = validateForm();
    if (!formValid) return;
    if (submitDisabled) return;

    updateConnectionMutation({
      companyId: connection.companyId,
      institutionId: connection.institutionId,
      connectionId: connection.id,
      title: newTitle || undefined,
      apiKey: newApiKey || undefined,
    });

    setModalOpen(false);
  };

  const resetForm = useCallback(() => {
    setNewTitle(originalTitle);
    setNewApiKey("");
    setFormErrors(defaultFormErrors);
  }, [setNewTitle, originalTitle, setNewApiKey, defaultFormErrors]);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    // reset and focus the form on open
    if (modalOpen) {
      resetForm();
    }
    // inside a modal, focus() will not work without setTimeout
    setTimeout(() => {
      if (formRef.current)
        (formRef.current.children[0]?.children[1] as HTMLInputElement)?.focus();
    });
  }, [modalOpen, resetForm]);

  return (
    <Modal
      title="Edit connection"
      isOpen={modalOpen}
      onClose={() => setModalOpen(!modalOpen)}
    >
      <form className="institution-form" onSubmit={onSubmit} ref={formRef}>
        <InstitutionFormErrors errorKey="general" errors={formErrors} />

        <div>
          <label
            className="institution__label"
            htmlFor="connection-edit-form__title-input"
          >
            Title
          </label>
          <input
            id="connection-edit-form__title-input"
            className="connection-edit-form__text-input"
            type="text"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
        </div>
        <InstitutionFormErrors errorKey="title" errors={formErrors} />

        {!!originalApiKey && (
          <div>
            <label
              className="institution__label"
              htmlFor="connection-edit-form__api-key-input"
            >
              Api Key
            </label>
            <input
              id="connection-edit-form__api-key-input"
              className="connection-edit-form__text-input"
              type="text"
              value={newApiKey}
              onChange={(e) => setNewApiKey(e.target.value)}
              placeholder={formatRedactedApiKey(originalApiKey)}
            />
          </div>
        )}
        <InstitutionFormErrors errorKey="apiKey" errors={formErrors} />

        <div className="institution-form__buttons">
          <Button
            label="Save Changes"
            theme="primary"
            size="medium"
            type="submit"
            disabled={submitDisabled}
          />
        </div>
      </form>
    </Modal>
  );
};
