import React, { useEffect, useState } from "react";
import moment from "moment";

import { Button } from "~/src/components/button";
import { useAppSelector } from "~/src/system/store/hooks";
import { IUncategorizedLineItem } from "~/src/types/transactions";
import { RuleUpsertForm } from "~/src/pages/rules-vault/components/rule-upsert-form";
import { Drawer } from "~/src/components/drawer";
import { formatDisplayDate } from "~/src/utilities/dates";
import { getAccountName } from "~/src/utilities/accounts";
import { formatCurrency } from "~/src/utilities/numbers";
import { TRANSACTION_TYPE_DISPLAY_MAP } from "~/src/constants/transactions";

import { ManuallyCategorizeForm } from "../manually-categorize-form";
import "./uncategorized-line-item-row.css";

interface IUncategorizedLineItemRowProps {
  lineItem: IUncategorizedLineItem;
  openDrawer: { id: string };
  setOpenDrawer: React.Dispatch<
    React.SetStateAction<{
      id: string;
    }>
  >;
}

export const UncategorizedLineItemRow: React.FunctionComponent<
  IUncategorizedLineItemRowProps
> = ({ lineItem, openDrawer, setOpenDrawer }) => {
  // state
  const companyId = useAppSelector(
    (state) => state.companiesData.customerCompanies.active?.id || ""
  );
  const [ruleDrawerOpen, setRuleDrawerOpen] = useState<boolean>(false);
  const [catDrawerOpen, setCatDrawerOpen] = useState<boolean>(false);
  const ruleOpenDrawerId = `${lineItem.externalTransactionId}-${lineItem.lineItemId}-rule`;
  const catOpenDrawerId = `${lineItem.externalTransactionId}-${lineItem.lineItemId}-cat`;
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  // manage drawers state
  const handleOpenRuleDrawer = () => {
    setOpenDrawer({ id: ruleOpenDrawerId });
  };
  const handleCloseRuleDrawer = () => {
    setRuleDrawerOpen(false);
  };
  const handleOpenCatDrawer = () => {
    setOpenDrawer({ id: catOpenDrawerId });
  };
  const handleCloseCatDrawer = () => {
    setCatDrawerOpen(false);
  };

  /**
   * This listens for the id of the drawer that should be open...
   * - if it matches a drawer in this row, set that drawer open.
   * - if it doesn't open any drawers on the first render cycle. If it did,
   *   it would open again if you search/page then cancel or page back into view.
   * This ensures the only drawer that "exists" is the one last requested by the user,
   * drastically improving performance for searching/paging/sorting.
   * `openDrawer` is an object so it is recognized as updated state when
   * the same drawer is closed then opened again.
   */
  useEffect(() => {
    if (!isFirstRender) {
      openDrawer.id === ruleOpenDrawerId && setRuleDrawerOpen(true);
      openDrawer.id === catOpenDrawerId && setCatDrawerOpen(true);
    } else setIsFirstRender(false);
  }, [openDrawer]);

  return (
    <tr>
      <td>
        <strong>{formatDisplayDate(moment(lineItem.date))}</strong>
      </td>
      <td>
        <p>{TRANSACTION_TYPE_DISPLAY_MAP[lineItem.type]}</p>
      </td>
      <td>
        <span>{lineItem.memo}</span>
      </td>
      <td>
        <span>{getAccountName(lineItem.sourceAccountInfo)}</span>
      </td>
      <td className="text-right">
        {/* TODO: currency should be localized */}
        <strong>{formatCurrency(lineItem.amount)}</strong>
      </td>
      <td>
        <div className="uncategorized-line-item-row__buttons">
          <Button
            label="Create a Rule"
            theme="primary"
            size="medium"
            onClick={handleOpenRuleDrawer}
            noWrap
          />
          <Button
            label="Manually Categorize"
            theme="secondary"
            size="medium"
            onClick={handleOpenCatDrawer}
            noWrap
          />
        </div>

        {openDrawer.id === ruleOpenDrawerId && (
          <Drawer
            isOpen={ruleDrawerOpen}
            onClose={handleCloseRuleDrawer}
            placement="right"
          >
            <RuleUpsertForm
              companyId={companyId}
              lineItem={lineItem}
              onClose={handleCloseRuleDrawer}
            />
          </Drawer>
        )}
        {openDrawer.id === catOpenDrawerId && (
          <Drawer
            isOpen={catDrawerOpen}
            onClose={handleCloseCatDrawer}
            placement="right"
          >
            <ManuallyCategorizeForm
              lineItem={lineItem}
              onClose={handleCloseCatDrawer}
            />
          </Drawer>
        )}
      </td>
    </tr>
  );
};
