import React from "react";
import { Icons } from "~/src/assets/icons";
import Classnames from "classnames";
import "./drawer.css";

type TDrawerPlacement = "left" | "right";

interface IDrawer {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  placement: TDrawerPlacement;
  contentFullHeight?: boolean;
}

export const Drawer = ({
  isOpen,
  onClose,
  children,
  placement,
  contentFullHeight,
}: IDrawer) => {
  const overlayClasses = Classnames("drawer-overlay", {
    "drawer-overlay--visible": isOpen,
  });
  const drawerClasses = Classnames("drawer", {
    [`drawer--${placement}`]: placement,
    "drawer--open": isOpen,
  });
  const contentClasses = Classnames("drawer__content", {
    "drawer__content--full-height": contentFullHeight,
  });

  return (
    <>
      <div className={overlayClasses} onClick={() => onClose()}></div>
      <div className={drawerClasses}>
        <button className="drawer__close" onClick={() => onClose()}>
          <img src={Icons.default.CloseSm} alt="close icon" />
        </button>
        <div className={contentClasses}>{children}</div>
      </div>
    </>
  );
};
