import { DefaultIcons } from "./default";
import { GreenIcons } from "./green";
import { WhiteIcons } from "./white";
import { RedIcons } from "./red";
import { YellowIcons } from "./yellow";

export type TIconTheme = "default" | "green";
export type TIcon = string;

export const Icons = {
  default: DefaultIcons,
  green: GreenIcons,
  white: WhiteIcons,
  red: RedIcons,
  yellow: YellowIcons,
};
