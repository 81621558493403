import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button } from "~/src/components/button";
import { Modal } from "~/src/components/modal";
import "./initiate-shopify-connection-form.css";

interface IInitiateShopifyConnectionFormProps {
  inputModalOpen: boolean;
  setInputModalOpen: Dispatch<SetStateAction<boolean>>;
  setShopName: Dispatch<SetStateAction<string | undefined>>;
}

export const InitiateShopifyConnectionForm = ({
  inputModalOpen,
  setInputModalOpen,
  setShopName,
}: IInitiateShopifyConnectionFormProps): JSX.Element => {
  const [shopNameInput, setShopNameInput] = useState("");
  const submitDisabled = !shopNameInput;
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShopName(shopNameInput);
    setInputModalOpen(false);
  };
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputModalOpen) {
      setShopNameInput("");
    }
    // inside a modal, focus() will not work without setTimeout
    setTimeout(() => {
      if (inputRef.current) inputRef.current.focus();
    });
  }, [inputModalOpen]);

  return (
    <Modal
      title="Add Shopify connection"
      isOpen={inputModalOpen}
      onClose={() => setInputModalOpen(false)}
    >
      <form className="institution-form" onSubmit={onSubmit}>
        <div>
          <label
            className="institution__label"
            htmlFor="shopify-connection-form__shop-name"
          >
            Shop Name
          </label>
          <div className="shopify-connection-form__shop-name-wrapper">
            <span>https://</span>
            <input
              id="shopify-connection-form__shop-name"
              type="text"
              value={shopNameInput}
              onChange={(e) => setShopNameInput(e.target.value)}
              ref={inputRef}
            />
            <span>.myshopify.com</span>
          </div>
        </div>
        <div className="institution-form__buttons">
          <Button
            label="Connect"
            type="submit"
            size="medium"
            theme="primary"
            disabled={submitDisabled}
          />
        </div>
      </form>
    </Modal>
  );
};
