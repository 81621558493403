import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { SVGHandler } from "~/src/components/svg-handler";
import "./nav-item.css";

interface ISideNavItem {
  path: string;
  label: string;
  collapsed: boolean;
  icon: string;
  activeIcon: string;
  className?: string;
}

export const SideNavItem = ({
  path,
  label,
  collapsed,
  icon,
  activeIcon,
  className,
}: ISideNavItem) => {
  const location = useLocation();

  return (
    <li className={className || ""}>
      <NavLink
        to={path}
        className="side-nav-item"
        activeClassName="side-nav-item--active"
      >
        {icon && (
          <div className="side-nav-item__icon">
            <SVGHandler
              width={26}
              height={26}
              image={location.pathname === path ? activeIcon : icon}
            />
          </div>
        )}
        {!collapsed && <div className="side-nav-item__label">{label}</div>}
      </NavLink>
    </li>
  );
};

interface ISideNavButton {
  onClick: Function;
  label: string;
  collapsed: boolean;
  icon: string;
  className?: string;
}

export const SideNavButton = ({
  onClick,
  label,
  collapsed,
  icon,
  className,
}: ISideNavButton) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!onClick) return;
    onClick(e);
  };

  return (
    <li className={className || ""}>
      <button
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClick(e)}
        className="side-nav-item"
      >
        {icon && (
          <div className="side-nav-item__icon">
            <SVGHandler width={26} height={26} image={icon} />
          </div>
        )}
        {!collapsed && <div className="side-nav-item__label">{label}</div>}
      </button>
    </li>
  );
};
