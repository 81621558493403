import React from "react";
import Classnames from "classnames";
import moment from "moment";

import {
  ICategorizedLineItem,
  IUncategorizedLineItem,
} from "~/src/types/transactions";
import { getAccountName } from "~/src/utilities/accounts";
import { formatDisplayDate } from "~/src/utilities/dates";

import "./line-item-summary.css";
import { formatCurrency } from "~/src/utilities/numbers";

interface ILineItemSummaryProps {
  lineItem: ICategorizedLineItem | IUncategorizedLineItem;
  className?: string;
}

export const LineItemSummary: React.FunctionComponent<
  ILineItemSummaryProps
> = ({ lineItem, className }) => {
  return (
    <div className={Classnames("line-item-summary", className || "")}>
      <dl>
        <dt>Date:</dt>
        <dd>{formatDisplayDate(moment(lineItem.date))}</dd>
        <dt>Type:</dt>
        <dd>{lineItem.type}</dd>
        <dt>Source Account:</dt>
        <dd>{getAccountName(lineItem.sourceAccountInfo)}</dd>
        {/* TODO: currency should be localized */}
        <dt>Amount:</dt>
        <dd>{formatCurrency(lineItem.amount)}</dd>
        <dt>Memo:</dt>
        <dd>{lineItem.memo}</dd>
      </dl>
    </div>
  );
};
