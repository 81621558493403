import React from "react";
import { Icons } from "~/src/assets/icons";
import { ScrollContainer } from "../scroll-container";
import ReactModal from "react-modal";
import classNames from "classnames";
import "./modal.css";

interface IModalProps {
  isOpen: boolean;
  onClose: Function;
  children: React.ReactNode | React.ReactChild | React.ReactChildren;
  title?: string;
  className?: string;
  contentLabel?: string;
}

/**
 * Modal library init
 */
ReactModal.setAppElement("#root");

/**
 * The modal operates via inline styles, so we create an object for the overlay
 * and content styles, then assign them to the defaults.
 */
export const ModalOverlayStyles = {
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const ModalContentStyles = {
  position: "static",
  top: "auto",
  left: "auto",
  right: "auto",
  bottom: "auto",
  border: "0",
  background: "transparent",
  minWidth: "250px",
  maxWidth: "50vw",
  maxHeight: "75vh",
  overflow: "visible",
  borderRadius: "2rem",
  outline: "none",
  padding: "0",
};

Object.assign(ReactModal.defaultStyles.overlay, ModalOverlayStyles);
Object.assign(ReactModal.defaultStyles.content, ModalContentStyles);

export const Modal = ({
  isOpen = false,
  onClose,
  children,
  title = "",
  contentLabel = "",
  className,
}: IModalProps) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      contentLabel={contentLabel}
      closeTimeoutMS={200}
    >
      <div className={classNames("modal__wrapper", className ?? "")}>
        <header className="modal__header">
          {title && <h2>{title}</h2>}
          <button className="modal__close" onClick={() => onClose()}>
            <img src={Icons.default.Remove} alt={"close the modal" || "icon"} />
          </button>
        </header>
        <ScrollContainer>
          <div className="modal__body">{children}</div>
        </ScrollContainer>
        <footer className="modal__footer"></footer>
      </div>
    </ReactModal>
  );
};
