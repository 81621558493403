import { accountSchema, tagSchema } from "../accounts/accounts-api-schema";
import { merchantSchema } from "../transactions/transactions-api-schema";

const conditionSchema = `
  argument
  operator
`;

const stopWordConditionSchema = `
  argument
`;

const sourceAccountConditionSchema = `
  account { ${accountSchema} }
`;

const postingTypeConditionSchema = `
  postingType
`;

export const ruleConditionsSchema = `
  postingTypeCondition { ${postingTypeConditionSchema} }
  memoConditions { ${conditionSchema} }
  memoStopWordConditions { ${stopWordConditionSchema} }
  amountConditions { ${conditionSchema} }
  sourceAccountCondition { ${sourceAccountConditionSchema} }
`;
