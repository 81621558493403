import React, { useEffect, useState } from "react";
import { ICompany } from "~/src/types/company";
import Classnames from "classnames";
import "./company-icon.css";

type CompanyIconSizes = "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

interface ICompanyIcon {
  size: CompanyIconSizes;
  company: ICompany;
  className?: string;
}

const getInitials = (company: ICompany) => {
  const initials: string[] = [];

  company.name.split(" ").map((word) => {
    initials.push(word.charAt(0));
  });

  return initials.slice(0, 2).join("");
};

// TODO: Images need a bit more love/styling
export const CompanyIcon = ({ company, size, className }: ICompanyIcon) => {
  const [companyInitials, setCompanyInitials] = useState<string>("");

  useEffect(() => {
    setCompanyInitials(getInitials(company));
  }, [company]);

  const classes = Classnames("company-icon", {
    "company-icon--xs": size === "xs",
    "company-icon--sm": size === "sm",
    "company-icon--md": size === "md",
    "company-icon--lg": size === "lg",
    "company-icon--xl": size === "xl",
    "company-icon--2xl": size === "2xl",
  });

  return (
    <div className={`${classes} ${className ? className : null}`}>
      {company?.avatar ? (
        <img src={company.avatar} alt="company avatar" />
      ) : (
        <span>{companyInitials}</span>
      )}
    </div>
  );
};
