import React, { useEffect } from "react";
import { useCreateConnectionMutation } from "~/src/state/institutions/institutions-api-slice";
import { toast } from "react-toastify";
import { Toast } from "~/src/components/toast";
import { Icons } from "~/src/assets/icons";
import { useDispatch } from "react-redux";
import { addConnection } from "~/src/state/institutions/institutions-data-slice";
import { InstitutionId } from "~/src/types/institutions";
import {
  ICreateKlaviyoConnectionInput,
  ICreatePlaidConnectionInput,
  ICreateQuickbooksConnectionInput,
  ICreateShopifyConnectionInput,
} from "~/src/state/institutions/institutions-api-types";

export interface ICreateConnectionInput {
  companyId: string;
  institutionId: InstitutionId;
  title?: string;
  klaviyoInput?: ICreateKlaviyoConnectionInput;
  plaidInput?: ICreatePlaidConnectionInput;
  quickbooksInput?: ICreateQuickbooksConnectionInput;
  shopifyInput?: ICreateShopifyConnectionInput;
}

export const createConnectionErrorToast = () => {
  toast(
    <Toast
      message="There was a problem creating the connection. Please try again."
      icon={Icons.green.Info}
    />
  );
};

export const useCreateConnection = () => {
  const dispatch = useDispatch();
  const [createConnectionMutation, createConnectionStatus] =
    useCreateConnectionMutation();

  const { isLoading, isError, isSuccess, data } = createConnectionStatus;

  // when the query is in flight, show loading toast, otherwise dismiss it
  useEffect(() => {
    if (isLoading) {
      toast(
        <Toast
          message="Creating new connection..."
          icon={Icons.green.LoadingAnimated}
        />,
        { toastId: "createConnection", autoClose: false }
      );
    } else toast.dismiss("createConnection");
  }, [isLoading]);

  // if the query is successful and returns data, show success toast and update store
  useEffect(() => {
    if (isSuccess && !!data.data) {
      toast(
        <Toast
          message="Connection was successfully created!"
          icon={Icons.green.Info}
        />
      );
      dispatch(addConnection(data.data));
    }
  }, [isSuccess, data, dispatch]);

  // if the query errors or returns an error, show error toast
  useEffect(() => {
    if (isError || !!data?.errors) {
      createConnectionErrorToast();
    }
  }, [isError, data, dispatch]);

  return { createConnection: createConnectionMutation, createConnectionStatus };
};
