import Alert from "./alert.svg";
import ArrowDownLg from "./arrow-down-lg.svg";
import ArrowDownSm from "./arrow-down-sm.svg";
import ArrowLeftLg from "./arrow-left-lg.svg";
import ArrowLeftSm from "./arrow-left-sm.svg";
import ArrowRightLg from "./arrow-right-lg.svg";
import ArrowRightSm from "./arrow-right-sm.svg";
import ArrowUpLg from "./arrow-up-lg.svg";
import ArrowUpSm from "./arrow-up-sm.svg";
import Attachment from "./attachment.svg";
import BackArrow from "./back-arrow.svg";
import Bank from "./bank.svg";
import Bold from "./bold.svg";
import Book from "./book.svg";
import Calendar from "./calendar.svg";
import Camera from "./camera.svg";
import Chat from "./chat.svg";
import CheckCircled from "./check-circled.svg";
import CheckCircledFill from "./check-circled-fill.svg";
import CheckSm from "./check-sm.svg";
import Check from "./check.svg";
import Clock from "./clock.svg";
import CloseSm from "./close-sm.svg";
import Coins from "./coins.svg";
import Compass from "./compass.svg";
import Dashboard from "./dashboard.svg";
import Dots from "./dots.svg";
import Edit from "./edit.svg";
import Excel from "./excel.svg";
import Exit from "./exit.svg";
import Filter from "./filter.svg";
import Garage from "./garage.svg";
import Google from "./google.svg";
import Home from "./home.svg";
import Info from "./info.svg";
import Italic from "./italic.svg";
import Label from "./label.svg";
import Link from "./link.svg";
import LoadingAlt from "./loading-alt.svg";
import LoadingAnimated from "./loading-animated.svg";
import Loading from "./loading.svg";
import Mail from "./mail.svg";
import MenuBurger from "./menu-burger.svg";
import Minus from "./minus.svg";
import Navigation from "./navigation.svg";
import NoLabel from "./no-label.svg";
import Notes from "./notes.svg";
import Object from "./object.svg";
import OrderedList from "./ordered-list.svg";
import Pdf from "./pdf.svg";
import Percent from "./percent.svg";
import Photo from "./photo.svg";
import Plus from "./plus.svg";
import Quote from "./quote.svg";
import Remove from "./remove.svg";
import Return from "./return.svg";
import Search from "./search.svg";
import SettingsGear from "./settings-gear.svg";
import SettingsTool from "./settings-tool.svg";
import Settings from "./settings.svg";
import Shop from "./shop.svg";
import SortAz from "./sort-az.svg";
import Strike from "./strike.svg";
import Trash from "./trash.svg";
import Underline from "./underline.svg";
import Undo from "./undo.svg";
import Unlink from "./unlink.svg";
import UnorderedList from "./unordered-list.svg";
import UserAdd from "./user-add.svg";
import UserCircle from "./user-circle.svg";
import User from "./user.svg";
import VaultEdit from "./vault-edit.svg";
import Vault from "./vault.svg";
import Wallet from "./wallet.svg";
import Warning from "./warning.svg";

export const GreenIcons = {
  Alert,
  ArrowDownLg,
  ArrowDownSm,
  ArrowLeftLg,
  ArrowLeftSm,
  ArrowRightLg,
  ArrowRightSm,
  ArrowUpLg,
  ArrowUpSm,
  Attachment,
  BackArrow,
  Bank,
  Bold,
  Book,
  Calendar,
  Camera,
  Chat,
  CheckCircled,
  CheckCircledFill,
  CheckSm,
  Check,
  Clock,
  CloseSm,
  Coins,
  Compass,
  Dashboard,
  Dots,
  Edit,
  Excel,
  Exit,
  Filter,
  Garage,
  Google,
  Home,
  Info,
  Italic,
  Label,
  Link,
  LoadingAlt,
  LoadingAnimated,
  Loading,
  Mail,
  MenuBurger,
  Minus,
  Navigation,
  NoLabel,
  Notes,
  Object,
  OrderedList,
  Pdf,
  Percent,
  Photo,
  Plus,
  Quote,
  Remove,
  Return,
  Search,
  SettingsGear,
  SettingsTool,
  Settings,
  Shop,
  SortAz,
  Strike,
  Trash,
  Underline,
  Undo,
  Unlink,
  UnorderedList,
  UserAdd,
  UserCircle,
  User,
  VaultEdit,
  Vault,
  Wallet,
  Warning,
};
