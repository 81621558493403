// Checks if a URL is valid.
export const validateUrl = (url: string | null) => {
  if (!url) return;

  try {
    new URL(url);
  } catch (e) {
    return false;
  }

  return true;
};
