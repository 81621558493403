import { useAppSelector } from "~/src/system/store/hooks";
import { get as lsGet } from "~/src/system/ls";

// Trying out this custom hook to see if it helps abstract a repeated pattern.
export const useActiveCompanyId = () => {
  const storeCompanyId = useAppSelector(
    (state) => state.companiesData.customerCompanies.active?.id
  );
  const lsCompanyId = lsGet("lastActiveCompanyId");

  return storeCompanyId ?? lsCompanyId;
};
