import { useEffect } from "react";

export const useRestoreStateAfterOauth = () => {
  const queryParams = new URLSearchParams(window.location.search);

  const state = queryParams.get("state");
  const path = JSON.parse(state ?? "{}").path;
  const code = queryParams.get("code");

  // the redirect url from oauth cannot have the hash ("/#"), this reloads the app (at the path from the params) and restores hash routing
  useEffect(() => {
    if (!!code && !!state)
      window.location.href =
        window.location.origin + "/#" + path + window.location.search;
  }, [code, path, state]);
};

/**
 *  for testing:
 *    state json:
 *      { "path": "/institutions", "query": "?test=test", "company_id": "192a14fe-5941-4dbf-8dc1-16503f3242fc", "institution_id": "QUICKBOOKS" }
 *    state json encoded:
 *      %7B%20%22path%22%3A%20%22%2Finstitutions%22%2C%20%22query%22%3A%20%22%3Ftest%3Dtest%22%2C%20%22company_id%22%3A%20%22192a14fe-5941-4dbf-8dc1-16503f3242fc%22%2C%20%22institution_id%22%3A%20%22QUICKBOOKS%22%20%7D
 *    url returned from oauth:
 *      http://localhost:1234?code=code_value_test&realmId=realmIdVal&state=%7B%20%22path%22%3A%20%22%2Finstitutions%22%2C%20%22query%22%3A%20%22%3Ftest%3Dtest%22%2C%20%22company_id%22%3A%20%22192a14fe-5941-4dbf-8dc1-16503f3242fc%22%2C%20%22institution_id%22%3A%20%22QUICKBOOKS%22%20%7D
 */
