import React from "react";
import { Icons } from "~/src/assets/icons";
import { PageHeader } from "~/src/components/page-header";
import { HelpTip } from "~/src/components/help-tip";
import { LoadTransactions } from "./components/load-transactions";
import "./categorization.css";

export const Categorization: React.FunctionComponent = () => {
  return (
    <>
      <PageHeader
        icon={Icons.default.Book}
        label="Transactions"
        className="categorization__header"
      >
        <HelpTip linkTo="https://www.notion.so/flowfinance/Categorization-60f4a18f61af44a5afa3133343e0c7f7" />
      </PageHeader>
      <LoadTransactions />
    </>
  );
};
