import React from "react";
import classNames from "classnames";
import "./simple-select.css";

export interface ISimpleSelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}
interface ISimpleSelectProps {
  className?: string;
  disabled?: boolean;
  icon?: "user" | "label" | "arrow";
  id: string;
  label?: string;
  name: string;
  fullWidth?: boolean;
  onChange: (value: string) => void;
  placeholder?: string;
  options: ISimpleSelectOption[];
  required?: boolean;
  value: string | number;
}

export const SimpleSelect = ({
  className,
  disabled,
  icon = "arrow",
  id,
  label,
  fullWidth,
  name,
  onChange,
  options,
  placeholder: placeholderText,
  required,
  value,
}: ISimpleSelectProps) => {
  const selectClassNames = classNames(`simple-select ${className}`, {
    [`simple-select--${icon}`]: icon,
    "simple-select--full-width": fullWidth,
  });

  const placeholder = placeholderText
    ? [
        {
          value: "",
          disabled: true,
          label: placeholderText,
        },
      ]
    : [];

  return (
    <>
      {label && (
        <label htmlFor="name" className="simple-select__label">
          {label}
        </label>
      )}
      <select
        disabled={disabled}
        aria-disabled={disabled}
        required={required}
        aria-required={required}
        className={selectClassNames}
        name={name}
        id={id}
        value={value}
        onChange={(event: React.FormEvent<HTMLSelectElement>) => {
          onChange(event.currentTarget.value);
        }}
      >
        {[...placeholder, ...options].map((option, index) => {
          return (
            <option
              key={`${option.value}${index}`}
              value={option.value}
              disabled={option.disabled}
              aria-disabled={option.disabled}
            >
              {option.label}
            </option>
          );
        })}
      </select>
    </>
  );
};
