import React from "react";
import { Route, Redirect } from "react-router-dom";

import { authenticated } from "~/src/system/security";
import ROUTES from "~/src/constants/routes";
import * as session from "~/src/system/session";

interface IPrivateRouteProps {
  path: string;
}

export const PrivateRoute: React.FunctionComponent<IPrivateRouteProps> = ({
  path,
  children,
}) => {
  const isAuthenticated = authenticated();
  if (isAuthenticated) session.refresh();
  else session.remove();

  return (
    <Route
      path={path}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.login.path,
              search: location.search,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
