import { accountSchema, tagSchema } from "../accounts/accounts-api-schema";
import { ruleConditionsSchema } from "../rules/rules-api-schema";

export const merchantSchema = `
  externalId
  name
  merchantType
  categorizationSuggestion { ${accountSchema} }
`;

export const splitAccountInfoSchema = `
  status
`;

export const sourceAccountInfoSchema = `
  ...on Account { ${accountSchema} }
  ...on SplitAccountInfo { ${splitAccountInfoSchema} }
`;

export const uncategorizedLineItemSchema = `
  externalTransactionId
  type
  lineItemId
  transactionSyncToken
  date
  memo
  amount
  sourceAccountInfo { ${sourceAccountInfoSchema} }
  postingType
`;

export const categorizedLineItemSchema = `
  externalTransactionId
  type
  lineItemId
  transactionSyncToken
  date
  memo
  amount
  sourceAccountInfo { ${sourceAccountInfoSchema} }
  merchant { ${merchantSchema} }
  categorizedAccount { ${accountSchema} }
  postingType
`;

export const ruleSchema = `
  id
  companyId
  merchant { ${merchantSchema} }
  tag { ${tagSchema} }
  conditions { ${ruleConditionsSchema} }
`;

export const categorizeWorkflowExcludeSuggestionsSchema = `
  uncategorizedLineItems { ${uncategorizedLineItemSchema} }
  categorizedLineItems { ${categorizedLineItemSchema} }
`;

export const ruleSuggestionSchema = `
  rule { ${ruleSchema} }
  mappedAccount { ${accountSchema} }
  matchingUncategorizedLineItems { ${uncategorizedLineItemSchema} }
`;

export const categorizationWorkflowSchema = `
  ${categorizeWorkflowExcludeSuggestionsSchema}
  ruleSuggestions { ${ruleSuggestionSchema} }
`;

export const transactionLineItemsSchema = `
  ${categorizedLineItemSchema}
`;

export const transactionSchema = `
  externalId
  syncToken
  type
  date
  lineItems { ${transactionLineItemsSchema} }
`;
