import React, { useState, useEffect } from "react";
import { Dropdown } from "../dropdown";
import { UserIcon } from "../user-icon";
import { Icons } from "~/src/assets/icons";
import { GoogleLogoutButton } from "../auth/google-logout-button";
import { IUser } from "~/src/types/user";
import "./user-menu.css";

interface IUserMenuTrigger {
  user: IUser;
}

// The element that we use to deploy the dropdown.
const UserMenuTrigger = ({ user }: IUserMenuTrigger) => {
  return (
    <div className="user-menu__trigger">
      <UserIcon user={user} size="sm" className="mr-2" />
      <div>
        {user?.first_name} {user?.last_name}
      </div>
      <img src={Icons.default.ArrowDownLg} />
    </div>
  );
};

interface IUserMenu {
  className?: string;
  user: IUser;
}

export const UserMenu = ({ className, user }: IUserMenu) => {
  return (
    <div className={`user-menu ${className}`}>
      <Dropdown
        customTrigger={<UserMenuTrigger user={user} />}
        direction="left"
      >
        <GoogleLogoutButton>Log Out</GoogleLogoutButton>
      </Dropdown>
    </div>
  );
};
