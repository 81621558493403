import { IAccount, ITag } from "./accounts";
import { IMerchant } from "./transactions";

export type TPostingType = "CREDIT" | "DEBIT";

export type TRuleMemoConditionOperator =
  | "CONTAINS"
  | "DOES_NOT_CONTAIN"
  | "EQUALS"
  | "STARTS_WITH";

export const RuleMemoConditionOperators: TRuleMemoConditionOperator[] = [
  "CONTAINS",
  "DOES_NOT_CONTAIN",
  "EQUALS",
  "STARTS_WITH",
];

export const RuleMemoOperatorsForUi = {
  CONTAINS: "Contains",
  DOES_NOT_CONTAIN: "Does Not Contain",
  EQUALS: "Equals",
  STARTS_WITH: "Starts With",
};

export type TRuleAmountConditionOperator =
  | "EQ"
  | "NE"
  | "GT"
  | "GTE"
  | "LT"
  | "LTE";

export const RuleAmountConditionOperators: TRuleAmountConditionOperator[] = [
  "EQ",
  "NE",
  "GT",
  "GTE",
  "LT",
  "LTE",
];

export const RuleAmountOperatorsForUi = {
  EQ: "=",
  NE: "!=",
  GT: ">",
  GTE: "≥",
  LT: "<",
  LTE: "≤",
};

export interface IRulePostingTypeCondition {
  postingType: TPostingType;
}

export interface IRuleMemoCondition {
  argument: string;
  operator: TRuleMemoConditionOperator;
}

export interface IRuleMemoStopWordCondition {
  argument: string;
}

export interface IRuleAmountCondition {
  argument: number;
  operator: TRuleAmountConditionOperator;
}

export interface IRuleSourceAccountCondition {
  account: IAccount;
}

// rule conditions
export interface IRuleConditions {
  postingTypeCondition: IRulePostingTypeCondition;
  memoConditions: IRuleMemoCondition[];
  memoStopWordConditions?: IRuleMemoStopWordCondition[];
  amountConditions?: IRuleAmountCondition[];
  sourceAccountCondition?: IRuleSourceAccountCondition;
}

// Basic Rules
export interface IRule {
  id: string;
  companyId: string;
  merchant: IMerchant;
  tag: ITag;
  conditions: IRuleConditions;
}

// Rule Suggestions
export interface IRuleSuggestion {
  rule: IRule;
  mappedAccount: IAccount;
  matchingUncategorizedTransactions: any[]; // TODO: IUncategorizedTransaction[] -- this depends on Ethan's work being merged;
}
