import React, { useState, useEffect } from "react";
import { Dropdown } from "../dropdown";
import { ScrollContainer } from "../scroll-container";
import { Icons } from "~/src/assets/icons";
import "./select.css";

export interface ISelectOption {
  id: string | number;
  label: string;
}

interface ISelectProps {
  options: ISelectOption[];
  children?: React.ReactNode;
  placeholder?: string;
  label?: string;
  onSelect: (option: ISelectOption) => void;
  defaultOption?: ISelectOption;
  buttonClassName?: string;
  icon?: string;
}

export const Select = ({
  options,
  children,
  placeholder,
  label,
  onSelect,
  defaultOption,
  buttonClassName,
  icon,
}: ISelectProps) => {
  const [selectedOption, setSelectedOption] = useState<
    ISelectOption | undefined
  >(undefined);

  const handleSelect = (item: ISelectOption) => {
    setSelectedOption(item);
    onSelect(item);
  };

  useEffect(() => {
    if (defaultOption) {
      setSelectedOption(defaultOption);
    }
  }, []);

  return (
    <Dropdown
      direction="center"
      rightIcon={icon ?? Icons.default.ArrowRightSm}
      label={
        selectedOption
          ? selectedOption.label
          : placeholder
          ? placeholder
          : "Select One"
      }
      buttonClassName={buttonClassName}
      textOnly
      isSelect
    >
      {label && <h3 className="select-label">{label}</h3>}

      <ScrollContainer noPadding height={250}>
        <ul className="select-options">
          {options.map((item, index) => {
            return (
              <li
                className={`select-item ${
                  item.id === selectedOption?.id ? `select-item--selected` : ``
                }`}
                key={index}
                onClick={() => handleSelect(item)}
              >
                {item.label}
              </li>
            );
          })}
        </ul>
      </ScrollContainer>

      {children && <div className="select-addon">{children}</div>}
    </Dropdown>
  );
};
