import { useAppSelector } from "~/src/system/store/hooks";
import { InstitutionId } from "~/src/types/institutions";

export const useConnectionTitleManager = (institutionId: InstitutionId) => {
  const TITLE_BASE = "Connection";
  const SEPARATOR = " ";

  const institutions = useAppSelector(
    (state) => state.institutions.institutions
  );
  const institution = institutions.find(({ id }) => id === institutionId);
  const connections = institution?.connections ?? [];

  const titlePrefixComponents = [institution?.displayName ?? "", TITLE_BASE];
  const titlePrefix = titlePrefixComponents.join(SEPARATOR);
  const buildSuggestedTitle = (instance: number) =>
    [titlePrefix, instance].join(SEPARATOR);

  /**
   * Suggest a title for use when manually creating a new connection. The requirement is that
   * the suggested title is the first unused iteration on the base default title `${institution.displayName} Connection`,
   * with iterations following the pattern (using "Klaviyo" as an example) "Klaviyo Connection",
   * then "Klaviyo Connection 2", "Klaviyo Connection 3" and so on. Examples:
   * - If the base title and iteration 2 are taken, use "Klaviyo Connection 3"
   * - If iterations 2 and 3 are taken, use the base title, "Klaviyo Connection"
   * - If the base title and iterations 2, 3, and 5 are taken, use "Klaviyo Connection 4"
   */
  const suggestTitle = () => {
    let title = titlePrefix;

    while (connections.find((c) => c.title === title)) {
      const newInstance =
        parseInt(title.split(SEPARATOR)[titlePrefixComponents.length] ?? "0") +
        1;
      title = buildSuggestedTitle(newInstance);
    }

    return title;
  };

  const titleIsUnique = (title: string) => {
    return !connections.find((c) => c.title === title);
  };

  return { suggestedTitle: suggestTitle(), titleIsUnique };
};
