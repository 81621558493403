import { useLocation } from "react-router";
import { IInitiateConnectionInputState } from "~/src/state/institutions/institutions-api-types";

// for documenting and keeping consistent the url value used with connections crud
export const useConnectionLocation = () => {
  const location = useLocation();
  const redirectUrl = window.location.origin; // just the tld, no trailing `/`, e.g. `https://flowfi.com`

  const state: IInitiateConnectionInputState = {
    path: location.pathname, // includes leading `/`, e.g. `/institutions` or `/` if there is no path
    query: location.search, // includes leading `?` when populated, e.g. `?query_param!=value` or an empty string if there are no search params
  };
  return { redirectUrl, state };
};
