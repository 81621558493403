import React, { useState, useEffect } from "react";
import { IAccountWithTags, ITag } from "~/src/types/accounts";
import { useAppSelector } from "~/src/system/store/hooks";
import { findAccountByTagId } from "~/src/utilities/accounts";
import { IMerchant } from "~/src/types/transactions";

interface IAccountTagSelector {
  tagId?: string;
  onChange: Function;
  filterAccounts?: boolean;
  merchantWithSuggestedAccount?: IMerchant;
  tagRequired?: boolean;
  accountRequired?: boolean;
}

/* 
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
*/

export const AccountTagSelector = ({
  tagId,
  onChange,
  filterAccounts,
  merchantWithSuggestedAccount,
  tagRequired,
  accountRequired,
}: IAccountTagSelector) => {
  // Master account/tag list
  const accountTagMapping = useAppSelector(
    (state) => state.accounts.accountTagMapping
  );
  const tagList = useAppSelector((state) => state.accounts.allTags.mappedTags);

  // Internal state
  const [_tagId, setTagId] = useState<string | undefined>(tagId ?? undefined);
  const [_accountId, setAccountId] = useState<string | undefined>(
    tagId ? findAccountByTagId(tagId, accountTagMapping) : undefined
  );
  const [accountList, setAccountList] = useState<IAccountWithTags[]>([]);

  // Filtered tag list, if accounts are selected
  const [filteredTags, setFilteredTags] = useState<ITag[]>(tagList);

  // Handle changing the account - filters the available tags to ones mapped to that account.
  const handleAccountIdChange = (id: string) => {
    setAccountId(id);
    setTagId("");

    const newTags = accountTagMapping.accounts.find(
      (account) => account.account.externalId === id
    );

    if (newTags) setFilteredTags(newTags.tags);
  };

  // Handle changing tags - auto-sets the mapped account id.
  const handleTagIdChange = (id: string) => {
    setTagId(id);
    setAccountId(findAccountByTagId(id, accountTagMapping));
  };

  // Resets both tag/account lists.
  const resetTagAndAccount = () => {
    setFilteredTags(tagList);
    setTagId("");
    setAccountId("");
  };

  // Pass the account and tag ID back up.
  useEffect(() => {
    onChange({ accountId: _accountId, tagId: _tagId });
  }, [_accountId, _tagId]);

  useEffect(() => {
    filterAccounts
      ? setAccountList(
          accountTagMapping.accounts.filter((a) => !!a.tags.length)
        )
      : setAccountList(accountTagMapping.accounts);
  }, []);

  // update the account id if a merchant with a suggested account is passed as a prop
  useEffect(() => {
    if (merchantWithSuggestedAccount?.categorizationSuggestion) {
      handleAccountIdChange(
        merchantWithSuggestedAccount.categorizationSuggestion.externalId
      );
    }
  }, [merchantWithSuggestedAccount]);

  return (
    <>
      <div className="rule-form__row">
        <label>Mapping {tagRequired && "*"}</label>
        <select
          className="w-full"
          value={_tagId}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            handleTagIdChange(e.target.value)
          }
        >
          <option value="">Select</option>
          {filteredTags.map((tag) => (
            <option key={tag.id} value={tag.id}>
              {tag.name}
            </option>
          ))}
        </select>
      </div>

      <div className="rule-form__row">
        <label>Account {accountRequired && "*"}</label>
        <select
          className="w-full"
          value={_accountId}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            handleAccountIdChange(e.target.value)
          }
        >
          <option value={""}>Select</option>
          {accountList.map((option) => (
            <option
              key={option.account.externalId}
              value={option.account.externalId}
            >
              {option.account.accountNumber} {option.account.displayName}
            </option>
          ))}
        </select>
      </div>

      <div className="rule-form__row rule-form__row--flex">
        {!!merchantWithSuggestedAccount &&
          merchantWithSuggestedAccount?.categorizationSuggestion?.externalId ===
            _accountId && (
            <span className="text-gray-dark">
              {`${merchantWithSuggestedAccount.name} has historically been categorized to this QBO account.`}
            </span>
          )}
        <button
          className="reset-tags"
          type="button"
          onClick={resetTagAndAccount}
        >
          Reset Mapping &amp; Account
        </button>
      </div>
    </>
  );
};
