import { decrypt, encrypt } from "~/src/system/security";
import * as ls from "~/src/system/ls";
import { IUserProfiles } from "../types/user";

export interface ISession extends IUserProfiles {
  iat: number;
}

export function create(token: string) {
  ls.set("session", token);
}

export function get(): ISession {
  const session = ls.get("session");
  let decrypted: ISession = {
    googleUser: {
      email: "",
      familyName: "",
      givenName: "",
      googleId: "",
      imageUrl: "",
      name: "",
    },
    flowUser: {
      companies: [],
      emails: [],
      user: {
        id: "",
        first_name: "",
        last_name: "",
      },
    },
    iat: 0,
  };
  if (session) {
    try {
      decrypted = decrypt(session || "") as ISession;
    } catch {
      //
    }
  }
  return decrypted;
}

export function refresh() {
  const session = get() || {};
  const newSession: Partial<ISession> = { ...session };
  delete newSession.iat;
  const token = encrypt(newSession);
  ls.set("session", token);
}

export function remove() {
  ls.del("session");
}

export function validate(session: ISession): boolean {
  const sessionLength = 86_400; // 2 days (in seconds)
  const now = Math.floor(new Date().getTime() / 1000);
  const validLength = now - session.iat <= sessionLength;
  return validLength;
}
