import React from "react";
import { useHistory } from "react-router";
import { BackButton } from "~/src/components/back-button";
import ROUTES from "~/src/constants/routes";
import { IActionItem } from "~/src/types/action-items";
import { ActionItemRow } from "./action-item-row";

import "./action-items.css";

interface IActionItemsClosedProps {
  items: IActionItem[];
}

export const ActionItemsClosed = ({ items }: IActionItemsClosedProps) => {
  const filteredItems = items.filter((item) => item.state === "closed");
  const history = useHistory();
  return (
    <>
      <div className="action-items-header-row">
        <BackButton onClick={() => history.push(ROUTES.actionItems.path)}>
          Closed Action Items ({filteredItems.length})
        </BackButton>
      </div>
      <table className="table action-items-container">
        <tbody>
          {filteredItems?.map((item) => {
            return (
              <ActionItemRow
                item={item}
                key={item.id}
                selectable={false}
                ctaLabel={""}
              />
            );
          })}
        </tbody>
      </table>
    </>
  );
};
