import React, { useState, useEffect } from "react";
import { Button } from "~/src/components/button";
import { PageHeader } from "~/src/components/page-header";
import {
  ConditionEditor,
  IConditionWithId,
  IDisabledConditionOptions,
} from "../condition-editor";
import { AccountTagSelector } from "~/src/components/account-tag-selector";
import { MerchantSelector } from "~/src/components/merchant-selector";
import { LineItemSummary } from "~/src/components/line-item-summary";
import { ScrollContainer } from "~/src/components/scroll-container";
import {
  IRule,
  IRuleMemoCondition,
  IRuleAmountCondition,
  IRuleSourceAccountCondition,
  IRulePostingTypeCondition,
  TPostingType,
  IRuleMemoStopWordCondition,
} from "~/src/types/rules";
import { IAccount, IAccountWithTags } from "~/src/types/accounts";
import { IUncategorizedLineItem, IMerchant } from "~/src/types/transactions";
import { useUpsertRuleMutation } from "~/src/state/rules/rules-api-slice";
import { useAppSelector } from "~/src/system/store/hooks";
import { Icons } from "~/src/assets/icons";
import { Toast } from "~/src/components/toast";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  TRuleMemoConditionOperator,
  TRuleAmountConditionOperator,
} from "~/src/types/rules";
import {
  IRuleConditionsInput,
  IRuleUpsertInput,
} from "~/src/state/rules/rules-api-types";
import "./rule-upsert-form.css";

interface IRuleUpsertForm {
  companyId: string;
  rule?: IRule | null;
  onClose: () => void;
  lineItem?: IUncategorizedLineItem | null;
  filterAccounts?: boolean;
}

interface IAccountTagIds {
  accountId: string;
  tagId: string;
}

export const RuleUpsertForm = ({
  rule,
  companyId,
  onClose,
  lineItem,
  filterAccounts,
}: IRuleUpsertForm) => {
  // CRUD
  const [
    upsertRule,
    {
      isLoading: upsertUpdating,
      isSuccess: upsertSuccess,
      isError: upsertError,
      data: upsertData,
    },
  ] = useUpsertRuleMutation();

  const sourceAccountList = useAppSelector(
    (state) => state.accounts.accountsByBlock["ASSETS"]
  );

  const postingTypeMapping = {
    EXPENSE: "DEBIT",
    DEPOSIT: "CREDIT",
  };

  // Fn to handle the initial posting type state
  const handlePostingTypeState = (): IRulePostingTypeCondition => {
    if (lineItem && lineItem.type.toString() !== "JOURNAL_ENTRY") {
      return { postingType: (postingTypeMapping as any)[lineItem.type] };
    } else if (rule) {
      return rule?.conditions.postingTypeCondition;
    } else {
      return { postingType: "DEBIT" };
    }
  };

  // Initial states
  const [uuid, setUuid] = useState<string>("");
  const [formReady, setFormReady] = useState<boolean>(false);
  const [tagId, setTagId] = useState<string | undefined>(rule?.tag.id);
  const [
    postingTypeCondition,
    setPostingTypeCondition,
  ] = useState<IRulePostingTypeCondition>(handlePostingTypeState());
  const [memoConditions, setMemoConditions] = useState<IRuleMemoCondition[]>(
    rule?.conditions.memoConditions ?? []
  );
  const [memoStopWordConditions, setMemoStopWordConditions] = useState<
    IRuleMemoStopWordCondition[]
  >(rule?.conditions.memoStopWordConditions ?? []);
  const [amountConditions, setAmountConditions] = useState<
    IRuleAmountCondition[] | undefined
  >(rule?.conditions.amountConditions);
  const [
    sourceAccountCondition,
    setSourceAccountCondition,
  ] = useState<IRuleSourceAccountCondition | null>(
    rule?.conditions.sourceAccountCondition ?? null
  );
  const [mappedConditions, setMappedConditions] = useState<IConditionWithId[]>(
    []
  );
  const [conditionsValid, setConditionsValid] = useState<boolean>(true);
  const [merchant, setMerchant] = useState<IMerchant | undefined>(
    rule?.merchant
  );

  // Initial load, parses out conditions to be usable by the editor.
  useEffect(() => {
    // generate a uuid if we're creating a new rule.
    if (!rule) setUuid(uuidv4());

    const mappedConditions: any[] = [];

    if (rule && rule.conditions.memoConditions) {
      rule.conditions.memoConditions.map((condition) => {
        mappedConditions.push({
          id: uuidv4(),
          type: "memo",
          argument: condition.argument,
          operator: condition.operator,
        });
      });
    } else {
      mappedConditions.push({
        id: uuidv4(),
        type: "memo",
        argument: "",
        operator: "CONTAINS",
      });

      setMappedConditions(mappedConditions);
    }

    if (rule && rule.conditions.memoStopWordConditions)
      rule.conditions.memoStopWordConditions?.map((condition) => {
        mappedConditions.push({
          id: uuidv4(),
          type: "memo",
          argument: condition.argument,
          operator: "DOES_NOT_CONTAIN",
        });
      });

    if (rule && rule.conditions.amountConditions)
      rule.conditions.amountConditions?.map((condition) => {
        mappedConditions.push({
          id: uuidv4(),
          type: "amount",
          argument: condition.argument,
          operator: condition.operator,
        });
      });

    if (rule && rule.conditions.sourceAccountCondition)
      mappedConditions.push({
        id: uuidv4(),
        type: "sourceAccount",
        argument: rule.conditions.sourceAccountCondition.account?.externalId,
        operator: "equals",
      });

    setMappedConditions(mappedConditions);
  }, []);

  // Handles the conditions data.
  useEffect(() => {
    const memos: IRuleMemoCondition[] = [];
    const memoStopWords: IRuleMemoStopWordCondition[] = [];
    const amounts: IRuleAmountCondition[] = [];
    let source: IAccount | undefined = undefined;
    let numInvalid = 0;

    mappedConditions.map((condition) => {
      const { type, argument, operator } = condition;
      if (type === "memo" && operator === "DOES_NOT_CONTAIN") {
        memoStopWords.push({
          argument: argument as string,
        });
      } else if (type === "memo" && operator !== "DOES_NOT_CONTAIN") {
        memos.push({
          argument: argument as string,
          operator: operator as TRuleMemoConditionOperator,
        });
      } else if (type === "amount") {
        amounts.push({
          argument: argument as number,
          operator: operator as TRuleAmountConditionOperator,
        });
      } else if (type === "sourceAccount") {
        const sa:
          | IAccountWithTags
          | undefined = sourceAccountList?.accounts.find(
          (account) => account.account.externalId === argument
        );

        if (sa) source = sa.account;
      }
      if (condition.valid === false) {
        numInvalid += 1;
      }
    });

    if (numInvalid > 0) {
      setConditionsValid(false);
    } else setConditionsValid(true);

    setMemoConditions(memos);
    setMemoStopWordConditions(memoStopWords);
    setAmountConditions(amounts);
    source
      ? setSourceAccountCondition({ account: source })
      : setSourceAccountCondition(null);
  }, [mappedConditions]);

  const [
    disabledConditionOptions,
    setDisabledConditionOptions,
  ] = useState<IDisabledConditionOptions>({
    type: [],
    operator: ["DOES_NOT_CONTAIN"],
  });

  // Handles setting the form as ready or not.
  useEffect(() => {
    merchant &&
    tagId &&
    postingTypeCondition &&
    memoConditions &&
    memoConditions[0]?.argument &&
    conditionsValid
      ? setFormReady(true)
      : setFormReady(false);
  }, [
    uuid,
    formReady,
    merchant,
    tagId,
    postingTypeCondition,
    memoConditions,
    memoStopWordConditions,
    amountConditions,
    sourceAccountCondition,
    conditionsValid,
  ]);

  useEffect(() => {
    const newDisabledConditionOptions = { ...disabledConditionOptions };
    // enable "DOES_NOT_CONTAIN" operator only if another memo condition exists
    if (
      mappedConditions.filter((condition) => condition.type === "memo").length >
      1
    ) {
      newDisabledConditionOptions.operator = newDisabledConditionOptions.operator.filter(
        (operator) => operator !== "DOES_NOT_CONTAIN"
      );
    } else {
      newDisabledConditionOptions.operator.push("DOES_NOT_CONTAIN");
    }

    // enable "sourceAccount" operator only if a sourceAccount condition does not exist
    if (
      mappedConditions.find((condition) => condition.type === "sourceAccount")
    ) {
      newDisabledConditionOptions.type.push("sourceAccount");
    } else {
      newDisabledConditionOptions.type = newDisabledConditionOptions.type.filter(
        (type) => type !== "sourceAccount"
      );
    }

    setDisabledConditionOptions(newDisabledConditionOptions);
  }, [mappedConditions]);

  // Sets the tagId from the accountTagSelector
  const handleAccountTagSelector = (data: IAccountTagIds) => {
    setTagId(data.tagId);
  };

  // Sets the mapped value of a posting type.
  const handlePostingType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostingTypeCondition({ postingType: e.target.value as TPostingType });
  };

  // Add/Remove/Change handlers for the condition editor.
  const handleAddCondition = () => {
    setMappedConditions([
      ...mappedConditions,
      {
        id: uuidv4(),
        type: "memo",
        argument: "",
        operator: ("CONTAINS" as unknown) as TRuleMemoConditionOperator,
        valid: true,
      },
    ]);
  };

  const handleRemoveCondition = (id: string) => {
    const conditions = [...mappedConditions];

    const index = conditions.findIndex((c) => c.id === id);
    conditions.splice(index, 1);

    setMappedConditions(conditions);
  };

  const handleConditionChange = (condition: IConditionWithId) => {
    const conditions = [...mappedConditions];
    const index = conditions.findIndex((c) => c.id === condition.id);

    conditions[index] = condition;
    setMappedConditions(conditions);
  };

  // Assemble the upsert input & handle form submission.
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!conditionsValid || !merchant?.externalId || !tagId) return;

    const conditions: IRuleConditionsInput = {
      postingTypeCondition,
      memoConditions,
      memoStopWordConditions,
      amountConditions,
    };

    if (sourceAccountCondition && sourceAccountCondition.account?.externalId)
      conditions["sourceAccountCondition"] = {
        externalAccountId: sourceAccountCondition.account.externalId,
      };

    const ruleInput: IRuleUpsertInput = {
      id: rule?.id ?? uuid,
      companyId,
      merchantId: merchant?.externalId ?? "",
      tagId: tagId ?? "",
      conditions,
    };

    upsertRule(ruleInput);
  };

  // Display toasts for upsert operations, then close the drawer.
  useEffect(() => {
    if (upsertSuccess && !!upsertData?.data) {
      toast(
        <Toast
          message={!rule ? "Rule added!" : "Rule updated."}
          icon={Icons.green.Info}
        />
      );
      onClose();
    } else if (upsertError || !!upsertData?.errors) {
      toast(
        <Toast
          message="There was a problem when editing this rule."
          icon={Icons.green.Info}
        />
      );
      onClose();
    }
  }, [upsertSuccess, upsertError, upsertData]);

  return (
    <>
      <PageHeader
        label={`${rule?.id ? "Edit" : "Create a New"} Rule`}
        icon={Icons.default.Vault}
        className="rule-form_header"
      />
      <ScrollContainer noPadding fullHeight>
        <form className="sidebar-form" onSubmit={handleSubmit}>
          {lineItem ? <LineItemSummary lineItem={lineItem} /> : null}
          <div className="sidebar-form__body">
            <div className="rule-form__row">
              <label htmlFor="posting-type">Posting Type</label>
              <div className="rule-form__radios">
                <label>
                  <input
                    type="radio"
                    id="posting-type"
                    name="posting-type"
                    value={"DEBIT"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handlePostingType(e);
                    }}
                    checked={postingTypeCondition.postingType === "DEBIT"}
                  />{" "}
                  Debit (Money Out)
                </label>
                <label>
                  <input
                    type="radio"
                    name="posting-type"
                    value={"CREDIT"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handlePostingType(e);
                    }}
                    checked={postingTypeCondition.postingType === "CREDIT"}
                  />{" "}
                  Credit (Money In)
                </label>
              </div>
            </div>

            <div className="rule-condition__label-wrap">
              <label htmlFor="add-condition">Conditions *</label>
              <button
                id="add-condition"
                className="add-condition"
                type="button"
                onClick={handleAddCondition}
              >
                + Add Condition
              </button>
            </div>

            <div className="rule-condition__form">
              {mappedConditions.map((condition) => {
                return (
                  <ConditionEditor
                    key={condition.id}
                    onChange={handleConditionChange}
                    onRemove={handleRemoveCondition}
                    condition={condition}
                    sourceAccounts={sourceAccountList?.accounts.filter(
                      (a) =>
                        a.account.accountType === "BANK" ||
                        a.account.accountType === "CREDIT_CARDS"
                    )}
                    disabledConditionOptions={disabledConditionOptions}
                  />
                );
              })}
            </div>

            <div className="rule-form__row">
              <MerchantSelector
                merchant={merchant}
                setMerchant={setMerchant}
                postingType={postingTypeCondition.postingType}
                required
              />
            </div>

            <AccountTagSelector
              tagId={tagId}
              onChange={handleAccountTagSelector}
              filterAccounts={filterAccounts}
              tagRequired
            />
          </div>
          <div className="sidebar-form__submit-row">
            <Button
              label="Cancel"
              type="button"
              size="large"
              theme="secondary"
              onClick={onClose}
            />
            <Button
              label="Save Rule"
              type="submit"
              disabled={!formReady || upsertUpdating}
              size="large"
              theme="primary"
            />
          </div>
        </form>
      </ScrollContainer>
    </>
  );
};
