import React from "react";
import { Icons } from "~/src/assets/icons";
import "./back-button.css";

interface IBackButton {
  children?: React.ReactNode;
  onClick?: any;
}

export const BackButton = ({ onClick, children }: IBackButton) => {
  const handleClick = (e: React.MouseEvent) => {
    if (!onClick) return;
    onClick();
  };

  return (
    <button
      className="back-button"
      onClick={(e: React.MouseEvent) => handleClick(e)}
    >
      <img src={Icons.default.BackArrow} alt="left-pointing arrow" />
      {children ?? <span>Back</span>}
    </button>
  );
};
