import { useEffect } from "react";
import { setActionItems } from "~/src/state/action-items/action-items-data-slice";
import { useGetActionItemsQuery } from "~/src/state/action-items/action-items-api-slice";
import { useActiveCompanyId } from "~/src/hooks/useActiveCompanyId";
import { useActiveUser } from "~/src/hooks/useActiveUser";
import { useDispatch } from "react-redux";
import { useAppSelector } from "~/src/system/store/hooks";

export const useActionItems = () => {
  const dispatch = useDispatch();
  const companyId = useActiveCompanyId();
  const { userId } = useActiveUser();

  const actionItemsQuery = useGetActionItemsQuery(
    {
      userId,
      companyId,
    },
    { skip: !companyId }
  );

  useEffect(() => {
    if (actionItemsQuery.isSuccess) {
      dispatch(setActionItems(actionItemsQuery.data ?? []));
    }
  }, [actionItemsQuery]);

  const items = useAppSelector((state) => state.actionItems.actionItems);

  return { actionItems: items, isLoading: actionItemsQuery.isLoading };
};
