import React from "react";
import { NavLink } from "react-router-dom";
import { IRoute } from "~/src/constants/routes";
import "./nav-tabs.css";

interface INavTabs {
  routes: IRoute[];
  children?: React.ReactNode;
}

export const NavTabs = ({ routes, children }: INavTabs) => {
  return (
    <ul className="nav-tabs">
      {routes.map((route, i) => {
        return (
          <li key={i}>
            <NavLink
              to={route.path}
              className="nav-tab"
              activeClassName="nav-tab--active"
            >
              {route.name || ""}
            </NavLink>
          </li>
        );
      })}
      {children && <li>{children}</li>}
    </ul>
  );
};
