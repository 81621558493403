import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompany } from "~/src/types/company";

export interface ICustomerCompanies {
  all: ICompany[];
  active: ICompany | null;
  assigned: ICompany[];
}

interface ICompaniesDataState {
  customerCompanies: ICustomerCompanies;
}

const initialCustomerCompaniesState: ICustomerCompanies = {
  all: [],
  active: null,
  assigned: [],
};

const initialState: ICompaniesDataState = {
  customerCompanies: initialCustomerCompaniesState,
};

const companiesDataSlice = createSlice({
  name: "companiesData",
  initialState,
  reducers: {
    setActiveCompany(state, action: PayloadAction<ICompany>) {
      state.customerCompanies.active = action.payload;
    },
    saveCustomerCompaniesData(
      state,
      action: PayloadAction<ICustomerCompanies>
    ) {
      const newState = { ...state.customerCompanies };
      newState.all = action.payload.all;
      newState.assigned = action.payload.assigned;
      state.customerCompanies = newState;
    },
  },
});

export const { saveCustomerCompaniesData, setActiveCompany } =
  companiesDataSlice.actions;
export default companiesDataSlice.reducer;
