import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IFlowUserData,
  IGoogleUser,
  IUser,
  IUserProfiles,
} from "~/src/types/user";

interface IUserDataState {
  profiles: IUserProfiles;
}

const initialFlowUser: IFlowUserData = {
  companies: [],
  emails: [],
  user: {
    id: "",
    first_name: "",
    last_name: "",
  },
};

const initialGoogleUser: IGoogleUser = {
  email: "",
  familyName: "",
  givenName: "",
  googleId: "",
  imageUrl: "",
  name: "",
};

const initialUserProfiles: IUserProfiles = {
  flowUser: initialFlowUser,
  googleUser: initialGoogleUser,
};

const initialState: IUserDataState = {
  profiles: initialUserProfiles,
};

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    saveUserData(state, action: PayloadAction<IUserProfiles>) {
      state.profiles = action.payload;
    },
  },
});

export const { saveUserData } = userDataSlice.actions;
export default userDataSlice.reducer;
