import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { IJiraEpic } from "../../types/jira-epic";

const jiraEpicModel = `
  id
  key
  summary
  status
`;

interface IJiraEpicQueryArgs {
  companyId: string;
}

export const jiraEpicsApi = createApi({
  reducerPath: "jiraEpicsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.STATE_API,
    prepareHeaders(headers) {
      headers.set("content-type", "application/json");
      return headers;
    },
  }),
  tagTypes: ["JiraEpics"],
  endpoints: (build) => {
    return {
      getJiraEpics: build.query<IJiraEpic[], IJiraEpicQueryArgs>({
        providesTags: ["JiraEpics"],
        query: ({ companyId }) => {
          const query = `
          query GetJiraEpics($company_id: ID!) {
              jiraEpics(company_id: $company_id){
                ${jiraEpicModel}
              }
          }
          `;
          const variables = {
            company_id: companyId,
          };
          return {
            url: `/`,
            method: "POST",
            body: { query, variables },
          };
        },
        transformResponse: (response: {
          data?: { jiraEpics: IJiraEpic[] };
        }) => {
          return response.data?.jiraEpics || [];
        },
      }),
    };
  },
});

export const { useGetJiraEpicsQuery } = jiraEpicsApi;
