import React from "react";
import {
  TRichStyle,
  IRichStyle,
  BLOCK_STYLES,
  INLINE_STYLES,
} from "~/src/types/richtext";
import {
  EditorCommand,
  EditorState,
  SelectionState,
  RichUtils,
  DraftInlineStyle,
  DraftBlockType,
  convertFromHTML,
  ContentState,
  Modifier,
} from "draft-js";
import { convertToHTML } from "draft-convert";
import Editor from "@draft-js-plugins/editor";
import addLinkPlugin from "./components/link";
import { validateUrl } from "~/src/utilities";
import { Icons } from "~/src/assets/icons";
import { SVGHandler } from "../svg-handler";
import Classnames from "classnames";
import "draft-js/dist/Draft.css";
import "./rich-text.css";

/**
 * Rich Text Editor
 */
interface IRichText {
  placeholder?: string;
  readonly?: boolean;
  editorState: EditorState;
  editorStateChange: Function;
  onAttach?: Function;
  id?: string;
  className?: string;
}

export const RichText = ({
  placeholder,
  readonly,
  editorState,
  editorStateChange,
  onAttach,
  id,
  className,
}: IRichText) => {
  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();

  // Custom inline styles
  const styleMap = {
    STRIKETHROUGH: {
      textDecoration: "line-through",
    },
  };

  // This allows for some common key commands (e.g. Cmd+B for bold text)
  const handleKeyCommand = (
    command: EditorCommand,
    editorState: EditorState
  ) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      editorStateChange(newState);
      return "handled";
    }

    return "not-handled";
  };

  // Manual buttons
  const onAddLink = () => {
    // TODO: flesh this out to pop a styled input rather than a prompt.
    const link = window.prompt("Enter or paste a valid hyperlink.");

    // Make sure the link is valid. http(s) required.
    if (!validateUrl(link)) return;

    if (!link) {
      editorStateChange(RichUtils.toggleLink(editorState, selection, null));
      return "handled";
    }

    const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
      url: link,
    });
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      "apply-entity"
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();

    editorStateChange(
      RichUtils.toggleLink(newEditorState, selection, entityKey)
    );
  };

  // Replace an entity (e.g. strip links)
  // The only custom entity we use is a link, so this doesn't currently need to be more specific.
  const removeEntity = () => {
    const newState = Modifier.applyEntity(content, selection, null);
    const newEditorState = EditorState.push(
      editorState,
      newState,
      "apply-entity"
    );

    editorStateChange(newEditorState);
  };

  return (
    <div className={`rich-text ${className}`} id={id}>
      <div className="rich-text__toolbar">
        <InlineStyleControls
          editorState={editorState}
          buttons={INLINE_STYLES}
          onChange={editorStateChange}
          disabled={readonly}
        />
        <BlockStyleControls
          editorState={editorState}
          buttons={BLOCK_STYLES}
          onChange={editorStateChange}
          disabled={readonly}
        />
        <div className="rich-text__controls">
          {/* { onAttach &&
            <RichStyleButton 
              style={'none'} 
              label={'Attachment'} 
              icon={Icons.default.Attachment} 
              onClick={() => onAttach()} 
              active={false}
              disabled={readonly}
            />
          } */}
          <RichStyleButton
            style={"LINK"}
            label={"Link"}
            icon={Icons.default.Link}
            onClick={onAddLink}
            active={false}
            disabled={readonly}
          />
          <RichStyleButton
            style={"none"}
            label={"UnLink"}
            icon={Icons.default.Unlink}
            onClick={removeEntity}
            active={false}
            disabled={readonly}
          />
        </div>
      </div>
      <div className="rich-text__editor">
        <Editor
          customStyleMap={styleMap}
          editorState={editorState}
          onChange={(editorState: EditorState) =>
            editorStateChange(editorState)
          }
          handleKeyCommand={handleKeyCommand}
          placeholder={readonly ? "" : placeholder}
          readOnly={readonly}
          plugins={[addLinkPlugin]}
        />
      </div>
    </div>
  );
};

/**
 * Block/Inline Style Components
 */

interface IStyleControls {
  editorState: EditorState;
  buttons: IRichStyle[];
  onChange: Function;
  disabled: boolean | undefined;
}

// Controls to handle inline styling
const InlineStyleControls = ({
  editorState,
  buttons,
  onChange,
  disabled,
}: IStyleControls) => {
  const currentStyle: DraftInlineStyle = editorState.getCurrentInlineStyle();

  const handleInlineStyle = (
    e: React.MouseEvent<HTMLButtonElement>,
    style: TRichStyle
  ) => {
    e.preventDefault();
    const nextState = RichUtils.toggleInlineStyle(editorState, style);
    onChange(nextState);
  };

  return (
    <div className="rich-text__controls">
      {buttons.map((button, i) => {
        return (
          <RichStyleButton
            key={`${button.label}-${i}`}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              handleInlineStyle(e, button.style)
            }
            style={button.style}
            label={button.label}
            icon={button.icon}
            active={currentStyle.has(button.style)}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

// Controls to handle block styling.
const BlockStyleControls = ({
  editorState,
  buttons,
  onChange,
  disabled,
}: IStyleControls) => {
  const selection: SelectionState = editorState.getSelection();
  const blockType: DraftBlockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const handleBlockType = (
    e: React.MouseEvent<HTMLButtonElement>,
    style: TRichStyle
  ) => {
    e.preventDefault();
    const nextState = RichUtils.toggleBlockType(editorState, style);
    onChange(nextState);
  };

  return (
    <div className="rich-text__controls">
      {buttons.map((button, i) => {
        return (
          <RichStyleButton
            key={`${button.label}-${i}`}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              handleBlockType(e, button.style)
            }
            style={button.style}
            label={button.label}
            icon={button.icon}
            active={button.style === blockType}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

interface IRichStyleButton {
  active: boolean;
  onClick: Function;
  style: string;
  label: string;
  icon: string;
  disabled?: boolean | undefined;
}

// Buttons to toggle styles.
const RichStyleButton = ({
  active,
  style,
  label,
  icon,
  onClick,
  disabled,
}: IRichStyleButton) => {
  const handleToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick(e, style);
  };

  const buttonClasses = Classnames("rich-text__button", {
    "rich-text__button--active": active,
    "rich-text__button--disabled": disabled,
  });

  return (
    <button
      aria-label={label}
      className={buttonClasses}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleToggle(e)}
    >
      <SVGHandler image={icon} altText={label} width={24} height={24} />
    </button>
  );
};

/**
 * Utility functions
 */

// Converts the rich text & all entities to flat HTML.
export const richTextToHtml = (editorState: EditorState) => {
  const html = convertToHTML({
    // Handle custom entities.
    entityToHTML: (entity, text) => {
      if (entity.type === "LINK") {
        return (
          <a rel={entity.data.rel} href={entity.data.url}>
            {text}
          </a>
        );
      }
      return text;
    },
    // Handle inline styles.
    styleToHTML: (style) => {
      if (style === "STRIKETHROUGH") {
        return <span style={{ textDecoration: "line-through" }} />;
      }
    },
  })(editorState.getCurrentContent());

  return html;
};

// Converts flat HTML to a state that the editor can use.
export const htmlToRichText = (html: string) => {
  // Assemble Draft blocks from the HTML
  const blocksFromHtml = convertFromHTML(html);

  // Create ContentState.
  const richText = ContentState.createFromBlockArray(
    blocksFromHtml.contentBlocks,
    blocksFromHtml.entityMap
  );

  return richText;
};
