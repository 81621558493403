import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { tagSchema } from "../accounts/accounts-api-schema";
import {
  categorizedLineItemSchema,
  merchantSchema,
  ruleSchema,
} from "../transactions/transactions-api-schema";
import { ruleConditionsSchema } from "./rules-api-schema";
import {
  IDeleteRuleInput,
  IDeleteRuleOutput,
  IRuleUpsertInput,
  IRuleUpsertOutput,
  IRuleVaultOutput,
  IUpsertAndApplyRuleInput,
  IUpsertAndApplyRuleOutput,
} from "./rules-api-types";
import { transformAppSyncApiResponse as transformResponse } from "~/src/system/resource";

/* 
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
*/

/* 
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
*/

/* 
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
*/

export const ruleVaultApi = createApi({
  reducerPath: "ruleVaultApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.JONATHAN_API,
    prepareHeaders(headers) {
      headers.set("content-type", "application/json");
      headers.set(
        "authorization",
        `Bearer ${process.env.JONATHAN_API_TOKEN || ""}`
      );
      return headers;
    },
  }),
  tagTypes: ["Rules"],
  endpoints: (build) => {
    return {
      // Get all rules for a company.
      ruleVault: build.query<IRuleVaultOutput, string>({
        query: (companyId: string) => {
          const query = `
            query ruleVault($companyId: ID!) {
              ruleVault(companyId: $companyId) { ${ruleSchema} }
            }
          `;
          const variables = {
            companyId,
          };
          return {
            url: `/`,
            method: "POST",
            body: { query, variables },
          };
        },
        providesTags: ["Rules"],
        transformResponse,
      }),
      // Upserts a rule.
      upsertRule: build.mutation<IRuleUpsertOutput, IRuleUpsertInput>({
        query: (input) => {
          const query = `
            mutation UpsertRule($input: RuleUpsertInput!) {
              upsertRule(input: $input) {
                id
                merchant { ${merchantSchema} }
                tag { ${tagSchema} }
                conditions { ${ruleConditionsSchema} }
              }
            }          
          `;
          const variables = {
            input,
          };
          return {
            url: `/`,
            method: "POST",
            body: { query, variables },
          };
        },
        invalidatesTags: (result, error, { id }) => [
          "Rules",
          { type: "Rules", id },
        ],
        transformResponse,
      }),
      // Upserts a rule and categorizes transactions.
      upsertAndApplyRule: build.mutation<
        IUpsertAndApplyRuleOutput,
        IUpsertAndApplyRuleInput
      >({
        query: (input) => {
          const query = `
            mutation upsertAndApplyRule($input: UpsertAndApplyRuleInput!) {
              upsertAndApplyRule(input: $input) {
                rule { ${ruleSchema} }
                categorizedTransactionLineItems { ${categorizedLineItemSchema} }
              }
            }          
          `;
          const variables = {
            input,
          };
          return {
            url: `/`,
            method: "POST",
            body: { query, variables },
          };
        },
        invalidatesTags: (_, __, { rule }) => [
          "Rules",
          { type: "Rules", id: rule.id },
        ],
        transformResponse,
      }),
      // Deletes a rule.
      deleteRule: build.mutation<IDeleteRuleOutput, IDeleteRuleInput>({
        query: ({ ruleId, companyId }) => {
          const query = `
            mutation DeleteRule($companyId: ID!, $ruleId: ID!) {
              deleteRule(companyId: $companyId, ruleId: $ruleId)
            }
          `;
          const variables = {
            companyId,
            ruleId,
          };
          return {
            url: `/`,
            method: "POST",
            body: { query, variables },
          };
        },
        invalidatesTags: (result, error, { ruleId }) => [
          "Rules",
          { type: "Rules", ruleId },
        ],
        transformResponse,
      }),
    };
  },
});

export const {
  useRuleVaultQuery,
  useUpsertRuleMutation,
  useUpsertAndApplyRuleMutation,
  useDeleteRuleMutation,
} = ruleVaultApi;
