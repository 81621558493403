import { IAppSyncApiOutput } from "~/src/system/resource";
import {
  InstitutionId,
  IConnection,
  IInstitution,
} from "~/src/types/institutions";

// institutions query
export interface IInstitutionsOutput extends IAppSyncApiOutput {
  data?: IInstitution[];
}

// initiateConnection query
export interface IInitiateConnectionInputState {
  path: string;
  query: string;
}

export interface IInitiateShopifyConnectionInput {
  shopName: string;
}

export interface IInitiateConnectionInput {
  companyId: string;
  institutionId: InstitutionId;
  redirectUrl: string;
  state: IInitiateConnectionInputState;
  shopifyInput?: IInitiateShopifyConnectionInput;
}

interface IInitiateConnectionResponse {
  __typename: string;
}

interface IInitiateConnectionResponseToken extends IInitiateConnectionResponse {
  token: string;
}

interface IInitiateConnectionResponseUrl extends IInitiateConnectionResponse {
  url: string;
}

export interface IInitiateConnectionOutput extends IAppSyncApiOutput {
  data?: IInitiateConnectionResponseToken | IInitiateConnectionResponseUrl;
}

export function isIInitiateConnectionResponseUrl(
  response: IInitiateConnectionResponse
): response is IInitiateConnectionResponseUrl {
  return response.__typename === "InitiateConnectionResponseUrl";
}

export function isIInitiateConnectionResponseToken(
  response: IInitiateConnectionResponse
): response is IInitiateConnectionResponseToken {
  return response.__typename === "InitiateConnectionResponseToken";
}

// createConnection mutation
export interface ICreateKlaviyoConnectionInput {
  apiKey: string;
}

export interface ICreatePlaidConnectionInput {
  authorizationCode: string;
  accountNames: string[];
  plaidInstitutionId?: string;
}

export interface ICreateQuickbooksConnectionInput {
  authorizationCode: string;
  redirectUrl: string;
  realmId: string;
}

export interface ICreateShopifyConnectionInput {
  authorizationCode: string;
  shopName: string;
}

export interface ICreateConnectionInput {
  companyId: string;
  institutionId: InstitutionId;
  title?: string;
  klaviyoInput?: ICreateKlaviyoConnectionInput;
  plaidInput?: ICreatePlaidConnectionInput;
  quickbooksInput?: ICreateQuickbooksConnectionInput;
  shopifyInput?: ICreateShopifyConnectionInput;
}

export interface ICreateConnectionOutput extends IAppSyncApiOutput {
  data?: IConnection;
}

// deleteConnection mutation
export interface IDeleteConnectionInput {
  companyId: string;
  institutionId: InstitutionId;
  connectionId: string;
}

export interface IDeleteConnectionResponse {
  connectionId: string;
}

export interface IDeleteConnectionOutput extends IAppSyncApiOutput {
  data?: IDeleteConnectionResponse;
}

// testConnection mutation
export interface ICheckConnectionHealthInput {
  companyId: string;
  institutionId: InstitutionId;
  connectionId: string;
}

export interface ICheckConnectionHealthResponse {
  connectionId: string;
  healthy: boolean;
  healthLastChecked: string;
}

export interface ICheckConnectionHealthOutput extends IAppSyncApiOutput {
  data?: ICheckConnectionHealthResponse;
}

// updateConnection mutation
export interface IUpdateConnectionInput {
  companyId: string;
  institutionId: InstitutionId;
  connectionId: string;
  title?: string;
  apiKey?: string;
}

export interface IUpdateConnectionOutput extends IAppSyncApiOutput {
  data?: IConnection;
}
