/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const supportingSchedules = /* GraphQL */ `
  query SupportingSchedules($companyId: ID!, $limit: Int, $offset: Int) {
    supportingSchedules(companyId: $companyId, limit: $limit, offset: $offset) {
      id
      type
      merchantName
      merchantId
      merchantType
      description
      transactionDate
      servicePeriodStart
      servicePeriodEnd
      recognitionInterval
      totalAmount
      originatingTransactionId
      originatingTransactionLineItemId
      originatingTransactionType
      incomeOrExpenseAccountId
      incomeOrExpenseAccountName
      assetOrLiabilityAccountId
      assetOrLiabilityAccountName
      supportingScheduleEntries {
        id
        supportingScheduleId
        date
        recognitionAmount
        remainingAmount
        totalAmount
        syncState
        numberOfEntries
        entryNumber
      }
    }
  }
`;
export const supportingScheduleEntries = /* GraphQL */ `
  query SupportingScheduleEntries($companyId: ID!, $supportingScheduleId: ID!) {
    supportingScheduleEntries(
      companyId: $companyId
      supportingScheduleId: $supportingScheduleId
    ) {
      id
      supportingScheduleId
      date
      recognitionAmount
      remainingAmount
      totalAmount
      syncState
      numberOfEntries
      entryNumber
    }
  }
`;
export const previewSupportingSchedule = /* GraphQL */ `
  query PreviewSupportingSchedule(
    $companyId: ID!
    $input: CreateSupportingScheduleInput!
  ) {
    previewSupportingSchedule(companyId: $companyId, input: $input) {
      id
      type
      merchantName
      merchantId
      merchantType
      description
      transactionDate
      servicePeriodStart
      servicePeriodEnd
      recognitionInterval
      totalAmount
      originatingTransactionId
      originatingTransactionLineItemId
      originatingTransactionType
      incomeOrExpenseAccountId
      incomeOrExpenseAccountName
      assetOrLiabilityAccountId
      assetOrLiabilityAccountName
      supportingScheduleEntries {
        id
        supportingScheduleId
        date
        recognitionAmount
        remainingAmount
        totalAmount
        syncState
        numberOfEntries
        entryNumber
      }
    }
  }
`;
