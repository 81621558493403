import React, { useEffect, useState } from "react";
import { Button } from "~/src/components/button";
import { Toast } from "~/src/components/toast";
import { Icons } from "~/src/assets/icons";
import { toast } from "react-toastify";
import {
  useCategorizeTransactionMutation,
  useCreateMerchantMutation,
} from "~/src/state/transactions/transactions-api-slice";
import { useDispatch } from "react-redux";
import { categorizeLineItems } from "~/src/state/transactions/transactions-data-slice";
import { IActionItem } from "~/src/types/action-items";
import {
  ICategorizeTransactionLineItemInput,
  ICreateMerchantInput,
} from "~/src/state/transactions/transactions-api-types";

interface IQuickApplyAction {
  item: IActionItem;
  onApply: Function;
}

export const QuickApplyAction = ({ item, onApply }: IQuickApplyAction) => {
  const dispatch = useDispatch();
  const toastId = "quickApplyAction";

  const {
    id,
    transaction_id,
    transaction_line_item_id,
    transaction_sync_token,
    transaction_posting_type,
    company_id,
    categorization_responses,
  } = item;

  // Handle the categorization mutation.
  const [categorizeTransaction, categorizeTransactionMutation] =
    useCategorizeTransactionMutation();

  useEffect(() => {
    if (categorizeTransactionMutation.isUninitialized) return;
    if (categorizeTransactionMutation.isLoading) {
      toast(
        <Toast
          message="Categorizing transaction..."
          icon={Icons.green.LoadingAnimated}
        />,
        { toastId, autoClose: false }
      );
    } else {
      toast.dismiss(toastId);
    }
    if (
      categorizeTransactionMutation.isSuccess &&
      !!categorizeTransactionMutation.data.data
    ) {
      dispatch(categorizeLineItems([categorizeTransactionMutation.data.data]));
      toast(
        <Toast message="Transaction categorized!" icon={Icons.green.Info} />
      );
      // Closes the action item
      onApply(id);
    }
    if (
      categorizeTransactionMutation.isError ||
      !!categorizeTransactionMutation.data?.errors
    ) {
      toast(
        <Toast
          message="There was a problem categorizing the transaction. Please try again."
          icon={Icons.green.Info}
        />
      );
    }
  }, [categorizeTransactionMutation]);

  // Handle merchant creation
  const [createMerchant, createMerchantMutation] = useCreateMerchantMutation();

  // Send the request off
  const apply = async () => {
    let merchantId = "";

    // if there was no merchant id provided, create a new merchant.
    // TODO: currently (4/2022), there is only a single item in this array. in the future, we should
    // rewrite this to handle any number of responses, but I'm not sure what operations we might need
    // to cover.
    if (categorization_responses && !categorization_responses[0].merchant_id) {
      await createMerchant({
        name: categorization_responses[0].name,
        companyId: company_id,
        postingType: transaction_posting_type
          ? transaction_posting_type
          : "DEBIT",
      })
        .unwrap()
        .then((res) => {
          if (res.data) {
            merchantId = res.data.externalId;
          } else if (res.errors) {
            console.error(res.errors);
          }
        });
    }
    // if we had a merchant passed back, use its id.
    else if (
      categorization_responses &&
      categorization_responses[0].merchant_id
    ) {
      merchantId = categorization_responses[0].merchant_id;
    }

    if (
      !transaction_id ||
      !transaction_line_item_id ||
      !transaction_sync_token ||
      !categorization_responses ||
      !merchantId
    )
      return;

    const categorizeTransactionLineItemInput: ICategorizeTransactionLineItemInput =
      {
        companyId: company_id,
        externalAccountId: categorization_responses[0].account_id,
        externalTransactionId: transaction_id,
        lineItemId: transaction_line_item_id,
        transactionSyncToken: transaction_sync_token,
        merchantId,
      };

    categorizeTransaction(categorizeTransactionLineItemInput);
  };

  return (
    <Button
      size="medium"
      theme="primary"
      label="Apply"
      onClick={apply}
      disabled={
        categorizeTransactionMutation.isLoading ||
        createMerchantMutation.isLoading
      }
    />
  );
};
