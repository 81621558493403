import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ISidebarState {
  collapsed: boolean;
}

const initialState: ISidebarState = {
  collapsed: false,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggle(state) {
      state.collapsed = !state.collapsed;
    },
  },
});

export const { toggle } = sidebarSlice.actions;
export default sidebarSlice.reducer;
