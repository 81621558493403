import React, { useEffect, useState } from "react";
import { IUser } from "~/src/types/user";
import Classnames from "classnames";
import "./user-icon.css";

type UserIconSizes = "sm" | "md" | "lg" | "xl";

interface IUserIcon {
  size: UserIconSizes;
  user: IUser;
  className?: string;
}

const getInitials = (user: IUser) => {
  return `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`;
};

// TODO: Images need a bit more love/styling
export const UserIcon = ({ user, size, className }: IUserIcon) => {
  const [userInitials, setUserInitials] = useState<string>("");

  useEffect(() => {
    setUserInitials(getInitials(user));
  }, [user]);

  const classes = Classnames("user-icon", {
    "user-icon--sm": size === "sm",
    "user-icon--md": size === "md",
    "user-icon--lg": size === "lg",
    "user-icon--xl": size === "xl",
  });

  return (
    <div className={`${classes} ${className ? className : null}`}>
      {user?.avatar ? (
        <img src={user.avatar} alt="user avatar" />
      ) : (
        <span>{userInitials}</span>
      )}
    </div>
  );
};
