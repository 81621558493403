import React, { useState, useEffect } from "react";
import { Button } from "~/src/components/button";
import { Icons } from "~/src/assets/icons";
import { IAccountWithTags } from "~/src/types/accounts";
import {
  TRuleMemoConditionOperator,
  TRuleAmountConditionOperator,
  RuleMemoConditionOperators,
  RuleMemoOperatorsForUi,
  RuleAmountConditionOperators,
  RuleAmountOperatorsForUi,
} from "~/src/types/rules";
import Classnames from "classnames";

type TAccountOperators = "equals" | "not";

export type TConditionType = "memo" | "amount" | "sourceAccount";
export type TConditionOperator =
  | TRuleMemoConditionOperator
  | TRuleAmountConditionOperator;
export type TConditionArgument = string | number;
export interface IConditionWithId {
  id: string;
  type: TConditionType;
  operator: TConditionOperator;
  argument: TConditionArgument;
  valid: boolean;
}
export interface IDisabledConditionOptions {
  type: TConditionType[];
  operator: TConditionOperator[];
}
interface IConditionEditor {
  condition: IConditionWithId;
  onChange: Function;
  onRemove: Function;
  sourceAccounts?: IAccountWithTags[];
  disabledConditionOptions: IDisabledConditionOptions;
}

export const ConditionEditor = ({
  condition,
  onChange,
  onRemove,
  sourceAccounts,
  disabledConditionOptions,
}: IConditionEditor) => {
  const [type, setType] = useState<TConditionType>(condition.type ?? "memo"); // default to memo line if one isn't passed
  const [operator, setOperator] = useState<
    | TRuleMemoConditionOperator
    | TRuleAmountConditionOperator
    | TAccountOperators
  >(condition.operator);
  const [argument, setArgument] = useState<string | number | undefined>(
    condition.argument ?? ""
  );

  const conditionTypes: TConditionType[] = ["memo", "amount", "sourceAccount"];
  const accountOperators: TAccountOperators[] = ["equals", "not"];
  const [loaded, setLoaded] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(true);
  const [argumentTouched, setArgumentTouched] = useState<boolean>(false);

  // Block the operator reset from triggering on ingest.
  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (loaded) resetOperator();
  }, [type]);

  const validateArgument = (type: TConditionType) => {
    switch (type) {
      case "memo":
        if (
          !!(argument as string).length &&
          ((argument as string).length <
            memoArgMinChars[operator as TRuleMemoConditionOperator] ??
            1)
        )
          setValid(false);
        else setValid(true);
    }
  };

  useEffect(() => {
    validateArgument(type);
    onChange({
      id: condition.id,
      type,
      argument,
      operator,
      valid: valid,
    });
  }, [valid, type, operator, argument]);

  const handleRemove = () => {
    onRemove(condition.id);
  };

  // Ensure that operators are getting set to a value in the corresponding enum when type changes.
  const resetOperator = () => {
    switch (type) {
      case "memo":
        setOperator("CONTAINS");
        break;
      case "amount":
        setOperator("EQ");
        break;
      case "sourceAccount":
        setOperator("equals");
        break;
    }
  };

  // Create operator options from enums. A mapped enum will iterate over the reversed key/val pairs as well,
  // so we filter those out.
  const handleOperatorOptions = (type: TConditionType) => {
    switch (type) {
      case "memo":
        return RuleMemoConditionOperators.map((operator) => (
          <option
            key={operator}
            value={operator}
            disabled={disabledConditionOptions.operator.includes(operator)}
          >
            {RuleMemoOperatorsForUi[operator]}
          </option>
        ));
      case "amount":
        return RuleAmountConditionOperators.map((operator) => (
          <option
            key={operator}
            value={operator}
            disabled={disabledConditionOptions.operator.includes(operator)}
          >
            {RuleAmountOperatorsForUi[operator]}
          </option>
        ));
      case "sourceAccount":
        return accountOperators.map((operator) => (
          <option key={operator} value={operator}>
            {operator}
          </option>
        ));
    }
  };

  const argumentClasses = Classnames("rule-condition__argument", {
    "rule-condition__argument--invalid": !valid && argumentTouched,
  });

  const memoArgMinChars: { [key in TRuleMemoConditionOperator]: number } = {
    CONTAINS: 5,
    DOES_NOT_CONTAIN: 3,
    EQUALS: 1,
    STARTS_WITH: 5,
  };

  return (
    <>
      <div className="rule-condition__form-row">
        <select
          className="rule-condition__select"
          value={type}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setType(e.currentTarget.value as TConditionType);
            // If the user switches types, reset the argument to a safe default, otherwise React throws a warning.
            setArgument(e.currentTarget.value === "memo" ? "" : 0);
          }}
        >
          {conditionTypes.map((type) => (
            <option
              key={type}
              value={type}
              disabled={
                type === "sourceAccount" &&
                disabledConditionOptions.type.includes("sourceAccount")
              }
            >
              {type}
            </option>
          ))}
        </select>

        <select
          className="rule-condition__select"
          value={operator}
          disabled={type === "sourceAccount"}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setOperator(
              e.currentTarget.value as
                | TRuleMemoConditionOperator
                | TRuleAmountConditionOperator
                | TAccountOperators
            )
          }
        >
          {handleOperatorOptions(type)}
        </select>

        {/* TODO: The currency symbol should be localized */}
        {type !== "sourceAccount" && type === "memo" ? (
          <input
            className={argumentClasses}
            type="text"
            name="argument"
            value={argument}
            placeholder="Condition"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setArgument(e.currentTarget.value)
            }
            onKeyUp={() => setArgumentTouched(true)}
            minLength={
              memoArgMinChars[operator as TRuleMemoConditionOperator] ?? 1
            }
          />
        ) : null}
        {type !== "sourceAccount" && type === "amount" ? (
          <input
            className="rule-condition__argument"
            type="number"
            name="argument"
            value={argument}
            placeholder="$"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setArgument(e.currentTarget.value)
            }
          />
        ) : null}
        {type === "sourceAccount" ? (
          <select
            className="rule-condition__argument"
            value={argument}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setArgument(e.currentTarget.value)
            }
          >
            <option>Select</option>
            {sourceAccounts?.map((account) => (
              <option
                key={account.account.externalId}
                value={account.account.externalId}
              >
                {account.account.accountNumber} {account.account.displayName}
              </option>
            ))}
          </select>
        ) : null}

        <Button
          theme="secondary"
          size="large"
          icon={Icons.default.Remove}
          type="button"
          onClick={handleRemove}
        />
      </div>
      {!valid && argumentTouched && (
        <p className="rule-condition__form-message">
          For a {operator} query,{" "}
          {memoArgMinChars[operator as TRuleMemoConditionOperator]} characters
          are required.
        </p>
      )}
    </>
  );
};
